<script setup lang="ts">
interface TabItem {
  value: string
  label: string
  disabled?: boolean
}
const props = withDefaults(defineProps<{
  options: TabItem[]
  size?: 'small' | 'default'
}>(), {
  size: 'default',
})

const emit = defineEmits(['change'])

const current = defineModel()

const customClass = computed(() => {
  const fontSize = props.size === 'small' ? `text-0.12 font-600` : 'text-0.14 font-700'
  return `${fontSize}`
})

function onChange(item: TabItem) {
  current.value = item.value
  emit('change', item.value)
}
</script>

<template>
  <div class="flex select-none gap-x-0.16 font-dm" :class="customClass">
    <div
      v-for="item in options" :key="item.value"
      class="tab-item cursor-pointer"
      :class="{
        'text-primary': item.value === current,
        'text-grey1': item.value !== current,
      }"
      @click="onChange(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<style scoped>
.tab-item {
  transition: all 0.5s ease-in;
}
</style>
