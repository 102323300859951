<script setup lang="ts">
import type { ReferralPointsAllocationConfig } from '@/service/modules/stats.ts'

const props = defineProps<{
  count: number
  config: ReferralPointsAllocationConfig[]
  point: number
}>()

const ratio = computed(() => {
  const r = 10.21 * props.count + 120 - 102.1
  return `conic-gradient(from -120deg, #2D9FF6, #19F2FF ${r}deg, #777F92 ${r}deg)`
})

function getPoints(n: number) {
  return props.config.find(i => +i.threshold === n)?.points
}
</script>

<template>
  <div class="relative h-2 w-2.9 overflow-hidden">
    <div class="absolute left-1/2 top-0.3 h-2.6 w-2.6 ring -translate-x-1/2">
      <div class="inner absolute left-1/2 top-1/2 h-2.55 w-2.55 -translate-x-1/2 -translate-y-1/2">
        <div class="absolute left-1/2 top-1/2 h-2.4 w-2.4 rd-full bg-#141416 -translate-x-1/2 -translate-y-1/2" />
      </div>
      <n-tooltip>
        <template #trigger>
          <VoyageReferralRingCircle class="rotate--102.1">
            0
          </VoyageReferralRingCircle>
        </template>
        {{ getPoints(0) }} Referral Coins for each referral
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <VoyageReferralRingCircle class="rotate--51.05">
            5
          </VoyageReferralRingCircle>
        </template>
        {{ getPoints(5) }} Referral Coins for each referral
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <VoyageReferralRingCircle>
            10
          </VoyageReferralRingCircle>
        </template>
        {{ getPoints(10) }} Referral Coins for each referral
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <VoyageReferralRingCircle class="rotate-51.05">
            15
          </VoyageReferralRingCircle>
        </template>
        {{ getPoints(15) }} Referral Coins for each referral
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <VoyageReferralRingCircle class="rotate-102.1">
            20
          </VoyageReferralRingCircle>
        </template>
        {{ getPoints(20) }} Referral Coins for each referral
      </n-tooltip>
      <div class="absolute left-1/2 top-0.5 w-full flex-center flex-col -translate-x-1/2">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path d="M7.75156 11.8162L9.12656 8.99121H11.7266V1.99121H18.7266L17.7266 3.99121L18.7266 5.99121H13.7266V8.99121H16.2266L17.6516 11.8412L14.6516 13.8412L12.7266 12.8662L10.8016 13.8412L7.75156 11.8162ZM2.72656 21.9912L6.85156 13.6162L10.6516 16.1412L12.7266 15.1162L14.8016 16.1412L18.5516 13.6662L22.7266 21.9912H2.72656Z" fill="url(#paint0_linear_24482_42205)" />
          <defs>
            <linearGradient id="paint0_linear_24482_42205" x1="12.7266" y1="1.99121" x2="12.7266" y2="21.9912" gradientUnits="userSpaceOnUse">
              <stop stop-color="#37AAEC" />
              <stop offset="0.295" stop-color="#37AAEC" />
              <stop offset="0.97" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>
        <div class="text-headline3 text-white2">
          {{ count }}
        </div>
        <div class="text-caption2m text-grey1">
          {{ point }} Referral Coins for each referral
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ring {
  background: v-bind(ratio);
  border-radius: 50%;
  /* mask: radial-gradient(transparent 110px, #000 110px); */
}

.inner {
  background: linear-gradient(202deg, #0C4A66 14.37%, #088C8C 85.78%);
  border-radius: 50%;
}
</style>
