<script setup lang="ts">
const { currentSymbol } = useSymbol()
const router = useRouter()

function toOrderHistory() {
  router.push({
    path: '/portfolio/history',
    query: { tab: 'order' },
  })
}
</script>

<template>
  <div class="relative w-full">
    <AssetHistoryOrder :history-current-symbol="currentSymbol" class="h-3.5" />
    <div class="mt-0.04 text-caption2 text-grey1">
      Explore
      <span class="cursor-pointer text-primary underline" @click="toOrderHistory">Portfolio > History > Orders</span>
      for more.
    </div>
  </div>
</template>

<style scoped>

</style>
