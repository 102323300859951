<script setup lang="ts">
import { use } from 'echarts/core'
import { graphic } from 'echarts'
import type { EChartsOption } from 'echarts'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { provide, ref } from 'vue'
import type { SpotHistory } from '~/service/modules/stats'

use([
  CanvasRenderer,
  LineChart,
  TooltipComponent,
  GridComponent,
])

provide(THEME_KEY, 'dark')

const { run: getSpotHistory } = useHttp(vesselApi.stats.getSpotHistory)
const { isSigned } = useKey()

const spotHistoryList = ref<SpotHistory[]>([])

const period = ref(7)

const option = computed<EChartsOption>(() => ({
  backgroundColor: 'transparent',
  grid: {
    top: 0,
    bottom: 30,
    left: -20,
    right: -20,
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#23262F',
    borderWidth: 0,
    // position: [0, -40],
    axisPointer: {
      lineStyle: {
        color: '#E6E8EC',
        type: [4, 8],
      },
    },
    formatter: (params: any) => {
      const data = params[0].data
      const item = spotHistoryList.value.find(i => i.dt === data[0])
      // const symbol = item.profit >= 0 ? '+' : '-'
      return `
      <div class="">
        <div class="text-center text-grey1 text-caption3m mb-0.02">${dayjs(data[0]).format('MM/DD, YYYY')}</div>
        <div class="flex justify-between items-center mb-0.02">
          <div class="text-caption3m text-grey3 mr-0.2">Est. Value</div>
          <div class="text-caption2m text-white2">$${formatNumber(data[1], 2)}</div>
        </div>

      </div>`
    },
    // <div class="flex justify-between items-center">
    //       <div class="text-caption3m text-grey3 mr-0.2">PNL</div>
    //       <div class="text-caption2m text-white2" style="color: ${item.profitRate >= 0 ? '#58BD7D' : '#EF445B'}">
    //         ${symbol}$${formatNumber(Math.abs(item.profit), 2)}(${symbol}${getRatio(Math.abs(item.profitRate))})</div>
    //     </div>
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: params => dayjs(params).format('MM/DD'),
      fontSize: 10,
      color: '#777E90',
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    axisLabel: {
      show: false,
    },
  },
  splitLine: {
    show: false,
  },
  series: [
    {
      name: 'Traffic Sources',
      type: 'line',
      smooth: true,
      data: spotHistoryList.value.slice(-1 * period.value).map(i => [i.dt, i.accountHoldValue]),
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(55, 170, 236, 0.60)',
          },
          {
            offset: 1,
            color: 'rgba(55, 170, 236, 0.00)',
          },
        ]),
      },
      emphasis: {

      },
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFtSURBVHgBjVM9S8NQFL33JbFt1FYaQdCCgygIdVXSyd8gdHBz7tpN8Cd07e6og7/ByaBrHUQRHKKTAVtr05qP582T1Jcmwd7p5eSe+3HeeQgzsX/pmIjQgBBqgKADhxEwsJnvW9bx2o2ci1PS1cBAb9ICZDXIC84dri107o7KzpT8S/xuA6IB/4VUgIkK8xJFMlIjryWOYkeAE/n/x65eH64X9kINlxU3dJbeJr3Kk/so57DAP1eFOPwP7G+XdgabxUb8HZSY0d8qHXIFCisPbi/GQ1U1o7ETAn1tFOtZ0w7TeI1RV11G/CKuZpGjFRIA8RjpPZIx5vHPLLI65u8JgHjR2LaM6a/j+yzyYhq3GVe0hGuqJErl2b2OO0WTlF9cK6U2gCVMcnDhnNEY+c6aDUTntlk9FSbhmtaNgHmJXFE74hhjwqKB3yb75TsNwaY1uwlvy0FFTAw8Mgk9EB7q9FDoNkI72tFqGgl9fgCvzYyOimxNHQAAAABJRU5ErkJggg==',
      showSymbol: false,
      symbolSize: 15,
    },
  ],
}))

whenever(isSigned, () => {
  getSpotHistory({ periods: 30 }).then((res) => {
    spotHistoryList.value = res.data.history
  })
}, {
  immediate: true,
})
</script>

<template>
  <div class="relative h-full flex flex-col">
    <div class="m-0.1 h-0.28">
      <v-tag class="cursor-pointer" label="7D" :selected="period === 7" @click="period = 7" />
      <v-tag class="cursor-pointer" label="30D" :selected="period === 30" @click="period = 30" />
    </div>

    <VChart class="flex-1" :option="option" autoresize />
  </div>
</template>

<style scoped>

</style>
