<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" x="11" y="11">
      <path d="M13.6527 1.6875H16.1337L10.7134 7.8825L17.0899 16.3125H12.0964L8.18591 11.1997L3.71141 16.3125H1.22891L7.02641 9.68625L0.910156 1.6875H6.02891L9.56366 6.36075L13.6527 1.6875ZM12.7819 14.8275H14.1567L5.28266 3.0945H3.80741L12.7819 14.8275Z" fill="#37AAEC" />
    </svg>
    <g filter="url(#filter0_b_24550_43762)">
      <circle cx="20" cy="20.5" r="20" fill="url(#paint0_linear_24550_43762)" fill-opacity="0.3" />
      <circle cx="20" cy="20.5" r="19.8" stroke="url(#paint1_linear_24550_43762)" stroke-width="0.4" />
    </g>
    <defs>
      <filter id="filter0_b_24550_43762" x="-12" y="-11.5" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24550_43762" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24550_43762" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_24550_43762" x1="20" y1="0.5" x2="20" y2="43.2966" gradientUnits="userSpaceOnUse">
        <stop offset="0.0595561" stop-color="#696F7D" stop-opacity="0.5" />
        <stop offset="0.840053" stop-color="#7C808D" />
      </linearGradient>
      <linearGradient id="paint1_linear_24550_43762" x1="20" y1="0.5" x2="20" y2="40.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#7ACEFD" />
        <stop offset="0.66" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>

</style>
