<script setup lang="ts">
const { openModal } = useModal()
</script>

<template>
  <v-button @click="openModal('chooseProvider')">
    Connect Wallet
  </v-button>
</template>

<style scoped>

</style>
