<script setup lang="ts">
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
import loginBg from '@/assets/image/login-bg.png'

const message = useMessage()
const router = useRouter()
const { openModal } = useModal()
const route = useRoute()
const form = ref({
  password: '',
})
const hidePassword = ref(true)
const hasChangeCode = ref(false)
const togglePassword = useToggle(hidePassword)
const { invitation } = useUser()
const { fontRatio, isMobile } = useResponsive()

const waitlistUrl = import.meta.env.VITE_VESSEL_WAITLIST_URL
const mail = import.meta.env.VITE_VESSEL_MAIL

const { pass, isFinished, errorFields, execute } = useAsyncValidator(form, {
  password: {
    type: 'string',
    required: true,
    message: 'Invitation Code is required',
  },
})

const { run: verifyPassword, isLoading } = useHttp(vesselApi.stats.verifyPassword)

async function login() {
  const code = route.query.code as string
  let realCode = form.value.password
  if (code) {
    realCode = code
  }
  await execute()
  if (!pass.value) {
    return message.error('Invitation code is required')
  }
  verifyPassword({
    code: realCode,
  }).then((res) => {
    if (!res.data.isValid) {
      return message.error('Invitation code error')
    }
    invitation.value.code = realCode
    invitation.value.isValid = true
    router.replace('/trade')
  })
}

function changeCode() {
  hasChangeCode.value = true
}

onMounted(() => {
  const code = route.query.code as string
  if (code) {
    form.value.password = code
  }
})
</script>

<template>
  <div class="absolute z-1 h-full w-full flex flex-center overflow-auto py-0.2">
    <div class="my-auto flex flex-col items-center justify-center">
      <div class="h-0.4 w-1.5 flex flex-none items-center justify-center border-[0.005rem] border-primary rd-full">
        <SvgLogoImage fill="white" :width="14 * fontRatio" :height="14 * fontRatio" class="mr-0.0373" />
        <SvgLogoTitle fill="white" :width="46 * fontRatio" :height="9.6 * fontRatio" />
        <!-- <div class="ml-0.04 text-0.14 font-700 font-dm">
          Testnet
        </div> -->
      </div>
      <div class="mt-0.24 w-full px-0.15 text-0.48 font-700 leading-0.56 tracking--0.0128 font-dm sm:text-center sm:text-0.64">
        Secure,<br v-if="isMobile"> Speedy, <br v-if="isMobile"> Self-Custody
      </div>
      <v-input
        v-model="form.password" class="mt-0.32 w-3.43 flex-none sm:w-2.6" align="left"
        :type="hidePassword ? 'password' : ''"
        placeholder="Invitation Code" @keydown.enter="login"
      >
        <template #suffix>
          <div class="cursor-pointer" @click="togglePassword()">
            <svg v-if="hidePassword" xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
              <path d="M6.99954 0.333313C1.91088 0.333313 0.381542 4.74465 0.367542 4.78931L0.296875 4.99998L0.366875 5.21065C0.381542 5.25531 1.91088 9.66665 6.99954 9.66665C12.0882 9.66665 13.6175 5.25531 13.6315 5.21065L13.7022 4.99998L13.6322 4.78931C13.6175 4.74465 12.0882 0.333313 6.99954 0.333313ZM6.99954 7.66665C5.52887 7.66665 4.33288 6.47065 4.33288 4.99998C4.33288 3.52931 5.52887 2.33331 6.99954 2.33331C8.47021 2.33331 9.66621 3.52931 9.66621 4.99998C9.66621 6.47065 8.47021 7.66665 6.99954 7.66665Z" fill="#777E91" />
              <path d="M6.9974 3.66656C6.27473 3.66656 5.66406 4.27723 5.66406 4.9999C5.66406 5.72256 6.27473 6.33323 6.9974 6.33323C7.72006 6.33323 8.33073 5.72256 8.33073 4.9999C8.33073 4.27723 7.72006 3.66656 6.9974 3.66656Z" fill="#777E91" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M5.3846 8.12935L2.8106 5.55535C1.79727 6.66002 1.41327 7.76669 1.4066 7.78935L1.33594 8.00002L1.40594 8.21069C1.4206 8.25535 2.94994 12.6667 8.0386 12.6667C8.65794 12.6667 9.22194 12.5987 9.73994 12.4847L7.90927 10.654C7.25013 10.6217 6.62657 10.3453 6.15993 9.8787C5.69328 9.41205 5.41692 8.7885 5.3846 8.12935ZM8.0386 3.33335C6.80194 3.33335 5.7886 3.60269 4.94394 3.99869L2.47394 1.52869L1.53127 2.47135L13.5313 14.4714L14.4739 13.5287L12.2753 11.33C14.0339 10.028 14.6613 8.23869 14.6706 8.21069L14.7406 8.00002L14.6706 7.78935C14.6559 7.74469 13.1273 3.33335 8.0386 3.33335ZM9.30927 8.36402C9.43394 7.91269 9.32794 7.40469 8.98127 7.05735C8.6346 6.71002 8.12594 6.60469 7.6746 6.72935L6.66927 5.72402C7.08125 5.47067 7.55497 5.33552 8.0386 5.33335C9.50927 5.33335 10.7053 6.52935 10.7053 8.00002C10.7033 8.48357 10.5679 8.95718 10.3139 9.36869L9.30927 8.36402Z" fill="#777E91" />
            </svg>
          </div>
        </template>
      </v-input>
      <v-button class="mt-0.24 w-3.43 sm:w-2.6" :loading="isLoading" @click="login">
        Log in
      </v-button>
      <v-button class="mt-0.24 w-3.43 sm:w-2.6" bg="black-3" color="primary" @click="openModal('chooseProvider')">
        Connect Wallet
      </v-button>
      <div class="mt-0.24 flex gap-x-0.24 py-0.06 text-0.12 font-600 underline">
        <div class="cursor-pointer" @click="openUrl(mail)">
          Need help?
        </div>
        <!-- <div class="cursor-pointer" @click="openUrl(waitlistUrl)">
          Join Waitlist
        </div> -->
      </div>
      <!-- <div class="mt-0.32 px-.15 text-left text-0.12 font-500 text-grey2 sm:text-center">
        🌟 Your invitation code is the key to early access for Vessel DEX Alpha. Secure your spot on our waitlist, and we'll<br v-if="!isMobile">
        deliver your code straight to your inbox. We value your patience as we release invitations in stages.
      </div> -->
    </div>
  </div>
  <img class="fixed bottom-0 left-0 right-0" :src="loginBg">
</template>

<style scoped>

</style>
