<script setup lang="ts">
import type { Column } from '~/components/v/Table.vue'

const { currentOpenOrderList, getCurrentTabOrder, isLoading } = useOrder()
const { currentSymbol } = useSymbol()
const { address } = useUser()

const message = useMessage()

const page = ref(1)

const tableColumns: Column[] = [
  {
    key: 'symbol',
    title: 'Symbol',
    minWidth: 80,
  },
  {
    key: 'time',
    title: 'Order Time',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'type',
    title: 'Type',
    minWidth: 60,
  },
  {
    key: 'side',
    title: 'Side',
    minWidth: 40,
  },
  {
    key: 'price',
    title: 'Filled | Order Price',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'amount',
    title: 'Filled | Order Amount',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'total',
    title: 'Filled | Total',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'status',
    title: 'Status',
    minWidth: 80,
    width: 80,
    align: 'right',
  },
  {
    key: 'cancel',
    title: '',
    width: 120,
    minWidth: 120,
    align: 'right',
  },
]

const orderList = computed(() => currentOpenOrderList.value.find(i => i.symbol === currentSymbol.value)?.orders.sort((i, j) => j.orderTime - i.orderTime) || [])
// const orderListCurrentPage = computed(() => orderList.value.slice(0, ORDER_PAGE_SIZE * page.value))
const orderListCurrentPage = computed(() => orderList.value)

function onChangePage(current: number) {
  page.value = current
}

function onCancelOrder(orderId: number) {
  vesselApi.order.cancelOrder({
    orderId,
    symbol: currentSymbol.value,
    address: address.value,
  }).then(() => {
    getCurrentTabOrder()
    message.success('Order Cancelled.')
  })
}
</script>

<template>
  <div class="relative w-full font-poppins">
    <div v-for="(item, index) in orderListCurrentPage" :key="item.clientOrderId">
      <div v-if="index !== 0" class="mb-0.2 mt-0.16 w-full bg-black3 pt-0.01" />
      <div class="flex items-center justify-between">
        <div class="flex items-center font-700">
          <div class="text-0.17 text-white2">
            {{ item.symbol }}
          </div>
          <div
            class="ml-.1 border-2 rd-0.04 p-0.06 text-0.12 leading-0.12"
            :class="item.side === 'BUY' ? 'text-green border-green' : 'text-red border-red'"
          >
            {{ item.side }} / {{ item.type }}
          </div>
        </div>
        <v-button size="tiny" type="outline" @click="onCancelOrder(item.orderId)">
          Cancel
        </v-button>
      </div>
      <div class="mt-0.16 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Order Time
        </div>
        <div class="text-0.16 text-white2">
          {{ dayjs(+item.orderTime).format('MM/DD/YYYY HH:mm:ss') }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Order Price
        </div>
        <div class="text-0.16 text-white2">
          {{ item.executedQty !== 0 ? formatNumber(item.cumulativeQuoteQty / item.executedQty, 2) : '-' }} / {{ item.type === 'MARKET' ? 'MARKET' : formatNumber(item.price, 2) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Order Amount
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(item.executedQty, 4) }} / {{ formatNumber(item.origQty, 4) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Total
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(item.cumulativeQuoteQty, 2) }} / {{ formatNumber(item.price * item.origQty) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Status
        </div>
        <div class="text-0.16 text-white2">
          {{ item.status.replace(/\_/g, ' ') }}
        </div>
      </div>
    </div>

    <div v-if="!orderListCurrentPage.length" class="h-1 flex-center text-0.12">
      No Data
    </div>

    <!-- <div class="flex justify-end">
      <n-pagination
        class="mt-0.12"

        :default-page-size="ORDER_PAGE_SIZE"
        :item-count="orderList.length" :page="page" @update-page="onChangePage"
      />
    </div> -->
  </div>
</template>

<style scoped>

</style>
