import type { VoyagePoolSummary } from '@/service/modules/stats'

export const useVoyage = createGlobalState(() => {
  const { run: _getVoyageSummary, isLoading } = useHttp(vesselApi.stats.getVoyageSummary)
  const currentTab = ref('pool')
  const contentRef = ref<HTMLDivElement>()
  const voyageSummary = ref<VoyagePoolSummary>()
  function getVoyageSummary() {
    _getVoyageSummary().then((res) => {
      voyageSummary.value = res.data
    })
  }
  return {
    voyageSummary,
    getVoyageSummary,
    currentTab,
    contentRef,
  }
})
