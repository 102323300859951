<script setup lang="ts">
import type { DropdownOption } from 'naive-ui'

const route = useRoute()
const router = useRouter()

interface RouteChildItem {
  key: string
  label: string
  to?: string
}

interface RouteItem {
  to: string
  label: string
  hot?: boolean
  start?: string
  children?: RouteChildItem[]
}

const routeList: RouteItem[] = IS_MAINNET
  ? [
      {
        to: '/trade',
        label: 'Trade',
      },
      {
        to: '/pools',
        label: 'Pools',
      },
      {
        to: '/portfolio/spot',
        start: '/portfolio',
        label: 'Portfolio',
      },
      {
        to: '/voyage',
        label: 'Voyage',
      },
      {
        to: '/more',
        label: 'More',
        children: [

          {
            key: 'explorer',
            label: 'Explorer',
            to: '/explorer',
          },
          {
            key: 'blog',
            label: 'Blog',
          },
          {
            key: 'apidoc',
            label: 'API Doc',
          },
          {
            key: 'privacy',
            label: 'Privacy Policy',
          },
          {
            key: 'use',
            label: 'Terms of Use',
          },
          {
            key: 'net',
            label: 'Testnet',
          },
        ],
      },
    ]
  : [
      {
        to: '/trade',
        label: 'Trade',
      },
      {
        to: '/pools',
        label: 'Pools',
      },
      {
        to: '/portfolio/spot',
        start: '/portfolio',
        label: 'Portfolio',
      },
      {
        to: '/faucet',
        label: 'Faucet',
      },
      {
        to: '/voyage',
        label: 'Voyage',
        children: [
          {
            key: 'voyage',
            label: 'Voyage',
            to: '/voyage',
          },
          {
            key: 'setsail',
            to: '/setsail',
            label: 'VesselSetSail',
          },

        ],
      },
      {
        to: '/more',
        label: 'More',
        children: [

          {
            key: 'explorer',
            label: 'Explorer',
            to: '/explorer',
          },
          {
            key: 'blog',
            label: 'Blog',
          },
          {
            key: 'apidoc',
            label: 'API Doc',
          },
          {
            key: 'privacy',
            label: 'Privacy Policy',
          },
          {
            key: 'use',
            label: 'Terms of Use',
          },
          {
            key: 'net',
            label: 'Mainnet',
          },
        ],
      },
    ]

function getRouteClass(item: RouteItem) {
  const routePath = route.path
  return routePath.startsWith(item.start || item.to) ? 'text-white2' : 'text-grey1'
}

function handleSelectOption(key: string, option: DropdownOption) {
  if (option.to) {
    router.push(option.to)
    return
  }
  switch (option.key) {
    case 'apidoc':
      openUrl(APIDOC_URL)
      break
    case 'blog':
      openUrl(BLOG_URL)
      break
    case 'privacy':
      openUrl(GITBOOK_URL)
      break
    case 'use':
      openUrl(GITBOOK_URL)
      break
    case 'net':
      location.href = IS_MAINNET ? TESTNET_URL : MAINNET_URL
      break
    case 'errorTest':
      throw new Error(`${new Date().getTime()}`)
      break
  }
}
</script>

<template>
  <div class="flex items-center gap-x-0.48 text-0.14">
    <div v-for="item in routeList" :key="item.to" :class="getRouteClass(item)">
      <RouterLink v-if="!item.children" :to="item.to">
        <div class="flex items-center text-button2">
          {{ item.label }}
          <SvgHot v-if="item.hot" class="ml-0.02" />
        </div>
      </RouterLink>
      <div v-else>
        <n-dropdown
          :options="item.children as DropdownOption[]"
          placement="bottom-start"
          size="large"
          @select="handleSelectOption"
        >
          <div class="ml--0.16 flex items-center">
            <span class="ml-0.16 text-button2">{{ item.label }}</span>
            <SvgBack class="rotate--90" />
          </div>
        </n-dropdown>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
