import { createDiscreteApi, darkTheme } from 'naive-ui'

const { message: globalMessage, notification, dialog, loadingBar } = createDiscreteApi(
  ['message', 'dialog', 'notification', 'loadingBar'],
  {
    configProviderProps: {
      theme: darkTheme,
    },
  },
)

export { globalMessage }
