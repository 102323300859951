<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.9987 12.6666C7.80981 12.6666 7.65137 12.6026 7.52337 12.4746C7.39537 12.3466 7.33159 12.1884 7.33203 12V8.66665H3.9987C3.80981 8.66665 3.65137 8.60265 3.52337 8.47465C3.39537 8.34665 3.33159 8.18843 3.33203 7.99998C3.33203 7.81109 3.39603 7.65265 3.52403 7.52465C3.65203 7.39665 3.81026 7.33287 3.9987 7.33332H7.33203V3.99998C7.33203 3.81109 7.39603 3.65265 7.52403 3.52465C7.65203 3.39665 7.81026 3.33287 7.9987 3.33332C8.18759 3.33332 8.34603 3.39732 8.47403 3.52532C8.60203 3.65332 8.66581 3.81154 8.66537 3.99998V7.33332H11.9987C12.1876 7.33332 12.346 7.39732 12.474 7.52532C12.602 7.65332 12.6658 7.81154 12.6654 7.99998C12.6654 8.18887 12.6014 8.34732 12.4734 8.47532C12.3454 8.60332 12.1871 8.66709 11.9987 8.66665H8.66537V12C8.66537 12.1889 8.60137 12.3473 8.47337 12.4753C8.34537 12.6033 8.18714 12.6671 7.9987 12.6666Z" fill="#FCFCFD" />
  </svg>
</template>

<style scoped>

</style>
