<script setup lang="tsx">
const showMenu = ref(false)
const route = useRoute()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal, feedbackType } = useModal()

interface RouteItem {
  to: string
  label: string
  children?: {
    name: string
    to?: string
    key?: string
    icon: JSX.Element
    iconSelected?: JSX.Element
  }[]
}

const routeList: RouteItem[] = [
  {
    to: '/trade',
    label: 'Trade',
  },
  {
    to: '/pools',
    label: 'Pools',
  },
  {
    to: '/portfolio/spot',
    label: 'Portfolio',
    children: [
      {
        name: 'Overview',
        to: '/portfolio/spot',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 11 11" fill="none">
            <path d="M4.88867 2.22179C4.88867 1.97633 4.68908 1.77479 4.44514 1.80201C3.81093 1.87276 3.20056 2.09455 2.66639 2.45146C2.0086 2.89099 1.49591 3.5157 1.19316 4.24661C0.890405 4.97751 0.811191 5.78178 0.965532 6.5577C1.11987 7.33363 1.50084 8.04636 2.06025 8.60577C2.61966 9.16518 3.33239 9.54614 4.10831 9.70048C4.88424 9.85482 5.6885 9.77561 6.41941 9.47286C7.15031 9.17011 7.77503 8.65742 8.21455 7.99962C8.57147 7.46546 8.79325 6.85509 8.86401 6.22088C8.89123 5.97693 8.68969 5.77734 8.44423 5.77734L5.33312 5.77734C5.08766 5.77734 4.88867 5.57836 4.88867 5.3329L4.88867 2.22179Z" fill="#FCFCFD" />
            <path d="M9.3329 4.88927C9.57836 4.88927 9.7799 4.68968 9.75268 4.44574C9.71106 4.07272 9.61706 3.70666 9.47286 3.35854C9.27184 2.87324 8.97721 2.43228 8.60577 2.06084C8.23434 1.68941 7.79338 1.39477 7.30808 1.19375C6.95995 1.04956 6.5939 0.955556 6.22088 0.913938C5.97693 0.88672 5.77734 1.08826 5.77734 1.33372V4.44483C5.77734 4.69029 5.97633 4.88927 6.22179 4.88927H9.3329Z" fill="#37AAEC" />
          </svg>),
        iconSelected: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.33301 3.33317C7.33301 2.96498 7.03362 2.66267 6.6677 2.7035C5.71639 2.80964 4.80084 3.14231 3.99959 3.67768C3.01289 4.33697 2.24386 5.27404 1.78973 6.3704C1.33561 7.46676 1.21679 8.67316 1.4483 9.83704C1.67981 11.0009 2.25125 12.07 3.09037 12.9091C3.92948 13.7483 4.99858 14.3197 6.16247 14.5512C7.32635 14.7827 8.53275 14.6639 9.62911 14.2098C10.7255 13.7557 11.6625 12.9866 12.3218 11.9999C12.8572 11.1987 13.1899 10.2831 13.296 9.33181C13.3368 8.96589 13.0345 8.6665 12.6663 8.6665L7.99968 8.6665C7.63149 8.6665 7.33301 8.36802 7.33301 7.99983L7.33301 3.33317Z" fill="#777E91" />
            <path d="M14.0003 7.3334C14.3685 7.3334 14.6708 7.03402 14.63 6.6681C14.5676 6.10857 14.4266 5.55949 14.2103 5.0373C13.9087 4.30935 13.4668 3.64791 12.9096 3.09076C12.3525 2.53361 11.691 2.09165 10.9631 1.79013C10.4409 1.57383 9.89182 1.43283 9.33229 1.3704C8.96637 1.32958 8.66699 1.63188 8.66699 2.00007V6.66674C8.66699 7.03493 8.96547 7.3334 9.33366 7.3334H14.0003Z" fill="#777E91" />
          </svg>
        ),
      },
      {
        name: 'History',
        to: '/portfolio/history',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.99871 1.38477C11.6807 1.38477 14.6654 4.36943 14.6654 8.05143C14.6654 11.7334 11.6807 14.7181 7.99871 14.7181C6.90389 14.7198 5.82565 14.4507 4.86004 13.9348L1.33204 14.7181L2.11604 11.1914C1.59962 10.2255 1.33028 9.14676 1.33204 8.05143C1.33204 4.36943 4.31671 1.38477 7.99871 1.38477ZM8.66537 4.7181H7.33204V9.38477H11.332V8.05143H8.66537V4.7181Z" fill="black" />
            <path d="M7.99871 1.38477C11.6807 1.38477 14.6654 4.36943 14.6654 8.05143C14.6654 11.7334 11.6807 14.7181 7.99871 14.7181C6.90389 14.7198 5.82565 14.4507 4.86004 13.9348L1.33204 14.7181L2.11604 11.1914C1.59962 10.2255 1.33028 9.14676 1.33204 8.05143C1.33204 4.36943 4.31671 1.38477 7.99871 1.38477ZM8.66537 4.7181H7.33204V9.38477H11.332V8.05143H8.66537V4.7181Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66406 4.71875H7.33594V8.04687V9.39062H8.66406H11.3359V8.04688L8.66406 8.04687V4.71875Z" fill="#37AAEC" />
          </svg>),
        iconSelected: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.00066 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00066 14.6673C6.90584 14.6691 5.8276 14.4 4.86199 13.884L1.33399 14.6673L2.11799 11.1407C1.60157 10.1747 1.33223 9.09597 1.33399 8.00065C1.33399 4.31865 4.31866 1.33398 8.00066 1.33398ZM8.66733 4.66732H7.33399V9.33398H11.334V8.00065H8.66733V4.66732Z" fill="#777E91" />
          </svg>
        ),
      },
    ],
  },
  {
    to: '/explorer',
    label: 'Explorer',
  },
  {
    to: '/faucet',
    label: 'Faucet',
  },
  {
    to: '/setsail',
    label: 'VesselSetSail',
  },
  {
    to: '/support',
    label: 'Support',
    children: [
      {
        name: 'Share Feedback',
        key: 'feedback',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M1.33398 14.6667V2.66671C1.33398 2.30004 1.46465 1.98626 1.72598 1.72537C1.98732 1.46449 2.3011 1.33382 2.66732 1.33337H13.334C13.7007 1.33337 14.0147 1.46404 14.276 1.72537C14.5373 1.98671 14.6678 2.30049 14.6673 2.66671V10.6667C14.6673 11.0334 14.5369 11.3474 14.276 11.6087C14.0151 11.87 13.7011 12.0005 13.334 12H4.00065L1.33398 14.6667ZM8.00065 10C8.18954 10 8.34798 9.93604 8.47598 9.80804C8.60398 9.68004 8.66776 9.52182 8.66732 9.33337C8.66732 9.14448 8.60332 8.98626 8.47532 8.85871C8.34732 8.73115 8.1891 8.66715 8.00065 8.66671C7.81176 8.66671 7.65354 8.73071 7.52598 8.85871C7.39843 8.98671 7.33443 9.14493 7.33398 9.33337C7.33398 9.52226 7.39798 9.68071 7.52598 9.80871C7.65398 9.93671 7.81221 10.0005 8.00065 10ZM7.33398 7.33337H8.66732V3.33337H7.33398V7.33337Z" fill="#FCFCFD" />
          </svg>),
      },
      {
        name: 'Report a Bug',
        key: 'bug',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.99935 14C7.27713 14 6.60779 13.8222 5.99135 13.4667C5.3749 13.1111 4.88868 12.6222 4.53268 12H2.66602V10.6667H4.06602C4.03268 10.4444 4.01313 10.2222 4.00735 10C4.00157 9.77778 3.9989 9.55556 3.99935 9.33333H2.66602V8H3.99935C3.99935 7.77778 4.00202 7.55556 4.00735 7.33333C4.01268 7.11111 4.03224 6.88889 4.06602 6.66667H2.66602V5.33333H4.53268C4.68824 5.07778 4.86335 4.83889 5.05802 4.61667C5.25268 4.39444 5.47757 4.2 5.73268 4.03333L4.66602 2.93333L5.59935 2L7.03268 3.43333C7.34379 3.33333 7.66046 3.28333 7.98268 3.28333C8.3049 3.28333 8.62157 3.33333 8.93268 3.43333L10.3993 2L11.3327 2.93333L10.2327 4.03333C10.4882 4.2 10.7189 4.39156 10.9247 4.608C11.1305 4.82444 11.3109 5.06622 11.466 5.33333H13.3327V6.66667H11.9327C11.966 6.88889 11.9856 7.11111 11.9914 7.33333C11.9971 7.55556 11.9998 7.77778 11.9993 8H13.3327V9.33333H11.9993C11.9993 9.55556 11.9965 9.77778 11.9907 10C11.9849 10.2222 11.9656 10.4444 11.9327 10.6667H13.3327V12H11.466C11.1105 12.6222 10.6245 13.1111 10.008 13.4667C9.39157 13.8222 8.72202 14 7.99935 14ZM6.66602 10.6667H9.33268V9.33333H6.66602V10.6667ZM6.66602 8H9.33268V6.66667H6.66602V8Z" fill="#FCFCFD" />
          </svg>),
      },
      {
        name: 'Discord',
        key: 'discord',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12.8464 3.5532C11.9598 3.13987 10.9998 2.83987 9.99975 2.66653C9.99098 2.66625 9.98226 2.6679 9.9742 2.67136C9.96614 2.67481 9.95893 2.67999 9.95309 2.68653C9.83309 2.90653 9.69309 3.1932 9.59975 3.4132C8.53909 3.2532 7.46042 3.2532 6.39975 3.4132C6.30642 3.18653 6.16642 2.90653 6.03975 2.68653C6.03309 2.6732 6.01309 2.66653 5.99309 2.66653C4.99309 2.83987 4.03975 3.13987 3.14642 3.5532C3.13975 3.5532 3.13309 3.55987 3.12642 3.56653C1.31309 6.27987 0.813086 8.91987 1.05975 11.5332C1.05975 11.5465 1.06642 11.5599 1.07975 11.5665C2.27975 12.4465 3.43309 12.9799 4.57309 13.3332C4.59309 13.3399 4.61309 13.3332 4.61975 13.3199C4.88642 12.9532 5.12642 12.5665 5.33309 12.1599C5.34642 12.1332 5.33309 12.1065 5.30642 12.0999C4.92642 11.9532 4.56642 11.7799 4.21309 11.5799C4.18642 11.5665 4.18642 11.5265 4.20642 11.5065C4.27975 11.4532 4.35309 11.3932 4.42642 11.3399C4.43975 11.3265 4.45975 11.3265 4.47309 11.3332C6.76642 12.3799 9.23975 12.3799 11.5064 11.3332C11.5198 11.3265 11.5398 11.3265 11.5531 11.3399C11.6264 11.3999 11.6998 11.4532 11.7731 11.5132C11.7998 11.5332 11.7998 11.5732 11.7664 11.5865C11.4198 11.7932 11.0531 11.9599 10.6731 12.1065C10.6464 12.1132 10.6398 12.1465 10.6464 12.1665C10.8598 12.5732 11.0998 12.9599 11.3598 13.3265C11.3798 13.3332 11.3998 13.3399 11.4198 13.3332C12.5664 12.9799 13.7198 12.4465 14.9198 11.5665C14.9331 11.5599 14.9398 11.5465 14.9398 11.5332C15.2331 8.5132 14.4531 5.8932 12.8731 3.56653C12.8664 3.55987 12.8598 3.5532 12.8464 3.5532ZM5.67975 9.93987C4.99309 9.93987 4.41975 9.30653 4.41975 8.52653C4.41975 7.74653 4.97975 7.1132 5.67975 7.1132C6.38642 7.1132 6.94642 7.7532 6.93975 8.52653C6.93975 9.30653 6.37975 9.93987 5.67975 9.93987ZM10.3264 9.93987C9.63975 9.93987 9.06642 9.30653 9.06642 8.52653C9.06642 7.74653 9.62642 7.1132 10.3264 7.1132C11.0331 7.1132 11.5931 7.7532 11.5864 8.52653C11.5864 9.30653 11.0331 9.93987 10.3264 9.93987Z" fill="#FCFCFD" />
          </svg>),
      },
      {
        name: 'Twitter',
        key: 'twitter',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.973 3.99984C14.4597 4.23317 13.9064 4.3865 13.333 4.45984C13.9197 4.1065 14.373 3.5465 14.5864 2.87317C14.033 3.2065 13.4197 3.43984 12.773 3.57317C12.2464 2.99984 11.5064 2.6665 10.6664 2.6665C9.0997 2.6665 7.8197 3.9465 7.8197 5.5265C7.8197 5.75317 7.84637 5.97317 7.89303 6.17984C5.5197 6.05984 3.40637 4.91984 1.9997 3.19317C1.75303 3.61317 1.61303 4.1065 1.61303 4.6265C1.61303 5.61984 2.11303 6.49984 2.88637 6.99984C2.41303 6.99984 1.97303 6.8665 1.58637 6.6665V6.6865C1.58637 8.07317 2.57303 9.23317 3.8797 9.49317C3.46019 9.60798 3.01976 9.62395 2.59303 9.53984C2.7741 10.1082 3.12873 10.6054 3.60704 10.9618C4.08536 11.3181 4.66333 11.5156 5.2597 11.5265C4.24879 12.3268 2.9957 12.7594 1.70637 12.7532C1.4797 12.7532 1.25303 12.7398 1.02637 12.7132C2.29303 13.5265 3.7997 13.9998 5.41303 13.9998C10.6664 13.9998 13.553 9.63984 13.553 5.85984C13.553 5.73317 13.553 5.61317 13.5464 5.4865C14.1064 5.0865 14.5864 4.57984 14.973 3.99984Z" fill="#FCFCFD" />
          </svg>),
      },
      {
        name: 'Contact',
        key: 'contact',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2.66732 13.3334C2.30065 13.3334 1.98687 13.203 1.72598 12.9421C1.4651 12.6812 1.33443 12.3672 1.33398 12.0001V4.00008C1.33398 3.63341 1.46465 3.31964 1.72598 3.05875C1.98732 2.79786 2.3011 2.66719 2.66732 2.66675H13.334C13.7007 2.66675 14.0147 2.79741 14.276 3.05875C14.5373 3.32008 14.6678 3.63386 14.6673 4.00008V12.0001C14.6673 12.3667 14.5369 12.6807 14.276 12.9421C14.0151 13.2034 13.7011 13.3339 13.334 13.3334H2.66732ZM8.00065 8.66675L13.334 5.33341V4.00008L8.00065 7.33342L2.66732 4.00008V5.33341L8.00065 8.66675Z" fill="#FCFCFD" />
          </svg>),
      },
    ],
  },
]

const showSubMenu = ref<Record<string, boolean>>({
  Asset: true,
})

function getRouteClass(item: RouteItem) {
  const routePath = route.path
  if (item.children && routePath.startsWith(item.to)) {
    return 'text-white2'
  }
  return routePath.startsWith(item.to) && !item.children ? 'text-white2 current' : 'text-grey1'
}

function handleClickMenu(action: string) {
  switch (action) {
    case 'feedback':
      openModal('feedback')
      feedbackType.value = 'feedback'
      break
    case 'bug':
      openModal('feedback')
      feedbackType.value = 'bug'
      break
    case 'discord':
      openUrl(DISCORD_URL)
      break
    case 'twitter':
      openUrl('https://x.com/vesselfinance?s=21&t=EglxJCuyhz1aGUWyhMhHTg')
      break
    case 'contact':
      openUrl('mailto:gm@vessel.finance')
      break
  }
}

watch(route, () => {
  showMenu.value = false
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" @click="showMenu = true">
    <path d="M6.66732 10.6745C5.93094 10.6745 5.33398 11.2715 5.33398 12.0078C5.33398 12.7442 5.93094 13.3412 6.66732 13.3412H25.334C26.0704 13.3412 26.6673 12.7442 26.6673 12.0078C26.6673 11.2715 26.0704 10.6745 25.334 10.6745H6.66732Z" fill="#777E91" />
    <path d="M6.66732 18.6745C5.93094 18.6745 5.33398 19.2715 5.33398 20.0078C5.33398 20.7442 5.93094 21.3412 6.66732 21.3412H25.334C26.0704 21.3412 26.6673 20.7442 26.6673 20.0078C26.6673 19.2715 26.0704 18.6745 25.334 18.6745H6.66732Z" fill="#777E91" />
  </svg>
  <div v-if="showMenu" class="fixed bottom-0 left-0 right-0 top-0 z-199 bg-black1">
    <div class="h-0.88 flex items-center justify-between p-0.24">
      <div class="flex items-center">
        <svg-logo-image mr-0.08 />
        <svg-logo-title />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" @click="showMenu = false">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05654 7.05721C7.57724 6.53651 8.42146 6.53651 8.94216 7.05721L15.9993 14.1144L23.0565 7.05721C23.5772 6.53651 24.4215 6.53651 24.9422 7.05721C25.4629 7.57791 25.4629 8.42213 24.9422 8.94283L17.885 16L24.9422 23.0572C25.4629 23.5779 25.4629 24.4221 24.9422 24.9428C24.4215 25.4635 23.5772 25.4635 23.0565 24.9428L15.9993 17.8856L8.94216 24.9428C8.42146 25.4635 7.57724 25.4635 7.05654 24.9428C6.53584 24.4221 6.53584 23.5779 7.05654 23.0572L14.1137 16L7.05654 8.94283C6.53584 8.42213 6.53584 7.57791 7.05654 7.05721Z" fill="#777E91" />
      </svg>
    </div>
    <div class="mt-0.64 h-66% overflow-auto font-poppins">
      <div v-for="item in routeList" :key="item.to" :class="getRouteClass(item)" class="relative">
        <RouterLink v-if="!item.children" :to="item.to">
          <div class="h-0.64 px-0.3 text-0.24 font-600 font-700 leading-0.6 font-dm">
            {{ item.label }}
          </div>
        </RouterLink>
        <div v-else>
          <div class="h-0.64 flex items-center justify-between px-0.3 text-0.24 font-600 font-700 leading-0.6 font-dm" @click="showSubMenu[item.label] = !showSubMenu[item.label]">
            {{ item.label }}
            <svg
              xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class="transition-all" :class="{
                'rotate-180': !showSubMenu[item.label],
              }"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6095 13.0572C21.0888 12.5365 20.2446 12.5365 19.7239 13.0572L16 16.781L12.2761 13.0572C11.7554 12.5365 10.9112 12.5365 10.3905 13.0572C9.86982 13.5779 9.86982 14.4221 10.3905 14.9428L15.0572 19.6095C15.5779 20.1302 16.4221 20.1302 16.9428 19.6095L21.6095 14.9428C22.1302 14.4221 22.1302 13.5779 21.6095 13.0572Z" fill="#777E91" />
            </svg>
          </div>
          <div v-if="showSubMenu[item.label]">
            <RouterLink
              v-for="subItem in item.children.filter(i => !!i.to)"
              :key="subItem.name" :to="subItem.to"
              class="group h-0.48 flex cursor-pointer items-center rd-0.04 px-0.16 hover:bg-black3"
            >
              <div class="mr-0.04 h-0.3 w-0.3 flex-center">
                <Render :value="$route.path !== subItem.to ? subItem.iconSelected : subItem.icon" />
              </div>
              <div
                class="text-0.14 font-700 font-dm" :class="{
                  'text-grey1 group-hover:text-white2': $route.path !== subItem.to,
                  'text-white2': $route.path === subItem.to,
                }"
              >
                {{ subItem.name }}
              </div>
            </RouterLink>
            <div
              v-for="subItem in item.children.filter(i => !i.to)" :key="subItem.name"
              class="group h-0.48 flex cursor-pointer items-center rd-0.04 px-0.16"
              @click="handleClickMenu(subItem.key)"
            >
              <div class="mr-0.04 h-0.3 w-0.3 flex-center">
                <Render :value="subItem.icon" />
              </div>
              <div
                class="text-0.14 font-700 font-dm text-grey1 group-hover:text-white2"
              >
                {{ subItem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute bottom-0.44 w-full flex justify-center">
      <common-connect-wallet-button v-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" />
      <topbar-user v-if="isSigned" placement="top" />
    </div>
  </div>
</template>

<style scoped>
.current:before {
  content: '';
  position: absolute;
  padding-left: 2px;
  height:  100%;
  background: var(--vessel-color-primary);
}
</style>
