<script setup lang="ts">
import { UseTimeAgo } from '@vueuse/components'
import type { Column } from '~/components/v/Table.vue'
import type { ProverBatchItem, ProverResponse } from '@/service/modules/stats'
import bgImg from '~/assets/image/login-bg.png'

const total = ref(0)
const page = ref(1)
const tableData = ref<ProverBatchItem[]>([])
const tableRes = ref<ProverResponse>()
const { run: getProverListApi, isLoading } = useHttp(vesselApi.stats.getProverStatus)
const { isMobile, fontRatio } = useResponsive()
const { currentChainId } = useConnector()

const scanUrl = computed(() => {
  const config = getChainConfigSync()
  console.log(config)
  return config.find(i => +i.id === +currentChainId.value).explorerUrl
})

const colorList: Record<string, string> = {
  'Pending': '#FFD166',
  'Proof Generated': '#58BD7D',
  'Failed': '#EF445B',
  'Committed': '#37AAEC',
}

function getProverList() {
  getProverListApi({
    page: page.value,
    pageSize: 10,
  }).then((res) => {
    tableData.value = res.data.batches
    total.value = res.data.count
    tableRes.value = res.data
  })
}

const summaryList = computed(() => [
  {
    title: 'Total Batch',
    value: formatNumber(tableRes.value?.count, 0),
  },
  {
    title: 'Proof Generated Batch',
    value: tableRes.value?.maxProofGeneratedId === -1 ? formatNumber(tableRes.value?.count, 0) : formatNumber(tableRes.value?.maxProofGeneratedId, 0),
  },
  {
    title: 'Committed Batch',
    value: tableRes.value?.maxCommittedId === -1 ? formatNumber(tableRes.value?.count, 0) : formatNumber(tableRes.value?.maxCommittedId, 0),
  },
])

const tableColumns: Column[] = [
  {
    key: 'id',
    title: 'Batch ID',
    minWidth: 80,
  },
  {
    key: 'time',
    title: 'Created At',
    minWidth: 100,
  },
  {
    key: 'hash',
    title: 'Committed Tx Hash',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'event',
    title: 'Last Event ID',
    minWidth: 200,
    align: 'right',
  },
  {
    key: 'status',
    title: 'Status',
    minWidth: 120,
    align: 'right',
  },
]

function shortenHash(hash: string): string {
  return `${hash.slice(0, 8)}...${hash.slice(-8)}`
}

function onChangePage(newPage: number) {
  page.value = newPage
  getProverList()
}

onMounted(getProverList)
</script>

<template>
  <div class="mt-0.4 w-full flex flex-col bg-black1">
    <img :src="bgImg" class="absolute bottom-0 w-full">
    <div class="no-scroll z-1 flex gap-0.16 overflow-auto px-0.16 sm:justify-center sm:gap-0.32">
      <div
        v-for="item in summaryList" :key="item.title"
        class="h-1 w-1.62 flex-none rd-br-0.3 rd-tl-0.3 bg-black2 px-0.16 py-0.16 sm:h-1.04 sm:h-auto sm:w-2.3 sm:w-2.44 sm:rd-br-0.4 sm:rd-tl-0.4 sm:px-0.32"
      >
        <div class="h-0.32 text-0.12 font-500 leading-0.16 sm:h-auto sm:text-0.14 sm:leading-0.24">
          {{ item.title }}
        </div>
        <div class="mt-0.08 text-0.24 font-700 leading-0.4 tracking--0.0032 font-dm sm:text-0.32">
          {{ item.value }}
        </div>
      </div>
    </div>
    <div class="relative my-0.4 flex flex-1 px-0.16 sm:px-0.8">
      <div class="w-full flex flex-1 flex-col justify-between border-0.4 border-black3 rd-0.12 bg-[rgba(20,20,22,0.50)] px-0.16 backdrop-blur-10 sm:py-0.32">
        <v-table
          v-if="!isMobile"
          :data="tableData"
          :columns="tableColumns"
          :column-gap="35"
          row-key="time"
          :loading="isLoading"
          row-class="h-0.48 px-0.16 hover:bg-black2 cursor-pointer"
          title-row-class="px-0.16"
          content-class="min-h-2 gap-y-0.1"
        >
          <template #hash="{ data: { row } }">
            <div
              v-if="row.transactionHash"
              @click="openUrl(`${scanUrl}${scanUrl.endsWith('/') ? '' : '/'}${row.transactionHash}`)"
            >
              <n-tooltip trigger="hover">
                <template #trigger>
                  <div class="flex cursor-pointer items-center justify-end gap-0.04 text-primary">
                    {{ shortenAddress(row.transactionHash) }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M3.33333 14C2.96667 14 2.65267 13.8693 2.39133 13.608C2.13 13.3467 1.99956 13.0329 2 12.6667V3.33333C2 2.96667 2.13067 2.65267 2.392 2.39133C2.65333 2.13 2.96711 1.99956 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8693 13.3473 13.608 13.6087C13.3467 13.87 13.0329 14.0004 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z" fill="#37AAEC" />
                    </svg>
                  </div>
                </template>
                {{ row.transactionHash }}
              </n-tooltip>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #time="{ data: { row } }">
            <n-tooltip trigger="hover">
              <template #trigger>
                <div>
                  <UseTimeAgo v-slot="{ timeAgo }" :time="row.createTime">
                    {{ timeAgo }}
                  </UseTimeAgo>
                </div>
              </template>
              {{ dayjs(row.createTime).format('MM/DD/YYYY HH:mm:ss') }}
            </n-tooltip>
          </template>
          <template #status="{ data: { row } }">
            <div :style="{ color: (colorList[row.status]) }">
              {{ row.status }}
            </div>
          </template>
          <template #event="{ data: { row } }">
            <div>
              {{ +row.eventIdRange.at(-1) || '-' }}
            </div>
          </template>
          <template #root="{ data: { row } }">
            <div>
              <div>
                After: {{ shortenHash(row.afterMerkleRoot) }}
              </div>
              <div>
                Before: {{ shortenHash(row.beforeMerkleRoot) }}
              </div>
            </div>
          </template>
        </v-table>

        <div v-else>
          <div v-for="row in tableData" :key="row.id" class="flex gap-x-0.12 py-0.16 text-0.12">
            <div class="h-0.18 flex-center">
              {{ row.id }}
            </div>
            <div class="flex-1" @click="openUrl(`https://sepolia.etherscan.io/tx/${row.transactionHash}`)">
              <div v-if="row.transactionHash" class="flex items-center text-primary">
                <n-tooltip trigger="hover">
                  <template #trigger>
                    {{ shortenAddress(row.transactionHash) }}
                    <svg xmlns="http://www.w3.org/2000/svg" :width="16 * fontRatio" :height="16 * fontRatio" viewBox="0 0 16 16" fill="none" class="ml-0.04">
                      <path d="M3.33333 14C2.96667 14 2.65267 13.8693 2.39133 13.608C2.13 13.3467 1.99956 13.0329 2 12.6667V3.33333C2 2.96667 2.13067 2.65267 2.392 2.39133C2.65333 2.13 2.96711 1.99956 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8693 13.3473 13.608 13.6087C13.3467 13.87 13.0329 14.0004 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z" fill="#37AAEC" />
                    </svg>
                  </template>
                  {{ row.transactionHash }}
                </n-tooltip>
              </div>
              <div v-else>
                -
              </div>
              <UseTimeAgo v-slot="{ timeAgo }" :time="row.createTime">
                {{ timeAgo }}
              </UseTimeAgo>
            </div>
            <div :style="{ color: (colorList[row.status]) }" class="h-0.18 flex-center">
              {{ row.status }}
            </div>
          </div>
        </div>
        <div class="my-0.2 w-full flex justify-center">
          <n-pagination :item-count="+total" :page="page" :page-slot="5" @update-page="onChangePage">
            <template #prev>
              <svg-back class="h-0.24 w-0.24" />
            </template>
            <template #next>
              <svg-back class="h-0.24 w-0.24 rotate-180" />
            </template>
          </n-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
