<script setup lang="ts">
const { tradeHistoryList, isLoading } = useOrder()
const { currentSymbolItem } = useSymbol()

const page = ref(1)

const orderList = computed(() => tradeHistoryList.value.sort((i, j) => +j.time - +i.time) || [])
// const orderListCurrentPage = computed(() => orderList.value.slice(ORDER_PAGE_SIZE * (page.value - 1), ORDER_PAGE_SIZE * page.value))
const orderListCurrentPage = computed(() => orderList.value)

function onChangePage(current: number) {
  page.value = current
}
</script>

<template>
  <div class="relative w-full font-poppins">
    <div v-for="(item, index) in orderListCurrentPage" :key="item.id">
      <div v-if="index !== 0" class="mb-0.2 mt-0.16 w-full bg-black3 pt-0.01" />
      <div class="flex items-center justify-between">
        <div class="flex items-center font-700">
          <div class="text-0.17 text-white2">
            {{ item.symbol }}
          </div>
          <div
            class="ml-.1 border-2 rd-0.04 p-0.06 text-0.12 leading-0.12"
            :class="item.side === 'BUY' ? 'text-green border-green' : 'text-red border-red'"
          >
            {{ item.side }}
          </div>
        </div>
      </div>
      <div class="mt-0.16 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled Time
        </div>
        <div class="text-0.16 text-white2">
          {{ dayjs(+item.time).format('MM/DD/YYYY HH:mm:ss') }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled Price
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(+item.price, 2) }} {{ currentSymbolItem.quote }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled Amount
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(+item.qty, 4) }} {{ currentSymbolItem.base }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Total
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(+item.quoteQty, 4) }} {{ currentSymbolItem.quote }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Fee
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(+item.fee, 8) }} {{ item.feeCurrency }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Maker / Taker
        </div>
        <div class="text-0.16 text-white2">
          {{ item.isMaker ? 'Maker' : 'Taker' }}
        </div>
      </div>
    </div>

    <div v-if="!orderListCurrentPage.length" class="h-1 flex-center text-0.12">
      No Data
    </div>

    <!-- <div class="flex justify-end">
      <n-pagination
        class="mt-0.12"

        :default-page-size="ORDER_PAGE_SIZE"
        :item-count="orderList.length" :page="page" @update-page="onChangePage"
      />
    </div> -->
  </div>
</template>

<style scoped>

</style>
