import type { CurrentOrderList } from './order'

const userApi = {
  getUserInfo: () => http.get<UserInfo>('/user'),
  verifyPassword: (params: { code: string }) => http.get('/stats/authorizationCode', { params }),
  getAdminSignature: () => http.get<AdminSignature>('/registration'),
  getDepositHistory: () => http.get<DepositRes>('/gateway/user/history/deposit'),
  getWithdrawHistory: () => http.get<WithdrawRes>('/gateway/user/history/withdraw'),
  getClaimHistory: () => http.get<ClaimRes>('/gateway/user/history/claim'),
  getTransferHistory: () => http.get<TransferRes>('/gateway/user/history/userAction'),
  getAsset: () => http.get<AssetRes>('/userAsset'),
  withdraw: (data: WithdrawReq) => http.post('/withdraw', data),
  transfer: (data: TransferReq, options: any) => http.post('/transfer', data, options),
  getWithdrawEstTime: (id: number) => http.get('/stats/prover/withdraw/estimate', { params: { withdrawGlobalEventId: id } }),
}

export default userApi

export interface ClaimItem {
  address: string
  assetName: string
  amount: string
  timestamp: string
}

export interface ClaimRes {
  claims: ClaimItem[]
}

interface WithdrawReq {
  assetName: string
  amount: string
  clientOrderId: string
}

interface TransferReq {
  eip712Message: string
  eip712Signature: string
  signature: string
}

export interface WithdrawItem extends DepositItem {
  withdrawOrderId: string
}
export interface WithdrawRes {
  withdraws: WithdrawItem[]
}

export interface AssetItem {
  assetName: string
  available: string
  inUse: string
  pendingWithdraw: string
  price: string
  todayTransactionAmount: string
}

export interface AssetRes {
  assets: AssetItem[]
}

export interface DepositItem {
  address: string
  amount: string
  assetName: string
  id: string
  status: string
  timestamp: string
  transactionFee: string
}
export interface DepositRes {
  deposits: DepositItem[]
}
export interface TransferItem {
  address: string
  amount: string
  assetName: string
  globalEventId?: string
  id?: string
  status?: string
  timestamp: string
  transaction: string
  transactionFee?: string
  type: string
  withdrawOrderId?: string
}
export interface TransferRes {
  history: TransferItem[]
}

interface AdminSignature {
  message: string
  operator: string
  signature: string
}

export type OrderType = 'LIMIT' | 'MARKET'

export interface Balance {
  asset: string
  balance: string
}

export interface OrderItem {
  executedQty: string
  orderId: string
  origQty: string
  price: string
  side: 'SELL' | 'BUY'
  symbol: string
  timeInForce: string
  type: OrderType
  workingTime: string
}

export interface UserOrder {
  symbol: string
  orders: OrderItem[]
}

export interface UserInfo {
  token: string
  id: string
  address: string
  status: string
  level: string
  registered: boolean
  balances: Balance[]
  orders: UserOrder[]
  takerFeeRate: string
  makerFeeRate: string
  vesselKey: string
  currentOpenOrders: CurrentOrderList[]
}
