const publicApi = {
  createUser: (data: CreateUserRequest) => http.post<A<CreateUserResponse>>('/gateway/public/user', data),
  getChainConfig: () => http.get<A<{ data: Record<keyof ChainConfig, string>[] }>>('/gateway/public/chain'),
  getTickerInfo: () => http.get<A<TickerRes>>('/public/tickerInfo').then(i => i.data.symbols),
  getSymbols: () => http.get<A<SymbolRes>>('/public/allTradingSymbols').then(i => i.data.results),
  getFeeInfo: () => http.get<A<FeeRes>>('/public/tradeFee'),
  getCurrency: () => http.get<A<CurrencyRes>>('/public/assetInfo'),
  getKLine: (params: KLineReq) => http.get<A<KLineRes>>('/gateway/public/klines', { params }),
  getHealth: () => http.get<any>('/gateway/public/status'),
}

export default publicApi

export interface FeeItem {
  level: string
  makerFeeRate: string
  takerFeeRate: string
}

interface FeeRes {
  vipFeeRateDetails: FeeItem[]
}

export interface ChainConfig {
  nativeCurrencyDecimals: number
  nativeCurrencySymbol: string
  nativeCurrencyName: string
  id: number
  name: string
  publicRpcUrl: string
  explorerName: string
  explorerUrl: string
  vaultAddress: string
}

export interface TickerSymbol {
  // symbol: string
  // baseAssetId: number
  // baseAssetName: string
  // baseAssetOnChainDecimal: number
  // baseAssetPrecision: number
  // isSpotTradingAllowed: boolean
  // maxBaseTradingSize: number
  // minBaseTradingSize: number
  // quoteAssetId: number
  // quoteAssetName: string
  // quoteAssetOnChainDecimal: number
  // quoteAssetPrecision: number
  // status: string
  // takerFeeDiscount: number
  // makerFeeDiscount: number
  baseAssetId: string
  baseAssetName: string
  baseAssetOnChainDecimal: string
  baseAssetPrecision: string
  baseScaleK: string
  isSpotTradingAllowed: boolean
  makerFeeDiscount: string
  maxBaseTradingSize: string
  minBaseTradingSize: string
  orderTypes: string[]
  permissions: string[]
  quoteAssetId: string
  quoteAssetName: string
  quoteAssetOnChainDecimal: string
  quoteAssetPrecision: string
  quoteScaleK: string
  status: string
  symbol: string
  takerFeeDiscount: string
}

export interface TickerRes {
  symbols: ConvertToString<TickerSymbol>[]
}

export interface KLineReq {
  symbol: string
  interval: string
  startTime?: string
  endTime?: string
  limit?: number
}

export interface CreateUserRequest {
  signMessage: string
  signature: string
}

export interface CreateUserResponse {
  address: string
  id: number
  key: string
  passphrase: string
  secret: string
  level: string
  makeFeeRate: number
  takeFeeRate: number
  status: string
  vesselKey: string
}

export interface SymbolItem {
  baseAssetId: string
  baseAssetName: string
  baseAssetOnChainDecimal: string
  baseAssetPrecision: string
  baseOnChainDecimal: string
  baseScaleK: string
  closePrice: string
  closeTime: string
  highPrice: string
  id: string
  isSpotTradingAllowed: boolean
  lowPrice: string
  makerFeeDiscount: string
  maxBaseTradingSize: string
  minBaseTradingSize: string
  openPrice: string
  openTime: string
  orderTypes: string[]
  permissions: string[]
  quoteAssetId: string
  quoteAssetName: string
  quoteAssetOnChainDecimal: string
  quoteAssetPrecision: string
  quoteOnChainDecimal: string
  quoteScaleK: string
  quoteVolume: string
  status: string
  symbol: string
  takerBuyBaseVolume: string
  takerBuyQuoteVolume: string
  takerFeeDiscount: string
  trades: string
  volume: string
}

export interface SymbolRes {
  results: SymbolItem[]
}

export interface CurrencyItem {
  // assetId: number
  // assetName: string
  // address: string
  // onChainDecimal: number
  // scaleK: number
  // canDepositFrontend: boolean
  // canWithdraw: boolean
  // depositLimit: number
  // depositPrecision: number
  // isToken: boolean
  // maxWithdrawSize: number
  // minWithdrawSize: number
  // price: number
  address: string
  assetId: string
  assetName: string
  canDepositFrontend: boolean
  canWithdraw: boolean
  depositLimit: string
  depositPrecision: string
  isToken: boolean
  maxWithdrawSize: string
  minWithdrawSize: string
  onChainDecimal: string
  price: string
  scaleK: string
}

export interface CurrencyRes {
  assets: CurrencyItem[]
}

export interface KLineItem {
  // openTime: number
  // openPrice: string
  // highPrice: string
  // lowPrice: string
  // closePrice: string
  // volume: string
  // trades: number
  // closeTime: number
  // quoteVolume: string
  // takerBuyBaseVolume: string
  // takerBuyQuoteVolume: string
  closePrice: string
  closeTime: string
  highPrice: string
  lowPrice: string
  openPrice: string
  openTime: string
  quoteVolume: string
  takerBuyBaseVolume: string
  takerBuyQuoteVolume: string
  trades: string
  volume: string
}

export interface KLineRes {
  klines: KLineItem[]
}
