import type { MintItem, MintedItem } from '~/service/modules/stats'

export const useFaucet = createGlobalState(() => {
  const { openModal } = useModal()
  const mintedList = ref<MintedItem[]>([])
  const allMintList = ref<MintItem[]>([])
  const isNewUser = ref(false)

  vesselApi.stats.getAllMintList({}).then((res) => {
    allMintList.value = res.data.tokens
  })

  function getFaucetInfo() {
    vesselApi.stats.getMintedList().then((res) => {
      mintedList.value = res.data.tokens
      isNewUser.value = res.data.isNewUser
      if (res.data.isNewUser && location.pathname !== '/faucet' && !IS_MAINNET) {
        openModal('remindFaucet')
      }
    }).catch(console.log)
  }
  return {
    mintedList,
    allMintList,
    isNewUser,
    getFaucetInfo,
  }
})
