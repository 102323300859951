<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#B1B5C4">
    <path d="M6 11.9999C5.63333 11.9999 5.31933 11.8693 5.058 11.6079C4.79667 11.3466 4.66622 11.0328 4.66667 10.6666V2.66659C4.66667 2.29992 4.79733 1.98592 5.05867 1.72459C5.32 1.46325 5.63378 1.33281 6 1.33325H12C12.3667 1.33325 12.6807 1.46392 12.942 1.72525C13.2033 1.98659 13.3338 2.30036 13.3333 2.66659V10.6666C13.3333 11.0333 13.2027 11.3473 12.9413 11.6086C12.68 11.8699 12.3662 12.0004 12 11.9999H6ZM3.33333 14.6666C2.96667 14.6666 2.65267 14.5359 2.39133 14.2746C2.13 14.0133 1.99956 13.6995 2 13.3333V3.99992H3.33333V13.3333H10.6667V14.6666H3.33333Z" fill="inherit" />
  </svg>
</template>

<style scoped>

</style>
