<script setup lang="ts">
const { isSigned, isGettingKey } = useKey()
</script>

<template>
  <v-button :loading="isGettingKey" @click="checkAndBindVesselKey">
    Sign for Trading
  </v-button>
</template>

<style scoped>

</style>
