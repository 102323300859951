<script setup lang="ts">

</script>

<template>
  <div class="card rd-0.24 px-0.32 py-0.4">
    <slot />
  </div>
</template>

<style scoped>
.card {
border: 1px solid var(--vessel-color-black-3, #353945);
background: var(--vessel-color-black-2, #23262F);

/* depth/4 */
box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.10);
}
</style>
