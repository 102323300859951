<script setup lang="ts">
import LastTrade from '../orderbook/LastTrade.vue'
import Orderbook from '../orderbook/Orderbook.vue'
import Chart from '../chart/index.vue'

const tabOptions = [
  { value: 'chart', label: 'Chart' },
  { value: 'orderbook', label: 'Order Book' },
  { value: 'trade', label: 'Last trades' },
  // { value: 'favorite', label: 'Favorite' },
]

const currentTab = ref('chart')

function onChangeTab() {

}

const dynamicComponent = computed(() => {
  return {
    chart: Chart,
    orderbook: Orderbook,
    trade: LastTrade,
  }[currentTab.value]
})
</script>

<template>
  <div class="pl-0.16">
    <div class="mb-0.08 mt-0.08 py-0.04">
      <v-tabs v-model="currentTab" :options="tabOptions" @change="onChangeTab" />
    </div>
    <keep-alive>
      <component :is="dynamicComponent" class="relative h-5.4 overflow-hidden" />
    </keep-alive>
  </div>
</template>

<style scoped>

</style>
