<script setup lang="ts">
import { Ethereum, ScrollSepolia } from '@particle-network/chains'
import type { Chain, ConnectConfig, Provider } from '@particle-network/connect'
import { ParticleConnect, metaMask, walletconnect } from '@particle-network/connect'
import { getAddress } from 'viem'
import { ref } from 'vue'

const provider = ref<Provider | undefined>(void 0)
const phone = ref<string>('')
const email = ref<string>('')

const config: ConnectConfig = {
  projectId: import.meta.env.VITE_PARTICLE_PROJECT_ID,
  clientKey: import.meta.env.VITE_PARTICLE_CLIENT_KEY,
  appId: import.meta.env.VITE_PARTICLE_APP_ID,
  chains: [ScrollSepolia],
}

const connectKit = new ParticleConnect(config)

async function connectWallet(id: string, options?: any) {
  console.log('connectWallet', id, options)
  try {
    const connectProvider = await connectKit.connect(id, options)
    provider.value = connectProvider
  }
  catch (error) {
    console.error('connectWallet', error)
  }
}

async function test() {
  console.log(provider.value)
  console.log(config)
  const accounts = (
    await provider.value?.request({
      method: 'eth_accounts',
    })
  ).map((x: string) => getAddress(x))
  console.log(accounts)
}

onMounted(async () => {
  const id = connectKit.cachedProviderId()
  if (id) {
    return connectKit
      .connectToCachedProvider()
      .then(async (_provider) => {
        provider.value = _provider
        const accounts = (
          await provider.value?.request({
            method: 'eth_accounts',
          })
        ).map((x: string) => getAddress(x))
        console.log(accounts)
      })
      .catch((error) => {
        console.error(error)
      })
  }
})
</script>

<template>
  <div class="main-box">
    <div class="custom-button" @click="connectWallet('metamask')">
      MetaMask
    </div>

    <div class="custom-button" @click="connectWallet('walletconnect_v2')">
      WalletConnect
    </div>

    <div class="custom-button" @click="connectWallet('particle')">
      Particle
    </div>

    <div class="custom-button" @click="connectWallet('particle', { preferredAuthType: 'google' })">
      Connect With Google
    </div>

    <input v-model="phone" class="input" type="number" placeholder="phone">
    <div class="custom-button" @click="connectWallet('particle', { preferredAuthType: 'phone', account: phone })">
      Connect With Phone
    </div>
    <input v-model="email" class="input" type="email" placeholder="email">

    <div class="custom-button" @click="connectWallet('particle', { preferredAuthType: 'email', account: email })">
      Connect With Email
    </div>

    <n-button @click="test">
      123
    </n-button>
  </div>
</template>

<style lang="scss" scoped>
.main-box {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.custom-button {
    margin: 20px;
    background-color: #5177f9;
    font-size: 14px;
    color: white;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
    max-width: 100%;
}

.custom-button:hover {
    opacity: 0.8;
}

.input {
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border: 1px solid #eee;
    padding: 0 10px;
}
</style>
