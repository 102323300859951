<script setup lang="ts">
import giftImg from '@/assets/image/voyage/gift.png'
import type { ReferralRes } from '@/service/modules/stats'
import type { Column } from '~/components/v/Table.vue'

const { isConnected, userStatsInfo } = useUser()
const { isSigned } = useKey()
const { openModal } = useModal()
const { voyageSummary, currentTab, contentRef } = useVoyage()
const { copy } = useClipboard({ source: '' })
const message = useMessage()
const route = useRoute()

const tableColumns: Column[] = [
  {
    key: 'userName',
    title: 'Voyager',
    minWidth: 200,
  },
  {
    key: 'point',
    title: 'Referral Coins',
    minWidth: 200,
    align: 'right',
  },
  {
    key: 'lpPointReturn',
    title: 'Vessel Mileage Returned',
    minWidth: 200,
    align: 'right',
  },
  {
    key: 'joinedDate',
    title: 'Joined Date',
    minWidth: 200,
    align: 'right',
  },
]

const { run: getReferralInfo, isLoading } = useHttp(vesselApi.stats.getReferralInfo)
const { run: getTwitterUrl } = useHttp(vesselApi.stats.getTwitterUrl)

const referralInfo = ref<ReferralRes>()

const isFollowed = computed(() => referralInfo.value?.isFollowingOfficial && referralInfo.value?.hasSufficientDeposit)
// const referralPoints = computed(() => {
//   const c = referralInfo.value.referralPointsAllocationConfig
//   const m = referralInfo.value.teamMemberCount
//   return c.reduce((res, i) => res + Math.max(i.points * Math.min(m - i.threshold, 5), 0), 0)
// })
const referralPoints = computed(() => voyageSummary.value.referralPointTotal)

function handleClickDeposit() {
  if (!referralInfo.value?.hasSufficientDeposit) {
    openModal('deposit')
  }
}
function handleClickTwitter() {
  if (!referralInfo.value?.isFollowingOfficial) {
    getTwitterUrl().then((res) => {
      openUrl(res.data.authorizationUrl)
    })
  }
}

const inviteUrl = computed(() => `${location.origin}/login?code=${userStatsInfo.value?.ownInvitationCode}`)
const currentLevel = computed(() => {
  const c = referralInfo.value.referralPointsAllocationConfig
  return c.filter(i => +i.threshold <= +referralInfo.value.teamMemberCount).at(-1)
})

function unlockInvite() {
  if (!isFollowed.value) {
    return
  }
  vesselApi.stats.unlockInviteCode().then((res) => {
    userStatsInfo.value.ownInvitationCode = res.data.ownInvitationCode
  })
}

function copyLink() {
  copy(inviteUrl.value).then(() => {
    message.success('Link Copied!')
  })
}

whenever(isSigned, () => {
  getReferralInfo().then((res) => {
    referralInfo.value = res.data
  })
}, {
  immediate: true,
})
</script>

<template>
  <div v-if="!isSigned" class="bg mb-0.4 flex-center flex-1 flex-col items-center gap-0.12 rd-0.12 px-0.16 py-0.32">
    <img :src="giftImg" alt="">
    <div class="text-center text-caption2m text-grey1">
      Refer voyagers to earn referral points. <br>
      And enjoy 16% Vessel Mileage from your friends.
    </div>
    <common-connect-wallet-button v-if="!isConnected" />
    <common-sign-for-trading-button v-else-if="!isSigned" />
  </div>
  <div v-else class="grid grid-cols-2 mb-0.4 gap-0.14">
    <div class="bg">
      <div v-if="isLoading" />
      <div v-else-if="isFollowed">
        <div class="flex justify-center gap-0.5">
          <div class="flex-center flex-col">
            <div class="mb-0.6 flex flex-col items-center">
              <div class="text-caption2m text-grey1">
                Referral Coins
              </div>
              <div class="bg-line mb-0.04 mt-0.02 w-1 pt-0.01" />
              <div class="text-body1b text-white2">
                {{ referralPoints }}
              </div>
            </div>
            <div class="flex flex-col items-center">
              <div class="text-caption2m text-grey1">
                Referral Rank
              </div>
              <div class="bg-line mb-0.04 mt-0.02 w-1 pt-0.01" />
              <div class="text-body1b text-white2">
                Lv.{{ currentLevel?.rank || '0' }}
              </div>
            </div>
          </div>
          <VoyageReferralRing
            :count="+referralInfo.teamMemberCount" :point="+currentLevel?.points"
            :config="referralInfo.referralPointsAllocationConfig"
          />
        </div>
      </div>
      <div v-else class="flex-center flex-col gap-0.4 py-0.32">
        <div class="flex items-center gap-x-0.1">
          <VoyageSvgDownload />
          <div
            class="h-0.48 w-4.55 flex flex-1 items-center justify-between rd-0.08 bg-black3 px-0.12"
            :class="{
              'is-active cursor-pointer': !referralInfo?.hasSufficientDeposit,
            }"
            @click="handleClickDeposit"
          >
            <div class="text-body2b text-white1">
              Deposit value: ≥$5
            </div>
            <SvgBack v-if="!referralInfo?.hasSufficientDeposit" fill="#fff" class="rotate-180" />
            <SvgSuccess v-else />
          </div>
        </div>
        <div class="flex items-center gap-x-0.1">
          <VoyageSvgX />
          <div
            class="h-0.48 w-4.55 flex flex-1 items-center justify-between rd-0.08 bg-black3 px-0.12"
            :class="{
              'is-active cursor-pointer': !referralInfo?.isFollowingOfficial,
            }"
            @click="handleClickTwitter"
          >
            <div class="text-body2b text-white1">
              Follow @VesselFinance on X
            </div>
            <SvgBack v-if="!referralInfo?.isFollowingOfficial" fill="#fff" class="rotate-180" />
            <SvgSuccess v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="bg flex-center">
      <div v-if="userStatsInfo?.ownInvitationCode" class="flex-center flex-col gap-0.12">
        <img :src="giftImg" alt="" class="w-0.9">
        <div class="text-center text-caption2m text-grey1">
          Your Referral Link
        </div>
        <div class="text-body2 text-primary">
          {{ inviteUrl }}
        </div>
        <v-button class="w-1.2" @click="copyLink">
          Copy Link
        </v-button>
      </div>
      <div v-else class="flex-center flex-col gap-0.12">
        <img :src="giftImg" alt="" class="w-0.9">
        <div class="text-center text-caption2m text-grey1">
          Refer voyagers to earn referral bounty. <br>And enjoy 16% Vessel Mileage from your referee.
        </div>
        <v-button :disabled="!isFollowed" class="w-1.2" @click="unlockInvite">
          Unlock
        </v-button>
      </div>
    </div>
    <div class="bg col-span-2">
      <div class="mb-0.12 text-0.18 text-grey3 font-600 leading-0.24">
        Referred Voyagers
      </div>
      <div class="mb-0.12 text-caption1b text-grey1">
        View the voyagers you've invited and their contribution.
        <span class="cursor-pointer text-caption4u text-primary" @click="openUrl('https://vesselfinance.gitbook.io/vessel/vessel-voyage/vessel-coins-for-referral')">Learn more</span>
      </div>
      <v-table
        class="min-h-3 overflow-auto"
        :data="referralInfo?.teamMembers || []"
        :columns="tableColumns"
        :column-gap="12"
        :loading="isLoading"
        row-key="time"
        content-class="gap-y-0.08  min-h-2.8 "
        row-class="gap-x-0.16 py-0.04 h-0.52 text-0.16 text-body2b"
        title-row-class="gap-x-0.16 text-caption2b py-0.04"
      >
        <template #title-point>
          <div class="flex items-center justify-end gap-0.04">
            <v-question class="mr-0.04">
              Referral coins are issued once new users finished their registration and complete a $5 deposit.
            </v-question>
            Referral Coins
          </div>
        </template>
        <template #title-lpPointReturn>
          <div class="flex items-center justify-end gap-0.04">
            <v-question class="">
              You will receive 16% of the Vessel Mileage earned by the invited users as a reward.
            </v-question>
            Vessel Mileage Returned
          </div>
        </template>
        <template #joinedDate="{ data: { row } }">
          {{ dayjs(row.joinedDate).format('MM/DD/YYYY') }}
        </template>
        <template #lpPointReturn="{ data: { row } }">
          {{ formatNumber(row.lpPointReturn, 0) }}
        </template>
        <template #point="{ data: { row } }">
          {{ formatNumber(row.referralPoint, 0) }}
        </template>
        <template #noData>
          You haven't invite any voyagers yet.
        </template>
      </v-table>
    </div>
  </div>
</template>

<style scoped>
.bg {
  border-radius: 12px;
  border: 0.4px solid var(--vessel-color-black-3, #353945);
  background: rgba(20, 20, 22, 0.50);
  backdrop-filter: blur(10px);
  padding: 32px 16px;
}

.bg-line {
  background: linear-gradient(245deg, rgba(25, 242, 255, 0.00) 21.31%, #19F2FF 54%, rgba(45, 159, 246, 0.00) 86.7%);
}

.is-active {
  background: linear-gradient(90deg, #353945 0.13%, #1F4357 99.87%);
}
</style>
