<script setup lang="ts">
import {
  useAccount,
  useChainId,
  useClient,
  useConnect,
  useDisconnect,
  useReconnect,
  useSendTransaction,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from '@wagmi/vue'
import { parseEther, parseUnits } from 'viem'
import erc20Abi from '~/abi/erc20.json'

const chainId = useChainId()
const { connectors, connect, connectAsync } = useConnect()
const account = useAccount()

const { address, connector, chain } = useAccount()
const { disconnect } = useDisconnect()
const { chains, switchChain } = useSwitchChain()

const { writeContractAsync, status, data: hash } = useWriteContract()
const { sendTransactionAsync, data: txHash } = useSendTransaction()
const { data: receipt, status: txStatus } = useWaitForTransactionReceipt({
  hash,
  retryCount: 20,
})
const client = useClient()
const { reconnectAsync } = useReconnect()
const { currentChainId } = useConnector()

watch(receipt, console.log)

async function mint() {
  await reconnectAsync()

  console.log({
    abi: erc20Abi,
    functionName: 'mint',
    address: '0x115d7113e5df03fd95242ab0183d343ff1ed13b3',
    account: address.value,
    args: [
      parseUnits('1', 8),
    ],
    chain: chain.value,
  })
  console.log(1)

  writeContractAsync({
    abi: erc20Abi,
    functionName: 'mint',
    address: '0x115d7113e5df03fd95242ab0183d343ff1ed13b3',
    account: address.value,
    args: [
      parseUnits('1', 8),
    ],
    chain: chain.value,
  }).then((res) => {
    console.log(res)
  })
}

function sendtx() {
  sendTransactionAsync({
    to: '0xA93b77a7cb8Ed77Ee3257261471CfB370D568e10',
    value: parseEther('0.001'),
  }).then((res) => {
    console.log(res)
  })
}

async function logConnectors() {
  console.log(connectors.map(i => ({ id: i.id, name: i.name, type: i.type })))
  const cc = connectors.find(i => i.id === 'particleWalletSDK')
  console.log(cc)
  connectAsync({ connector: cc, chainId: currentChainId.value }).then((res) => {
    console.log('11.8166C1', res)
  })

  console.log(connector)
  const accounts = await connector.value?.getAccounts()
  console.log(accounts)
}

onMounted(() => {
  // reconnectAsync()
  console.log(connectors.map(i => ({ id: i.id, name: i.name, type: i.type })))
})
</script>

<template>
  <div>
    <div>Address: {{ address }}</div>
    {{ account.chain }}
    {{ account.chainId }}
    {{ account.address }}
    <div>Connected to {{ connector?.name }} Connector.</div>
    <n-button @click="disconnect()">
      Disconnect
    </n-button>

    <button @click="switchChain({ chainId: (chains[0].id) })">
      chageChain
    </button>

    <div>
      {{ client }}
    </div>

    <n-button @click="mint">
      111
    </n-button>
    {{ status }}
    <n-button @click="sendtx">
      send tx
    </n-button>
    {{ status }}
    {{ txHash }}
    {{ txStatus }}
    <div>
      <n-button @click="logConnectors">
        connectors
      </n-button>
    </div>
  </div>
</template>
