<script setup lang="tsx">
import type { SelectOption } from 'naive-ui'
import type { Column } from '~/components/v/Table.vue'
import VTickSpacing from '~/components/v/TickSpacing.vue'
import type { PoolHistoryItem } from '~/service/modules/amm'

const props = defineProps<{
  poolId?: number
  left?: number
  right?: number
  hideFilter?: boolean
}>()

const { poolDetailList, updatePool } = useAmm()
const { symbolList } = useSymbol()

const filter = ref({
  poolId: props.poolId || null,
  type: null,
  range: [dayjs().subtract(6, 'days').startOf('day').valueOf(), dayjs().valueOf()],
})

const noMore = ref(false)
const typeList = ['Add Liquidity', 'Remove Liquidity', 'Collect Fee']
const poolList = computed(() => [...poolDetailList.value.map(i => ({ value: i.poolId, label: addSymbolSlash(i.symbol) }))])

const { run: getPoolHistory, isLoading } = useHttp(vesselApi.amm.getPoolHistory)

const { isMobile } = useResponsive()
const { isSigned } = useKey()
const { currencyList } = useSymbol()

const poolHistory = ref<PoolHistoryItem[]>([])

const tableColumns = computed<Column[]>(() => [
  {
    key: 'time',
    title: 'Time',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'type',
    title: 'Type',
    minWidth: 120,
    width: 80,
    align: 'left',
  },
  {
    key: 'pool',
    title: 'Pool',
    minWidth: 240,
    align: 'left',
  },
  {
    key: 'range',
    title: 'Price Range',
    minWidth: 200,
    align: 'right',
  },
  {
    key: 'total',
    title: 'Total Value',
    minWidth: 120,
    align: 'right',
  },
  {
    key: 'amount',
    title: 'Amount',
    minWidth: 150,
    align: 'right',
  },
])

function getPoolItemByPoolId(poolId: string) {
  return poolDetailList.value.find(i => i.poolId === poolId)
}

function getPoolHistoryList(isReset = false) {
  if (isReset) {
    poolHistory.value = []
  }
  getPoolHistory({
    fromId: poolHistory.value.length ? poolHistory.value.at(-1)?.id : undefined,
    limit: ORDER_PAGE_SIZE,
    startTime: props.poolId ? null : filter.value.range[0],
    endTime: props.poolId ? null : dayjs(filter.value.range[1]).endOf('day').valueOf(),
    type: filter.value.type?.toUpperCase().replace(' ', '_'),
    poolId: filter.value.poolId,
    tickL: props.left,
    tickR: props.right,
  }).then((res) => {
    if (isReset) {
      poolHistory.value = []
    }
    noMore.value = res.data.history.length < ORDER_PAGE_SIZE
    poolHistory.value.push(...res.data.history)
  })
}

const poolHistoryFiltered = computed(() => poolHistory.value
  .filter(i => !props.poolId || +i.poolId === +props.poolId)
  .filter(i => !props.left || +i.tickL === +props.left)
  .filter(i => !props.right || +i.tickR === +props.right),
)

function getTotalPrice(row: PoolHistoryItem) {
  const basePrice = currencyList.value.find(i => i.assetName === row.baseAssetName)?.price
  const quotePrice = currencyList.value.find(i => i.assetName === row.quoteAssetName)?.price
  return `$${formatNumber(Math.abs(+(+row.baseAssetAmount * +basePrice + +row.quoteAssetAmount * +quotePrice)), 2)}`
}

function reset() {
  filter.value = {
    poolId: null,
    type: null,
    range: null,
  }
}

function handleLoad() {
  if (isLoading.value || noMore.value)
    return
  getPoolHistoryList()
}

function renderLabel(option: SelectOption) {
  const item = poolDetailList.value.find(i => i.poolId === option.value)
  return (
    <div class="flex items-center justify-start gap-x-0.12">
      {option.label}
      <VTickSpacing tickSpacing={item.tickSpacing} token={getCurrencyFromSymbol(item.symbol).quote} />
    </div>
  )
}

watch(computed(() => [filter]), () => {
  getPoolHistoryList(true)
}, {
  deep: true,
})

whenever(isSigned, () => {
  updatePool()
  getPoolHistoryList(true)
}, {
  immediate: true,
})
</script>

<template>
  <div class="relative w-full flex-1 flex-col overflow-hidden">
    <div v-if="!hideFilter" class="flex items-center gap-x-0.12" :class="{ 'px-0.32': !poolId }">
      <n-date-picker
        v-model:value="filter.range"
        start-placeholder="MM/DD/YYYY"
        end-placeholder="MM/DD/YYYY"
        type="daterange" clearable
        size="small"
        format="MM/dd/yyyy"
      >
        <template #date-icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.332 2.00033H12.6654V0.666992H11.332V2.00033H4.66536V0.666992H3.33203V2.00033H2.66536C1.93203 2.00033 1.33203 2.60033 1.33203 3.33366V14.0003C1.33203 14.7337 1.93203 15.3337 2.66536 15.3337H13.332C14.0654 15.3337 14.6654 14.7337 14.6654 14.0003V3.33366C14.6654 2.60033 14.0654 2.00033 13.332 2.00033ZM13.332 14.0003H2.66536V6.66699H13.332V14.0003ZM13.332 5.33366H2.66536V3.33366H13.332V5.33366Z" fill="#777E91" />
          </svg>
        </template>
      </n-date-picker>
      <n-config-provider :theme-overrides="selectBorderOverrides" abstract>
        <n-select
          v-model:value="filter.poolId" class="w-2.5" clearable
          :options="poolList" placeholder="Pool" :render-label="renderLabel"
        />
        <n-select
          v-model:value="filter.type" class="w-1.5" clearable
          :options="typeList.map(i => ({ value: i, label: i }))" placeholder="Type"
        />
      </n-config-provider>
      <!--      <div class="cursor-pointer text-0.14 font-500 font-dm text-white2" @click="reset"> -->
      <!--        Reset -->
      <!--      </div> -->
    </div>
    <n-infinite-scroll v-if="!isMobile" style="height: calc(100% - 55px)" :distance="10" class="mt-0.12" @load="handleLoad">
      <v-table
        class="min-h-3 overflow-auto"
        :data="poolHistoryFiltered"
        :columns="tableColumns"
        :loading="isLoading"
        content-class="min-h-2"
        :column-gap="35"
        row-class="h-0.68 py-0.14 px-0.32 gap-x-0.16 border-t-1 border-t-black3"
        title-row-class="py-0.04 px-0.32 gap-x-0.16"
        row-key="time"
      >
        <template #time="{ data: { row } }">
          <div>
            <div>{{ dayjs(+row.timestamp).format('MM/DD/YYYY') }}</div>
            <div>{{ dayjs(+row.timestamp).format('HH:mm:ss') }}</div>
          </div>
        </template>
        <template #type="{ data: { row } }">
          <div>
            {{ row.type.toLowerCase().split('_').join(' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}
          </div>
        </template>
        <template #pool="{ data: { row } }">
          <div class="flex items-center gap-x-0.08">
            <v-icon-pair :base="row.baseAssetName" :quote="row.quoteAssetName" :size="24" />
            <div class="">
              <span class="text-caption1">{{ row.baseAssetName }}/{{ row.quoteAssetName }}</span>
              <div class="flex items-center">
                <VTickSpacing :tick-spacing="getPoolItemByPoolId(row.poolId)?.tickSpacing" :token="row.quoteAssetName" />
              </div>
            </div>
          </div>
        </template>
        <template #range="{ data: { row } }">
          <div class="">
            {{ formatNumber(row.tickL, -1) }} {{ row.quoteAssetName }} per {{ row.baseAssetName }} - <br>
            {{ formatNumber(row.tickR, -1) }} {{ row.quoteAssetName }} per {{ row.baseAssetName }}
          </div>
        </template>
        <template #total="{ data: { row } }">
          <div class="">
            {{ getTotalPrice(row) }}
          </div>
        </template>
        <template #amount="{ data: { row } }">
          <div class="">
            {{ formatNumber(Math.abs(+row.baseAssetAmount), -1) }} {{ row.baseAssetName }}
          </div>
          <div class="">
            {{ formatNumber(Math.abs(+row.quoteAssetAmount), -1) }} {{ row.quoteAssetName }}
          </div>
        </template>
      </v-table>
    </n-infinite-scroll>

    <div v-else>
      <div v-for="(row, index) in poolHistory" :key="index">
        <div class="mb-0.14 flex justify-between text-grey1">
          <div>Time</div>
          <div class="text-white2">
            {{ dayjs(+row.timestamp).format('MM/DD/YYYY HH:mm:ss') }}
          </div>
        </div>

        <div v-if="index !== poolHistory.length - 1" class="mb-0.16 w-full bg-black3 pt-0.01" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
