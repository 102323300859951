<script setup lang="ts">
import bgImg from '@/assets/image/airdrop/share-bg.png'
import shareImg from '@/assets/image/airdrop/share-logo.png'
import collectButtonImg from '@/assets/image/airdrop/collect-button-disabled.png'

const showShare = defineModel<boolean>('show')
const { airdropInfo } = useAirdrop()
const { address } = useUser()
</script>

<template>
  <v-modal
    v-model:show="showShare"
    modal-class="w-4.48 text-grey1"
    :z-index="201"
    title="Share Your Success"
  >
    <div class="relative h-2.15 w-3.2 bg-cover sm:w-3.84" :style="{ backgroundImage: `url(${bgImg})` }">
      <img class="absolute right-0.3 top-0.3 sm:w-0.42" :src="shareImg" alt="">
      <div class="flex flex-col scale-80 items-center">
        <div
          class="flex-center sm:mt-0.16 sm:h-0.24 sm:w-0.24 sm:rd-full"
          style="background:linear-gradient(180deg, #37AAEC 0%, #19F2FF 100%);box-shadow: 1.5px 3px 9px 0px rgba(204, 252, 255, 0.20) inset;"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.64945C6 3.59445 7.19 4.00945 7.78 3.27445C7.915 3.10945 8.15 3.08445 8.315 3.20945C9.52 4.07445 10.23 5.62445 9.925 7.25945C9.715 8.37945 9.015 9.33445 8.07 9.90945C8.42 9.39945 8.58 8.75945 8.455 8.07945C8.365 7.56445 8.085 7.10445 7.715 6.73945L6.35 5.39445C6.155 5.20445 5.845 5.20445 5.65 5.39445L4.295 6.72945C3.915 7.09945 3.635 7.56945 3.54 8.08945C3.48162 8.40766 3.48737 8.73433 3.55692 9.05029C3.62646 9.36626 3.7584 9.66515 3.945 9.92945C2.78 9.23445 2 7.95945 2 6.49945C2 3.98445 4.06 2.24445 5.225 1.46445C5.555 1.24445 6 1.48445 6 1.88445V2.64945ZM4.98486 7.44922L5.99986 6.44922L7.01486 7.45422C7.24986 7.67922 7.42486 7.96922 7.47986 8.28922C7.62986 9.20422 6.90986 9.99922 5.99986 9.99922C5.08986 9.99922 4.36986 9.20422 4.51986 8.28422C4.57486 7.96422 4.75486 7.67922 4.98486 7.44922Z" fill="#141416" />
          </svg>
        </div>
        <div class="mt-0.04 text-0.14 font-500 leading-0.24 font-poppins text-primary">
          Total Points
        </div>
        <div class="text-0.48 font-0.48 font-700 tracking--0.0096 font-dm text-white2 sm:leading-0.56">
          {{ airdropInfo?.totalScore || '-' }}
        </div>
        <div class="share-button mt-0.06 h-0.5 w-1.5 flex scale-66 items-center justify-center px-0.1 text-0.14 text-white2">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10" fill="none" class="mr-0.05 flex-none">
            <path d="M7.736 2.93054V2.19207C7.736 1.78481 7.4048 1.45361 6.99754 1.45361H2.19754C1.58683 1.45361 1.08984 1.9506 1.08984 2.56131V6.99207C1.08984 7.80475 1.75224 8.09977 2.19754 8.09977H7.736C8.14326 8.09977 8.47446 7.76857 8.47446 7.36131V3.669C8.47446 3.26174 8.14326 2.93054 7.736 2.93054ZM6.99754 6.25361H6.25907V4.77669H6.99754V6.25361ZM2.19754 2.93054C2.10247 2.92628 2.0127 2.88552 1.94693 2.81675C1.88116 2.74797 1.84445 2.65647 1.84445 2.56131C1.84445 2.46614 1.88116 2.37464 1.94693 2.30587C2.0127 2.23709 2.10247 2.19633 2.19754 2.19207H6.99754V2.93054H2.19754Z" fill="#FCFCFD" />
          </svg>
          {{ shortenAddress(address) }}
        </div>
        <div class="scale-75 font-400 text-grey2">
          Update at {{ dayjs().format('MM, DD YYYY HH:mm') }}
        </div>
      </div>
    </div>
    <template #footer>
      <div class="mt-0.43 flex gap-x-0.16">
        <v-button type="outline" class="flex-1">
          Save Image
        </v-button>
        <v-button type="primary" class="flex-1">
          Share to Twitter
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.share-button {
  border-radius: 55.828px;
border: 0.248px solid #FFF;
background: linear-gradient(98deg, #1F7DC1 0.41%, #19F1FF 52.92%, #37AAEC 100.41%);
}
</style>
