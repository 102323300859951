<script setup lang="ts">
const { currentSymbol } = useSymbol()
</script>

<template>
  <div class="relative w-full">
    <AssetHistoryOpen :history-current-symbol="currentSymbol" class="h-3.5" />
  </div>
</template>

<style scoped>

</style>
