<script setup lang="ts">
import BN from 'bignumber.js'
import type { UserPositionItem } from '@/service/modules/amm'

const route = useRoute()
const router = useRouter()

const { poolDetailList, updatePool, selectPool, getUserPosition } = useAmm()
const { symbolList, currencyList, tickerInfo } = useSymbol()
const { symbolPrice, assetBalance } = useWs()
const { run: addPool, isLoading: isLoadingAdd } = useHttp(vesselApi.amm.addPool)

const showPreview = ref(false)
const isMinHigherThanMin = ref(false)
const isTickTooSmall = ref(false)
const isAdding = ref(false)

const currencyDirection = ref('')
const lastEditAmount = ref<'base' | 'quote'>('base')

const notification = useNotification()

const form = ref<{
  symbol: string
  min: string
  max: string
  minIndex: number
  maxIndex: number
  baseAmount: string
  quoteAmount: string
}>({
  symbol: '',
  minIndex: 0,
  maxIndex: 0,
  min: '',
  max: '',
  baseAmount: '',
  quoteAmount: '',
})

function back() {
  if (history.state.back) {
    router.back()
  }
  else {
    router.replace('/pools')
  }
}

const currentSymbol = computed(() => poolDetailList.value.find(i => i.poolId === selectPool.value.poolId)?.symbol)
const currentSymbolItem = computed(() => poolDetailList.value.find(i => i.poolId === selectPool.value.poolId))
const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbol.value))

const currencyPair = computed(() => {
  return getCurrencyFromSymbol(currentSymbol.value)
})

const currentPairSwitch = computed(() => {
  if (currencyPair.value?.base) {
    return [currencyPair.value.base, currencyPair.value.quote]
  }
  else {
    return ['base', 'quote']
  }
})

const isQuote = computed(() => currencyDirection.value === currencyPair.value?.base)
const priceSuffix = computed(() => isQuote.value
  ? `${currencyPair.value?.quote} per ${currencyPair.value?.base}`
  : `${currencyPair.value?.base} per ${currencyPair.value?.quote}`,
)
const currentPrice = computed(() => {
  return symbolPrice.value?.[currentSymbol.value]?.closePrice
})
const currentPriceIndex = computed(() => getSymbolIndex(currentPrice.value))

function getAvailableBalance(assetName: string) {
  return +assetBalance.value?.find(i => i.assetName === assetName)?.available || 0
}

function init() {
  if (symbolList.value.length) {
    const symbol = route.query.symbol as string
    if (symbol) {
      form.value.symbol = symbol
    }
    else {
      form.value.symbol = symbolList.value[0].symbol
    }
    currencyDirection.value = getCurrencyFromSymbol(currentSymbol.value)?.base
  }
}

const isInsufficientBase = computed(() => +form.value.baseAmount > getAvailableBalance(currencyPair.value?.base))
const isInsufficientQuote = computed(() => +form.value.quoteAmount > getAvailableBalance(currencyPair.value?.quote))

const isInsufficient = computed(() => {
  if (form.value.minIndex < currentPriceIndex.value && currentPriceIndex.value < form.value.maxIndex) {
    return isInsufficientBase.value || isInsufficientQuote.value
  }
  if (form.value.minIndex > currentPriceIndex.value) {
    return isInsufficientBase.value
  }
  if (form.value.maxIndex < currentPriceIndex.value) {
    return isInsufficientQuote.value
  }
  return false
})

const isDisablePreview = computed(() => {
  return isMinHigherThanMin.value || !form.value.baseAmount || !form.value.quoteAmount || isInsufficient.value || isTickTooSmall.value
})

function onPreview() {
  if (!form.value.min || !form.value.max || +form.value.min >= +form.value.max || isDisablePreview.value) {
    return
  }
  showPreview.value = true
}

function getSymbolIndex(value: string | number) {
  if (!value) {
    return 0
  }
  const currentSymbolAmmItem = currentSymbolItem.value
  if (+value < +currentSymbolAmmItem?.minTick) {
    return 0
  }
  if (+value > +currentSymbolAmmItem?.maxTick) {
    return Math.round((+currentSymbolAmmItem.maxTick - +currentSymbolAmmItem.minTick) / +currentSymbolAmmItem.tickSpacing)
  }
  return Math.round((+value - +currentSymbolAmmItem?.minTick) / +currentSymbolAmmItem?.tickSpacing)
}

function getSymbolValueFromIndex(index: number) {
  const currentSymbolAmmItem = currentSymbolItem.value
  return BN(currentSymbolAmmItem?.minTick).plus(BN(currentSymbolAmmItem?.tickSpacing).multipliedBy(BN(index))).toString()
}

const minBaseAmount = computed(() => {
  if (currentPriceIndex.value > form.value.minIndex) {
    return (form.value.maxIndex - currentPriceIndex.value + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
  }
  return (form.value.maxIndex - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
})
const minQuoteAmount = computed(() => {
  if (currentPriceIndex.value < form.value.maxIndex) {
    return (currentPriceIndex.value - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
      * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2)
  }
  return (form.value.maxIndex - form.value.minIndex + 1) * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2) / 10 ** +currentTickerItem.value.baseAssetPrecision
})

const isOutOfRange = computed(() => (form.value.maxIndex && form.value.maxIndex <= currentPriceIndex.value)
  || (form.value.minIndex && form.value.minIndex >= currentPriceIndex.value))

function changeAmount() {
  form.value.baseAmount = form.value.baseAmount.replace(/^([^.]*)\.([^.]*)\.?/, '\$1.\$2')
  form.value.quoteAmount = form.value.quoteAmount.replace(/^([^.]*)\.([^.]*)\.?/, '\$1.\$2')
  const currentTickerItem = tickerInfo.value.find(i => i.symbol === form.value.symbol)

  if (form.value.minIndex && form.value.maxIndex && form.value.minIndex >= currentPriceIndex.value) {
    form.value.quoteAmount = '0'
  }
  if (form.value.minIndex && form.value.maxIndex && form.value.maxIndex <= currentPriceIndex.value) {
    form.value.baseAmount = '0'
  }

  if (currentPriceIndex.value <= form.value.minIndex) {
    isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount
  }
  else if (currentPriceIndex.value >= form.value.maxIndex) {
    isTickTooSmall.value = minQuoteAmount.value > +form.value.quoteAmount
  }

  if (isOutOfRange.value) {
    return
  }

  if (lastEditAmount.value === 'base') {
    const p = +getSymbolValueFromIndex(currentPriceIndex.value)
    const A = +getSymbolValueFromIndex(form.value.minIndex)
    const B = +getSymbolValueFromIndex(form.value.maxIndex)
    const y = +form.value.baseAmount
    const t = +currentSymbolItem.value.tickSpacing
    console.log(A, B, p, y, t)

    const r = 0.5
    const s = y / ((B - p - t) / t + r)
    const x = s * ((p - A) / t) * ((p - t + A) / 2) + s * (1 - r) * p
    console.log(r, s, x)

    form.value.quoteAmount = Number.isNaN(x) ? '' : `${floorTo(x, +currentTickerItem.quoteAssetPrecision)}`
  }
  else {
    const p = +getSymbolValueFromIndex(currentPriceIndex.value)
    const A = +getSymbolValueFromIndex(form.value.minIndex)
    const B = +getSymbolValueFromIndex(form.value.maxIndex)
    const x = +form.value.quoteAmount
    const t = +currentSymbolItem.value.tickSpacing
    console.log(A, B, p, x, t)

    const l = 0.5
    const s = x / (p * l + (p - A) / t * (p - t + A) / 2)
    const y = ((B - p - t) / t + 1 - 1) * s
    console.log(l, s, y)

    form.value.baseAmount = Number.isNaN(y) ? '' : `${floorTo(y, +currentTickerItem.baseAssetPrecision)}`
  }

  if (form.value.minIndex < currentPriceIndex.value && currentPriceIndex.value < form.value.maxIndex) {
    isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount || minQuoteAmount.value > +form.value.quoteAmount
  }
}

function onMax(type: 'base' | 'quote') {
  lastEditAmount.value = type
  form.value[`${type}Amount`] = `${getAvailableBalance(currencyPair.value[type])}`
  changeAmount()
}

function getPrice(amount: string, assetName: string) {
  const price = currencyList.value.find(i => i.assetName === assetName)?.price || 0

  return `$${formatNumber(BN(amount || 0).multipliedBy(BN(price)).toFixed(2), 2)}`
}

function getSymbolItemByPoolId(poolId: string) {
  const symbol = poolDetailList.value.find(i => i.poolId === poolId)?.symbol
  return symbolList.value.find(i => i.symbol === symbol)
}

function isInRange(row: UserPositionItem) {
  const currentPrice = +symbolPrice.value?.[getSymbolItemByPoolId(row.poolId)?.symbol]?.closePrice
  return +row.tickL < currentPrice && currentPrice < +row.tickR
}

function onChangeAmount(type: 'base' | 'quote') {
  lastEditAmount.value = type
  changeAmount()
}

async function addLiquidity() {
  if (isAdding.value) {
    return
  }
  isAdding.value = true
  // add
  const signMessage = {
    poolId: +currentSymbolItem.value.poolId,
    addType: 1,
    tickIndexL: form.value.minIndex,
    tickIndexR: form.value.maxIndex,
    baseAmount: Math.floor(+form.value.baseAmount * 10 ** +currentTickerItem.value.baseAssetPrecision) / 10 ** +currentTickerItem.value.baseAssetPrecision,
    quoteAmount: Math.floor(+form.value.quoteAmount * 10 ** +currentTickerItem.value.quoteAssetPrecision) / 10 ** +currentTickerItem.value.quoteAssetPrecision,
    timestamp: new Date().getTime(),
    // timestamp: 1710195590372,
    // nonce: 72,
    nonce: new Date().getTime(),
  }
  const signature = await signPool(signMessage, currentSymbol.value)
  console.log(signMessage.timestamp)

  addPool({
    ...signMessage,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': signMessage.timestamp,
    },
  }).then((res) => {
    if (res.data.error) {
      return
    }
    notification.success({
      title: 'Increase Liquidity Confirmation',
      content: `Your liquidity of ${res.data.baseAssetAmount} ${currencyPair.value.base} and ${res.data.quoteAssetAmount} ${currencyPair.value.quote} has been successfully increased to the ${currencyPair.value.base}/${currencyPair.value.quote} pool.`,
      duration: 2000,
    })
    showPreview.value = false
    isAdding.value = false
    getUserPosition()
    router.back()
  })
}

watch(symbolList, () => {
  init()
})
onMounted(() => {
  if (!selectPool.value) {
    router.replace('/pools')
  }
  else {
    const symbol = poolDetailList.value.find(i => i.poolId === selectPool.value.poolId)?.symbol
    form.value.symbol = symbol
    nextTick(() => {
      form.value = {
        symbol,
        minIndex: getSymbolIndex(selectPool.value.tickL),
        maxIndex: getSymbolIndex(selectPool.value.tickR),
        min: `${selectPool.value.tickL}`,
        max: `${selectPool.value.tickR}`,
        baseAmount: '',
        quoteAmount: '',
      }
      console.log(form.value, currentPrice.value, currentPriceIndex.value)
    })
  }
  init()
  updatePool()
})
</script>

<template>
  <div v-if="selectPool" class="mx-auto w-6.22">
    <v-back name="Back" @click="back" />

    <div class="text-0.32 font-700 font-dm">
      Increase Liquidity
    </div>

    <div class="mt-0.14 flex items-center justify-between text-0.16 font-700 font-dm">
      <div class="flex items-center gap-x-0.04">
        <v-icon-pair :base="getSymbolItemByPoolId(selectPool.poolId)?.base" :quote="getSymbolItemByPoolId(selectPool.poolId)?.quote" :size="24" />
        <div>
          <span class="text-caption1">{{ getSymbolItemByPoolId(selectPool.poolId)?.base }}/{{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}</span>
          <div class="flex items-center">
            <v-tick-spacing :tick-spacing="currentSymbolItem.tickSpacing" :token="getSymbolItemByPoolId(selectPool.poolId)?.base " />
            <v-in-range :is-in="isInRange(selectPool)" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
      <div class="flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
        </div>
        <div>
          <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.baseAmount, -1) }}</span>
          <span class="text-0.14 text-grey2">{{ getPrice(`${selectPool.baseAmount}`, currencyPair?.base) }}</span>
        </div>
      </div>
      <div class="mt-0.24 flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
        </div>
        <div>
          <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.quoteAmount, -1) }}</span>
          <span class="text-0.14 text-grey2">{{ getPrice(`${selectPool.quoteAmount}`, currencyPair?.quote) }}</span>
        </div>
      </div>
    </div>

    <div class="my-0.14 flex items-center justify-between text-grey2">
      <div>Price Range</div>
      <v-switch v-model="currencyDirection" :options="currentPairSwitch" disabled />
    </div>

    <div class="mt-0.14 text-grey2">
      Current Price
    </div>
    <div v-if="isQuote" class="text-white1">
      1 {{ currencyPair?.base }} = {{ formatNumber(currentPrice, +currentTickerItem?.quoteAssetPrecision - +currentTickerItem?.baseAssetPrecision) }} {{ currencyPair?.quote }}
    </div>
    <div v-else>
      1 {{ currencyPair?.quote }} = {{ formatNumber(1 / (+currentPrice || 1), 8) }} {{ currencyPair?.base }}
    </div>

    <div class="mt-0.12 rd-0.12 bg-black3 p-0.12 font-500">
      <div class="flex justify-between">
        <div class="flex items-center text-0.14 text-white2">
          Min Price
        </div>
        <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.tickL, -1) }} {{ currencyPair?.quote }} per {{ currencyPair?.base }}</span>
      </div>
      <div class="mt-0.24 flex justify-between">
        <div class="flex items-center text-0.14 text-white2">
          Max Price
        </div>
        <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.tickR, -1) }} {{ currencyPair?.quote }} per {{ currencyPair?.base }}</span>
      </div>
    </div>

    <div class="my-0.14 text-grey2">
      <div>Amount</div>
    </div>

    <v-input-amm
      v-model="form.baseAmount" class="mb-0.14"
      :prefix-bottom="getPrice(form.baseAmount, currencyPair?.base)"
      :locked="!!form.maxIndex && form.maxIndex <= currentPriceIndex"
      :error-message="isInsufficientBase ? '' : undefined"
      @change="onChangeAmount('base')"
    >
      <template #suffix>
        <div class="flex flex-col items-end">
          <div class="flex items-center gap-x-0.08">
            <v-icon :currency="currencyPair?.base" class="w-0.16" />
            {{ currencyPair?.base }}
          </div>
          <div class="mt-0.06 flex text-0.12">
            <span class="mr-0.08 text-grey1 font-600">Available</span>
            {{ getAvailableBalance(currencyPair?.base) }} {{ currencyPair?.base }}
            <v-button size="tiny" class="ml-0.08 h-0.2!" bg="black-3" color="primary" @click="onMax('base')">
              Max
            </v-button>
          </div>
        </div>
      </template>
    </v-input-amm>
    <v-input-amm
      v-model="form.quoteAmount" class="mb-0.14"
      :prefix-bottom="getPrice(form.quoteAmount, currencyPair?.quote)"
      :locked="!!form.minIndex && form.minIndex >= currentPriceIndex"
      :error-message="isInsufficientQuote ? '' : undefined"
      @change="onChangeAmount('quote')"
    >
      <template #suffix>
        <div class="flex flex-col items-end">
          <div class="flex items-center gap-x-0.08">
            <v-icon :currency="currencyPair?.quote" class="w-0.16" />
            {{ currencyPair?.quote }}
          </div>
          <div class="mt-0.06 flex text-0.12">
            <span class="mr-0.08 text-grey1 font-600">Available</span>
            {{ getAvailableBalance(currencyPair?.quote) }} {{ currencyPair?.quote }}
            <v-button size="tiny" class="ml-0.08 h-0.2!" bg="black-3" color="primary" @click="onMax('quote')">
              Max
            </v-button>
          </div>
        </div>
      </template>
    </v-input-amm>
    <div v-if="isInsufficientBase || isInsufficientQuote" class="mt--0.08 text-0.14 color-red font-500">
      Insufficient Balance.
    </div>
    <div v-else-if="isTickTooSmall" class="mt--0.08 text-0.14 color-red font-500">
      <div v-if="!!form.maxIndex && form.maxIndex <= currentPriceIndex">
        You must at least add {{ formatNumber(minQuoteAmount, getPrecisionFromSymbol(currentSymbolItem.symbol).quote) }} {{ currencyPair.quote }} to this price range.
      </div>
      <div v-else-if="!!form.minIndex && form.minIndex >= currentPriceIndex">
        You must at least add {{ formatNumber(minBaseAmount, getPrecisionFromSymbol(currentSymbolItem.symbol).base) }} {{ currencyPair.base }} to this price range.
      </div>
      <div v-else>
        You must at least add {{ formatNumber(minBaseAmount, getPrecisionFromSymbol(currentSymbolItem.symbol).base) }} {{ currencyPair.base }} and {{ formatNumber(minQuoteAmount, getPrecisionFromSymbol(currentSymbolItem.symbol).quote) }} {{ currencyPair.quote }} to this price range.
      </div>
    </div>
    <!-- <div
      v-if="isOutOfRange"
      class="flex items-start text-0.12 text-grey1 font-600"
    >
      <SvgWarning class="mr-0.04" />
      The market price is out of your specified price range. Single-asset deposit only.
    </div> -->
    <v-button class="mt-0.32 w-full" type="primary" :disabled="isDisablePreview" @click="onPreview">
      Preview
    </v-button>

    <v-modal
      v-model:show="showPreview"
      modal-class="w-5.5 text-grey1"
      :z-index="201"
      title="Add Liquidity"
    >
      <div class="mt-0.2 font-500">
        <div class="flex justify-between">
          <div class="flex items-center gap-x-0.08 text-white2">
            <v-icon-pair :base="currencyPair.base" :quote="currencyPair.quote" :size="24" />
            {{ currencyPair.base }}/{{ currencyPair.quote }}
            <VTickSpacing :tick-spacing="currentSymbolItem.tickSpacing" :token="currencyPair.quote" />
          </div>
          <div v-if="isOutOfRange" class="flex items-center text-0.14 text-yellow font-700 font-dm">
            <div class="mr-.04 h-0.06 w-0.06 rd-full bg-yellow pt-0.06" />
            Out of Range
          </div>
          <div v-else class="flex items-center text-0.14 text-green font-700 font-dm">
            <div class="mr-.04 h-0.06 w-0.06 rd-full bg-green pt-0.06" />
            In Range
          </div>
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between">
            <div class="flex items-center text-white2">
              <v-icon :currency="currencyPair.base" class="mr-0.04 w-0.24" />
              {{ currencyPair.base }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ formatNumber(form.baseAmount, -1) }}</span>
              <span class="text-0.14 text-grey2">{{ getPrice(form.baseAmount, currencyPair?.base) }}</span>
            </div>
          </div>
          <div class="mt-0.24 flex justify-between">
            <div class="flex items-center text-white2">
              <v-icon :currency="currencyPair.quote" class="mr-0.04 w-0.24" />
              {{ currencyPair.quote }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ formatNumber(form.quoteAmount, -1) }}</span>
              <span class="text-0.14 text-grey2">{{ getPrice(form.quoteAmount, currencyPair?.quote) }}</span>
            </div>
          </div>
        </div>
        <div class="my-0.14 flex items-center justify-between text-grey2">
          <div>Price Range</div>
          <v-switch v-model="currencyDirection" disabled :options="currentPairSwitch" />
        </div>
        <div class="my-0.12 flex items-center">
          <div class="mr-0.04 text-grey2 font-400">
            Current Price
          </div>
          <div v-if="isQuote" class="text-white1">
            1 {{ currencyPair?.base }} = {{ currentPrice }} {{ currencyPair?.quote }}
          </div>
          <div v-else>
            1 {{ currencyPair?.quote }} = {{ 1 / +currentPrice }} {{ currencyPair?.base }}
          </div>
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between">
            <div class="flex items-center text-0.14 text-white2">
              Min Price
            </div>
            <div class="text-white2">
              {{ form.min }} {{ priceSuffix }}
            </div>
          </div>
          <div class="mt-0.24 flex justify-between">
            <div class="flex items-center text-0.14 text-white2">
              Max Price
            </div>
            <div class="text-white2">
              {{ form.max }} {{ priceSuffix }}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" :loading="isAdding" @click="addLiquidity">
            Add Liquidity
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
