<script setup lang="ts" generic="T extends Record<string, any>">
export interface Column {
  title: string
  key: string
  align?: 'left' | 'right'
  fixed?: boolean
  className?: string
  titleClassName?: string
  width?: number
  minWidth?: number
  span?: number
  sortable?: boolean
}

const props = withDefaults(defineProps<{
  data: T[]
  columns: Column[]
  columnGap?: number
  loading?: boolean
  rowKey?: string
  titleRowClass?: string
  contentClass?: string
  rowClass?: string
}>(), {
  rowKey: 'id',
})
const emit = defineEmits(['clickRow'])
// const sortKey = defineModel('sortKey', { type: String, default: '' })
// const sortOrder = defineModel('sortOrder', { type: 'asc' | 'desc' | null, default: '' })
</script>

<template>
  <div class="flex flex-col overflow-auto">
    <div class="table-title mb-0.12 flex justify-between" :class="titleRowClass">
      <div
        v-for="item in columns" :key="item.key"
        class="text-0.12 text-grey1 font-600"
        :class="item.titleClassName"
        :style="{
          minWidth: item.minWidth ? `${(item.minWidth) / 400}rem` : 'auto',
          textAlign: item.align,
          width: item.width ? `${(item.width) / 400}rem` : 'auto',
        }"
      >
        <slot :name="`title-${item.key}`" :data="{ column: item }">
          <!--          <div class="flex items-center"> -->
          <div>{{ item.title }}</div>
          <!--            <svg-sort v-if="item.sortable" :order="sortKey === item.key ? sortOrder : null" /> -->
          <!--          </div> -->
        </slot>
      </div>
    </div>
    <div v-if="data.length || loading" class="relative flex flex-col" :class="contentClass">
      <div v-if="loading" class="absolute inset-0 flex-center bg-[rgba(20,20,22,0.50)]">
        <svg-loading class="animate-spin animate-duration-2000" />
      </div>
      <div
        v-for="(row, index) in data" :key="row[rowKey] || index"
        class="table-content flex items-center justify-between"
        :class="`${rowClass} ${row.rowClass}`"
        @click="emit('clickRow', row)"
      >
        <div
          v-for="item in columns" :key="item.key"
          class="text-0.12 text-white1 font-400 leading-0.2"
          :class="item.titleClassName"
          :style="{
            minWidth: item.minWidth ? `${(item.minWidth) / 400}rem` : 'auto',
            textAlign: item.align,
            width: item.width ? `${(item.width) / 400}rem` : 'auto',
          }"
        >
          <slot :name="item.key" :data="{ row }">
            {{ row[item.key] }}
          </slot>
        </div>
      </div>
    </div>
    <div v-else class="flex-center flex-1 text-0.12" :class="contentClass">
      <slot name="noData">
        No Data
      </slot>
    </div>
  </div>
</template>

<style scoped>

</style>
