import type { PlaceOrderRequest, TIF } from '~/service/modules/order'

interface LimitForm {
  price: string
  amount: string
  total: string
  percent: number
  tif: TIF
}

type LimitFormWithType = Record<'buy' | 'sell', LimitForm>
export const usePutOrder = createGlobalState(() => {
  const current = ref('limit')
  const type = ref<'buy' | 'sell'>('buy')
  const isChangePrice = ref(false)

  function initForm(): LimitForm {
    return {
      price: '',
      amount: '',
      total: '',
      percent: 0,
      tif: 'GTC',
    }
  }

  const form = ref<LimitFormWithType>({
    buy: initForm(),
    sell: initForm(),
  })

  return {
    form,
    current,
    type,
    isChangePrice,
  }
})
