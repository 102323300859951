<script setup lang="tsx">
import { useDisconnect } from '@wagmi/vue'
import type { PopoverPlacement } from 'naive-ui'

const props = withDefaults(defineProps<{
  placement?: PopoverPlacement
}>(), {
  placement: 'bottom-end',
})
const { address, clearInvitation, userStatsInfo, userInfo, isRegistering } = useUser()
const message = useMessage()
const router = useRouter()
const { isSigned, clearKey } = useKey()
const { openModal } = useModal()
const { disconnectAsync } = useDisconnect()

const { copy } = useClipboard({ source: address })

const options = computed(() => [
  // {
  //   label: 'Verify',
  //   key: 'verify',
  //   suffix: () => (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E90">
  //       <path d="M6 12.0011C5.63333 12.0011 5.31933 11.8705 5.058 11.6091C4.79667 11.3478 4.66622 11.034 4.66667 10.6678V2.66781C4.66667 2.30114 4.79733 1.98714 5.05867 1.72581C5.32 1.46447 5.63378 1.33403 6 1.33447H12C12.3667 1.33447 12.6807 1.46514 12.942 1.72647C13.2033 1.98781 13.3338 2.30158 13.3333 2.66781V10.6678C13.3333 11.0345 13.2027 11.3485 12.9413 11.6098C12.68 11.8711 12.3662 12.0016 12 12.0011H6ZM3.33333 14.6678C2.96667 14.6678 2.65267 14.5371 2.39133 14.2758C2.13 14.0145 1.99956 13.7007 2 13.3345V4.00114H3.33333V13.3345H10.6667V14.6678H3.33333Z" fill="inherit" />
  //     </svg>
  //   ),
  //   hide: isSigned.value,
  // },
  {
    label: 'Copy Address',
    key: 'copy',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E90">
        <path d="M6 12.0011C5.63333 12.0011 5.31933 11.8705 5.058 11.6091C4.79667 11.3478 4.66622 11.034 4.66667 10.6678V2.66781C4.66667 2.30114 4.79733 1.98714 5.05867 1.72581C5.32 1.46447 5.63378 1.33403 6 1.33447H12C12.3667 1.33447 12.6807 1.46514 12.942 1.72647C13.2033 1.98781 13.3338 2.30158 13.3333 2.66781V10.6678C13.3333 11.0345 13.2027 11.3485 12.9413 11.6098C12.68 11.8711 12.3662 12.0016 12 12.0011H6ZM3.33333 14.6678C2.96667 14.6678 2.65267 14.5371 2.39133 14.2758C2.13 14.0145 1.99956 13.7007 2 13.3345V4.00114H3.33333V13.3345H10.6667V14.6678H3.33333Z" fill="inherit" />
      </svg>
    ),
  },
  {
    label: 'API Management',
    key: 'api',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E90">
        <path d="M4.66699 10.001C5.22255 10.001 5.69477 9.80653 6.08366 9.41764C6.47255 9.02875 6.66699 8.55653 6.66699 8.00098C6.66699 7.44542 6.47255 6.9732 6.08366 6.58431C5.69477 6.19542 5.22255 6.00098 4.66699 6.00098C4.11144 6.00098 3.63921 6.19542 3.25033 6.58431C2.86144 6.9732 2.66699 7.44542 2.66699 8.00098C2.66699 8.55653 2.86144 9.02875 3.25033 9.41764C3.63921 9.80653 4.11144 10.001 4.66699 10.001ZM4.66699 12.001C3.55588 12.001 2.61144 11.6121 1.83366 10.8343C1.05588 10.0565 0.666992 9.11209 0.666992 8.00098C0.666992 6.88987 1.05588 5.94542 1.83366 5.16764C2.61144 4.38987 3.55588 4.00098 4.66699 4.00098C5.56699 4.00098 6.35321 4.25653 7.02566 4.76764C7.6981 5.27875 8.16744 5.91209 8.43366 6.66764H14.017L15.3337 7.98431L13.0003 10.651L11.3337 9.33431L10.0003 10.6676L8.66699 9.33431H8.43366C8.15588 10.1343 7.67255 10.7788 6.98366 11.2676C6.29477 11.7565 5.52255 12.001 4.66699 12.001Z" fill="inherit" />
      </svg>
    ),
    hide: !isSigned.value,
  },
  {
    label: 'Disconnect',
    key: 'disconnect',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E90">
        <path d="M4.00033 1.33447H10.0003C10.3539 1.33447 10.6931 1.47495 10.9431 1.725C11.1932 1.97505 11.3337 2.31418 11.3337 2.66781V4.00114H10.0003V2.66781H4.00033V13.3345H10.0003V12.0011H11.3337V13.3345C11.3337 13.6881 11.1932 14.0272 10.9431 14.2773C10.6931 14.5273 10.3539 14.6678 10.0003 14.6678H4.00033C3.6467 14.6678 3.30756 14.5273 3.05752 14.2773C2.80747 14.0272 2.66699 13.6881 2.66699 13.3345V2.66781C2.66699 2.31418 2.80747 1.97505 3.05752 1.725C3.30756 1.47495 3.6467 1.33447 4.00033 1.33447Z" fill="inherit" />
        <path d="M10.7267 10.3941L11.6667 11.3341L15 8.00081L11.6667 4.66748L10.7267 5.60748L12.4467 7.33415H6V8.66748H12.4467L10.7267 10.3941Z" fill="inherit" />
      </svg>
    ),
  },
  {
    label: 'Return to Login',
    key: 'login',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E90">
        <path d="M4.00033 1.33447H10.0003C10.3539 1.33447 10.6931 1.47495 10.9431 1.725C11.1932 1.97505 11.3337 2.31418 11.3337 2.66781V4.00114H10.0003V2.66781H4.00033V13.3345H10.0003V12.0011H11.3337V13.3345C11.3337 13.6881 11.1932 14.0272 10.9431 14.2773C10.6931 14.5273 10.3539 14.6678 10.0003 14.6678H4.00033C3.6467 14.6678 3.30756 14.5273 3.05752 14.2773C2.80747 14.0272 2.66699 13.6881 2.66699 13.3345V2.66781C2.66699 2.31418 2.80747 1.97505 3.05752 1.725C3.30756 1.47495 3.6467 1.33447 4.00033 1.33447Z" fill="inherit" />
        <path d="M10.7267 10.3941L11.6667 11.3341L15 8.00081L11.6667 4.66748L10.7267 5.60748L12.4467 7.33415H6V8.66748H12.4467L10.7267 10.3941Z" fill="inherit" />
      </svg>
    ),
  },
])

function registerOnChain() {
  if (!isRegistering.value)
    openModal('registerOnChain')
}

function handleSelect(key: string) {
  switch (key) {
    case 'verify':
      checkAndBindVesselKey()
      break
    case 'copy':
      copy(address.value).then(() => {
        message.success('Address copied')
      })
      break
    case 'api':
      router.push('/key')
      break
    case 'disconnect':
      openModal('disconnect')
      break
    case 'login':
      clearKey(address.value)
      disconnectAsync().then(() => {
        disconnectWallet()
        clearInvitation()
        router.replace('/login')
      })
      break
  }
}
</script>

<template>
  <div>
    <n-popover
      trigger="hover" :show-arrow="false" placement="bottom-end" raw
      size="large"
    >
      <template #trigger>
        <v-button type="outline">
          <template #prefix>
            <div v-if="!isSigned">
              !
            </div>
          </template>
          {{ shortenAddress(address) }}
        </v-button>
      </template>
      <div class="w-1.9 rd-0.04 bg-black2">
        <div v-if="isSigned && !userInfo.registered" class="flex-center py-0.08">
          <v-button :loading="isRegistering" :disabled="isRegistering" @click="registerOnChain">
            Register On-Chain
          </v-button>
        </div>
        <div v-if="!isSigned" class="flex-center py-0.08">
          <v-button @click="checkAndBindVesselKey">
            Sign for Trading
          </v-button>
        </div>
        <div class="flex items-center rd-tl-0.04 rounded-tr-0.04 bg-black3 px-0.16 py-0.08">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M20 10.001C20 15.5239 15.5228 20.001 10 20.001C4.47715 20.001 0 15.5239 0 10.001C0 4.47816 4.47715 0.00100708 10 0.00100708C15.5228 0.00100708 20 4.47816 20 10.001Z" fill="url(#paint0_angular_22764_44642)" />
            <defs>
              <radialGradient id="paint0_angular_22764_44642" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10 10.001) rotate(90) scale(10)">
                <stop stop-color="#0661D6" />
                <stop offset="0.355" stop-color="#109EF0" />
                <stop offset="0.91" stop-color="white" />
              </radialGradient>
            </defs>
          </svg>
          <div class="ml-0.08">
            <div class="text-caption1b text-white2">
              {{ shortenAddress(address) }}
            </div>
            <div class="text-caption3m text-grey3">
              {{ userStatsInfo?.userName || '' }}
            </div>
          </div>
        </div>
        <div
          v-for="item in options.filter(i => !i.hide)" :key="item.key"
          class="h-0.4 flex cursor-pointer items-center justify-between gap-x-0.08 rd-0.04 px-0.16 py-0.08 hover:bg-black3"
          @click="handleSelect(item.key)"
        >
          <div class="w-1.34 text-0.14 font-poppins">
            {{ item.label }}
          </div>
          <Render v-if="item.suffix" :value="item.suffix" />
        </div>
      </div>
    </n-popover>
  </div>
</template>

<style scoped>

</style>
