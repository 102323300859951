<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { isMobile, isPad } = useResponsive()

const { currentSymbol } = useSymbol()
const symbol = useRouteQuery('symbol')

watch(currentSymbol, () => {
  symbol.value = currentSymbol.value
}, {
  immediate: true,
})
</script>

<template>
  <div v-if="isPad" class="bg-black2">
    <trade-advertise />
    <trade-asset-bar />
    <div class="content-pad w-full overflow-hidden">
      <trade-chart class="chart" />
      <!-- Chart -->
      <trade-operation class="operation" />
      <trade-orderbook-orderbook class="orderbook" show-title />
      <trade-orderbook-last-trade class="trade" show-title />
      <trade-history class="order" />
      <trade-asset class="asset" />
    </div>
  </div>

  <div v-else-if="!isMobile" class="bg-black2">
    <trade-advertise />
    <trade-asset-bar />
    <div class="content w-full overflow-hidden">
      <trade-chart />
      <!-- Chart -->
      <trade-orderbook />
      <trade-operation />
      <trade-history class="order" />
      <trade-asset />
    </div>
  </div>

  <div v-else class="pb-0.88">
    <trade-mobile-asset-bar />
    <trade-mobile-detail />
    <trade-mobile-history />
    <trade-mobile-operation />
  </div>
  <common-questionnaire />
</template>

<style scoped lang="scss">
.content {
  display: grid;
  grid-template-columns: 1fr 282px 282px;
  grid-template-rows: 576px 444px;
  gap: calc(2rem / 400);

  #chartWrapper {
    background: var(--vessel-color-black-2);
  }

  > div {
    background: var(--vessel-color-black-1);
    border-radius: calc(2rem / 400);
  }
  .order {
    grid-column: 1 / 3;
  }
}

.content-pad {
  display: grid;
  grid-template-columns: 1fr 1fr 282px;
  grid-template-rows: 566px 566px 370px;
  gap: calc(2rem / 400);

  #chartWrapper {
    background: var(--vessel-color-black-2);
    grid-column: 1 / 3;
  }

  .operation {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  > div {
    background: var(--vessel-color-black-1);
    border-radius: calc(2rem / 400);
  }

  .orderbook {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .asset {
    grid-column: 3/4;
    grid-row: 2/4
  }
  .order {
    grid-column: 1 / 3;
  }
}
</style>
