<script setup lang="ts">
const props = defineProps<{
  currency?: string
}>()
const { allMintList } = useFaucet()

const iconUrl = computed(() => {
  const currencyItem = allMintList.value.find(i => i.name === props.currency)
  return currencyItem?.iconUrl ? `${import.meta.env.VITE_VESSEL_CDN_URL}${currencyItem.iconUrl}` : ''
})
</script>

<template>
  <img v-if="iconUrl" :src="iconUrl" alt="">
  <div v-else class="flex items-center justify-center rd-full bg-primary text-body2b text-white2">
    {{ currency?.slice(0, 1).toUpperCase() }}
  </div>
</template>

<style scoped>

</style>
