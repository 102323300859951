import type { AxiosResponse } from 'axios'

interface HttpOption {

}

export function useHttp<T>(
  api: (...args: any[]) => Promise<AxiosResponse<T, any>>,
  options?: HttpOption,
) {
  const isLoading = ref(false)

  function run(...args: any[]): Promise<AxiosResponse<T, any>> {
    // if (isLoading.value) {
    //   return Promise.reject(new Error('Fetching'))
    // }
    isLoading.value = true
    return api.call(api, ...args).finally(() => {
      isLoading.value = false
    })
  }

  return {
    isLoading,
    run,
  }
}
