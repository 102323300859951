<script setup lang="tsx">
import type { Option } from './progress.vue'

const { referralList, codeAllocation } = useAirdrop()
const message = useMessage()
const { isMobile } = useResponsive()
const { isSigned } = useKey()
const { isConnected } = useUser()

const maxCode = computed(() => codeAllocation.value.at(-1)?.codes || 0)
const levelOption = computed<Option[]>(() => {
  if (!codeAllocation.value.length) {
    return []
  }
  const options = [
    {
      value: 0,
      name: `Initial Code ${codeAllocation.value[0].codes}`,
      tooltipRender: (
        <div class="w-0.9 text-center">
          <span>Points &gt;= </span>
          <span>{codeAllocation.value[0].threshold}</span>
        </div>
      ),
    },
    ...(codeAllocation.value.slice(1).map((i, index) => {
      return {
        value: i.codes,
        name: `+${i.codes - codeAllocation.value[index].codes}`,
        tooltipRender: (
          <div class="w-0.9 text-center">
            <span>Points &gt;= </span>
            {i.threshold}
          </div>),
      }
    })
    ),
    {
      value: maxCode.value * 2,
      name: '',
    },
    {
      value: maxCode.value * 3,
      name: '',
    },
    {
      value: maxCode.value * 4,
      name: '',
    },
  ]
  return options
})

const step = computed(() => {
  const codeNumber = referralList.value.length
  const s = codeAllocation.value.findIndex(i => i.codes === codeNumber)
  return s < 0 ? 0 : s
})

const { copy } = useClipboard({ source: '' })

function copyLink(code: string) {
  const link = `${location.host}/login?code=${code}`
  copy(link).then(() => {
    message.success('Address copied')
  })
}
</script>

<template>
  <div class="px-0.1">
    <AirdropCard class="w-3.36 flex flex-col items-center sm:w-auto">
      <div class="mb-0.24 w-1.75 text-0.18 font-600 sm:w-auto">
        Earn Points and Bonus with Referral! 🚀
      </div>
      <div class="flex flex-col gap-x-0.24 sm:flex-row">
        <div class="card-bg relative h-1.7 w-2.97 rd-0.12 sm:w-3.64">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <g opacity="0.3">
                <path d="M21.502 98.5005C20.5853 97.5838 20.127 96.4171 20.127 95.0005C20.127 93.5838 20.5853 92.4171 21.502 91.5005L28.377 84.6255C25.7103 81.2088 23.6486 77.4171 22.192 73.2505C20.7353 69.0838 20.0053 64.6672 20.002 60.0005C20.002 48.8338 23.877 39.3755 31.627 31.6255C39.3769 23.8755 48.8353 20.0005 60.0019 20.0005H100.002V60.0005C100.002 71.1671 96.1269 80.6255 88.3769 88.3755C80.6269 96.1255 71.1686 100 60.0019 100C55.3353 100 50.9403 99.2721 46.8169 97.8155C42.6936 96.3588 38.922 94.2955 35.502 91.6255L28.502 98.5005C27.5853 99.4172 26.4186 99.8755 25.002 99.8755C23.5853 99.8755 22.4186 99.4172 21.502 98.5005ZM41.752 78.2505C42.6686 79.3338 43.8353 79.8538 45.252 79.8105C46.6686 79.7671 47.8769 79.2471 48.8769 78.2505L68.5019 58.6255C69.5019 57.6255 70.0019 56.4372 70.0019 55.0605C70.0019 53.6838 69.5019 52.4972 68.5019 51.5005C67.5853 50.5838 66.4186 50.1255 65.0019 50.1255C63.5853 50.1255 62.4186 50.5838 61.5019 51.5005L41.752 71.2505C40.8353 72.1671 40.3769 73.3138 40.3769 74.6905C40.3769 76.0672 40.8353 77.2538 41.752 78.2505Z" fill="url(#paint0_linear_17091_37256)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_17091_37256" x1="19.9995" y1="19.9999" x2="99.9995" y2="99.9999" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#37AAEC" />
                  <stop offset="1" stop-color="#37AAEC" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute left-1.65 top-0.21 text-0.32 font-700 leading-0.4 font-dm text-primary">
            01
          </div>
          <div class="absolute left-0.22 top-0.85 w-2.5 text-center text-0.14 font-500 text-grey1 sm:w-3.2">
            Invite through your referral code to earn points!
            Earn 10 points for every successful invitation.
          </div>
        </div>
        <div class="card-bg relative h-1.7 w-2.97 rd-0.12 sm:w-3.64">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 124 124" fill="none">
              <g opacity="0.3">
                <path d="M111.318 60.1702L98.164 47.0211V28.4159C98.164 27.7308 97.8918 27.0737 97.4073 26.5892C96.9229 26.1047 96.2658 25.8326 95.5806 25.8326H76.9755L63.8211 12.6782C63.3367 12.1939 62.6797 11.9219 61.9947 11.9219C61.3097 11.9219 60.6527 12.1939 60.1683 12.6782L47.0191 25.8326H28.414C27.7288 25.8326 27.0717 26.1047 26.5873 26.5892C26.1028 27.0737 25.8306 27.7308 25.8306 28.4159V47.0211L12.6763 60.1754C12.192 60.6599 11.9199 61.3168 11.9199 62.0018C11.9199 62.6868 12.192 63.3438 12.6763 63.8282L25.8306 76.9774V95.5826C25.8306 96.2677 26.1028 96.9248 26.5873 97.4093C27.0717 97.8937 27.7288 98.1659 28.414 98.1659H47.0191L60.1735 111.32C60.6579 111.805 61.3149 112.077 61.9999 112.077C62.6849 112.077 63.3418 111.805 63.8263 111.32L76.9755 98.1659H95.5806C96.2658 98.1659 96.9229 97.8937 97.4073 97.4093C97.8918 96.9248 98.164 96.2677 98.164 95.5826V76.9774L111.318 63.8231C111.803 63.3386 112.075 62.6817 112.075 61.9967C112.075 61.3117 111.803 60.6547 111.318 60.1702ZM61.9973 41.3326C67.4784 41.3326 72.7351 43.5099 76.6108 47.3857C80.4866 51.2615 82.664 56.5181 82.664 61.9992C82.664 67.4804 80.4866 72.737 76.6108 76.6128C72.7351 80.4885 67.4784 82.6659 61.9973 82.6659" fill="url(#paint0_linear_17091_37265)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_17091_37265" x1="11.9169" y1="11.9211" x2="112.072" y2="112.076" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#37AAEC" />
                  <stop offset="1" stop-color="#37AAEC" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute left-1.65 top-0.21 text-0.32 font-700 leading-0.4 font-dm text-primary">
            02
          </div>
          <div class="absolute left-0.22 top-0.85 w-2.5 text-center text-0.14 font-500 text-grey1 sm:w-3.2">
            Get a 10% bonus on points earned by the interaction and invitation from your invitee.
          </div>
        </div>
      </div>
      <div
        class="mt-0.14 cursor-pointer text-0.14 font-700 font-dm text-grey2 underline"
        @click="openUrl('https://vesselfinance.gitbook.io/vessel/vessel-testnet-user-guide')"
      >
        Our documentation
      </div>
    </AirdropCard>
    <div class="mt-0.24 text-0.18 font-600 leading-0.24">
      Your Team
    </div>
    <div class="mb-0.14 text-0.14 font-500 text-grey1">
      Lone sailor? Invite your friends on board and join the Vessel community.
    </div>
    <AirdropCard v-if="isSigned" class="mb-0.4 w-3.36 sm:w-auto px-0.1!">
      <div class="mb-0.54 mt-0.1 w-full px-0.4">
        <AirdropProgress :step="step" :options="levelOption" :total="maxCode * 4" />
      </div>
      <div class="sm:px-0.34">
        <div class="mb-0.14 flex px-0.12 text-0.12 font-600 text-grey1">
          <div class="flex-grow-1 flex-basis-2">
            Invitation Link
          </div>
          <div class="flex-grow-1 flex-basis-1">
            User
          </div>
          <div class="flex-grow-1 flex-basis-1.2 text-right">
            Invitation Number
          </div>
          <div class="flex-grow-1 flex-basis-0.82 text-right">
            Return Points
          </div>
        </div>
        <div
          v-for="(item, index) in referralList" :key="index"
          class="mb-0.12 h-0.48 flex rd-0.08 bg-black3 px-0.12 text-0.12 font-500 leading-0.48 text-white2"
        >
          <div class="flex flex-grow-1 flex-basis-2 items-center gap-x-0.08">
            <v-button color="white2" size="small" @click="copyLink(item.code)">
              <SvgCopy class="mr-0.04" fill="#FCFCFD" />Copy {{ isMobile ? '' : 'Link' }}
            </v-button>
            {{ item.code }}
          </div>
          <div class="flex-grow-1 flex-basis-1">
            {{ shortenAddress(item.userAddress) || '-' }}
          </div>
          <div class="flex-grow-1 flex-basis-1.2 text-right">
            {{ item.invitationCount }}
          </div>
          <div class="flex-grow-1 flex-basis-0.82 text-right">
            {{ item.returnScore }}
          </div>
        </div>
      </div>
    </AirdropCard>
    <AirdropCard v-else class="mb-0.4 w-3.36 flex-center py-0.6 sm:w-auto px-0.1!">
      <common-connect-wallet-button v-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" />
    </AirdropCard>
  </div>
</template>

<style scoped>
.card-bg {
  background: linear-gradient(180deg, #353945 0%, rgba(53, 57, 69, 0.00) 100%);
}
</style>
