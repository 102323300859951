<script setup lang="ts">
import BN from 'bignumber.js'
import type { AssetItemWithClaimable } from '@/store/user'
import type { StartBalanceItem } from '~/service/modules/stats'

const startBalanceList = ref<StartBalanceItem[]>([])

const { assetListWithClaimable } = useUser()
const { currencyList } = useSymbol()
const { run: getStartBalance } = useHttp(vesselApi.stats.getStartBalance)

function getPrice(type: keyof AssetItemWithClaimable) {
  return assetListWithClaimable.value.reduce((res, i) => res.plus(BN(i[type] || 0).multipliedBy(BN(i.price || 0))), BN(0)).toNumber()
}

const value0 = computed(() => startBalanceList.value.reduce((res, i) => res + +i.balance * +i.price, 0))

const pnl = computed(() => {
  return assetListWithClaimable.value.reduce((res, i) => res + (+i.available + +i.inUse - +i.todayTransactionAmount) * +i.price, 0) - value0.value
})

const pnlRatio = computed(() => value0.value ? getRatio(pnl.value / value0.value) : '-')

onMounted(() => {
  getStartBalance().then((res) => {
    startBalanceList.value = res.data.balances
  })
})
</script>

<template>
  <div class="mx-0.32 my-0.12">
    <div class="mb-0.04 text-caption1b">
      Estimated Total Value
    </div>
    <div class="text-headline4">
      $ {{ formatNumber(getPrice('available') + getPrice('inUse') + getPrice('pendingWithdraw') + getPrice('claimable')) }}
    </div>
    <div class="text-caption2m" :class="pnl >= 0 ? 'text-green' : 'text-red'">
      <!-- ${{ formatNumber(pnl, 2) }}({{ pnlRatio }}) <span class="text-caption2b text-white1">Today</span> -->
    </div>
    <div class="grid grid-cols-2 gap-x-0.04 gap-y-0.08">
      <div class="py-0.2">
        <div class="flex items-center gap-x-0.08">
          <div class="h-0.12 w-0.12 rd-0.04 bg-#9757D7" />
          <div class="text-caption1b text-white1">
            Available
          </div>
        </div>
        <div class="mt-0.12 text-body2b">
          ${{ formatNumber(getPrice('available'), 2) }}
        </div>
      </div>
      <div class="py-0.2">
        <div class="flex items-center gap-x-0.08">
          <div class="h-0.12 w-0.12 rd-0.04 bg-primary" />
          <div class="text-caption1b text-white1">
            In Use
          </div>
        </div>
        <div class="mt-0.12 text-body2b">
          ${{ formatNumber(getPrice('inUse'), 2) }}
        </div>
      </div>
      <div class="py-0.2">
        <div class="flex items-center gap-x-0.08">
          <div class="h-0.12 w-0.12 rd-0.04 bg-#19F2FF" />
          <div class="text-caption1b text-white1">
            Pending Withdraw
          </div>
        </div>
        <div class="mt-0.12 text-body2b">
          ${{ formatNumber(getPrice('pendingWithdraw'), 2) }}
        </div>
      </div>
      <div class="py-0.2">
        <div class="flex items-center gap-x-0.08">
          <div class="h-0.12 w-0.12 rd-0.04 bg-#FFD166" />
          <div class="text-caption1b text-white1">
            Claimable
          </div>
        </div>
        <div class="mt-0.12 text-body2b">
          ${{ formatNumber(getPrice('claimable'), 2) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
