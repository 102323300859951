<script setup lang="ts">
const { isConnected } = useUser()
const { isSigned } = useKey()
const currentTab = ref('open order')
const tabOptions = [
  { value: 'open order', label: 'Open Orders' },
  { value: 'order history', label: 'Order History' },
  { value: 'trade history', label: 'Trade History' },
  // { value: 'favorite', label: 'Favorite' },
]
</script>

<template>
  <div class="overflow-hidden p-0.16">
    <div class="mb-0.12 py-0.04">
      <v-tabs v-model="currentTab" :options="tabOptions" />
    </div>
    <div v-if="!isSigned" class="h-2 flex-center flex-1">
      <common-connect-wallet-button v-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" />
    </div>
    <div v-else>
      <n-config-provider :theme-overrides="selectBorderOverrides" abstract>
        <trade-history-open-order v-if="currentTab === 'open order'" />
        <trade-history-order-history v-if="currentTab === 'order history'" />
        <trade-history-trade-history v-if="currentTab === 'trade history'" />
      </n-config-provider>
    </div>
  </div>
</template>

<style scoped>

</style>
