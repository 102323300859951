<script setup lang="ts">
import type { TransferItem } from '@/service/modules/user'
import type { Column } from '~/components/v/Table.vue'
import { capitalizeFirstLetter } from '~/utils'

const { isMobile } = useResponsive()
const { isSigned } = useKey()
const { currencyList } = useSymbol()
const { isBalanceChange } = useWs()
const page = ref(1)
const PAGE_SIZE = 9

type TransferItemWithTime = TransferItem & {
  estTime?: number
}

const transferList = ref<TransferItemWithTime[]>([])

const filter = ref({
  token: null,
  type: null,
  range: null,
})

const typeList = ['Deposit', 'Withdraw', 'Claim']
const tokenList = computed(() => [...currencyList.value.map(i => i.assetName)])

const statusColorDict = {
  Success: '#58BD7D',
  Pending: '#FFD166',
  Failed: '#EF445B',
}

const tableColumns = computed<Column[]>(() => [
  {
    key: 'time',
    title: 'Time',
    minWidth: 80,
    width: 80,
    align: 'left',
  },
  {
    key: 'type',
    title: 'Type',
    minWidth: 80,
    width: 80,
    align: 'left',
  },
  {
    key: 'assetName',
    title: 'Asset',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'amount',
    title: 'Amount',
    minWidth: 100,
    align: 'right',
  },
  {
    key: 'status',
    title: 'Status',
    minWidth: 160,
    align: 'right',
  },
  // {
  //   key: 'transactionFee',
  //   title: 'Transaction Fee',
  //   minWidth: 100,
  //   align: 'right',
  // },
])

const { run: getTransferHistory, isLoading: isLoadingTransfer } = useHttp(vesselApi.user.getTransferHistory)

function getTransferList() {
  if (isSigned.value) {
    getTransferHistory().then((res) => {
      page.value = 1
      transferList.value = res.data.history
      for (const item of transferList.value) {
        if (item.type === 'WITHDRAW' && item.status === 'PENDING') {
          vesselApi.user.getWithdrawEstTime(+item.globalEventId).then((res) => {
            item.estTime = res.data.estimatedTime
          })
        }
      }
    })
  }
}

function onChangePage(current: number) {
  page.value = current
}

function getCurrentPage() {
  getTransferList()
}

function getEstMinute(time: number) {
  if (!time)
    return 0
  return Math.ceil(time / 60 / 5) * 5
}

const transferListFiltered = computed(() => transferList.value
  .filter(i => filter.value.token !== null ? i.assetName === filter.value.token : true)
  .filter(i => filter.value.type !== null ? i.type.toUpperCase() === filter.value.type.toUpperCase() : true)
  .filter(i => !filter.value.range || (i.timestamp >= filter.value.range[0] && i.timestamp < filter.value.range[1] + 86400 * 1000)),

)

const transferListByPage = computed(() => transferListFiltered.value.slice(PAGE_SIZE * (page.value - 1), PAGE_SIZE * page.value))

function reset() {
  filter.value = {
    token: null,
    type: null,
    range: null,
  }
}

watch(isBalanceChange, getTransferList)

onMounted(() => {
  getTransferList()
})
whenever(isSigned, getTransferList)
</script>

<template>
  <div class="flex justify-between">
    <div class="ml-0.04 flex items-center gap-x-0.12 px-0.28">
      <n-date-picker
        v-model:value="filter.range"
        start-placeholder="MM/DD/YYYY"
        end-placeholder="MM/DD/YYYY"
        type="daterange" clearable
        size="small"
        format="MM/dd/yyyy"
      >
        <template #date-icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.332 2.00033H12.6654V0.666992H11.332V2.00033H4.66536V0.666992H3.33203V2.00033H2.66536C1.93203 2.00033 1.33203 2.60033 1.33203 3.33366V14.0003C1.33203 14.7337 1.93203 15.3337 2.66536 15.3337H13.332C14.0654 15.3337 14.6654 14.7337 14.6654 14.0003V3.33366C14.6654 2.60033 14.0654 2.00033 13.332 2.00033ZM13.332 14.0003H2.66536V6.66699H13.332V14.0003ZM13.332 5.33366H2.66536V3.33366H13.332V5.33366Z" fill="#777E91" />
          </svg>
        </template>
      </n-date-picker>
      <n-select
        v-model:value="filter.token" class="w-1.34" clearable
        :options="tokenList.map(i => ({ value: i, label: i }))" placeholder="Asset"
      /><n-select
        v-model:value="filter.type" class="w-1.34" clearable
        :options="typeList.map(i => ({ value: i, label: i }))" placeholder="Type"
      />
      <!--      <div class="cursor-pointer text-0.14 font-500 font-dm text-white2" @click="reset"> -->
      <!--        Reset -->
      <!--      </div> -->
    </div>
  </div>
  <v-table
    v-if="!isMobile"
    class="mt-0.12 h-7 flex-1"
    :data="transferListByPage"
    :columns="tableColumns"
    :loading="isLoadingTransfer"
    content-class="min-h-6"
    :column-gap="35"
    row-class="h-0.68 py-0.14 px-0.32 gap-x-0.16 border-t-1 border-t-black3"
    title-row-class="py-0.04 px-0.32 gap-x-0.16"
    row-key="time"
  >
    <template #time="{ data: { row } }">
      <div>
        <div>{{ dayjs(+row.timestamp).format('MM/DD/YYYY') }}</div>
        <div>{{ dayjs(+row.timestamp).format('HH:mm:ss') }}</div>
      </div>
    </template>
    <template #status="{ data: { row } }">
      <div class="text-right">
        <div :style="{ color: (statusColorDict[capitalizeFirstLetter(row.status) as keyof typeof statusColorDict]) }">
          {{ capitalizeFirstLetter(row.status) }}
        </div>
        <div v-if="row.status === 'PENDING' && row.type === 'WITHDRAW'" class="flex items-center justify-end text-caption2 text-grey1">
          (Est. {{ getEstMinute(row.estTime) }} mins)
          <v-question placement="bottom">
            <div class="w-3.2">
              Pending time is estimated to be {{ getEstMinute(row.estTime) }} minutes.
              This time may vary due to the Zero-Knowledge Proof (ZKP) generation process.
            </div>
          </v-question>
        </div>
      </div>
    </template>
    <template #type="{ data: { row } }">
      {{ `${row.type.slice(0, 1)}${row.type.slice(1).toLowerCase()}` }}
    </template>
    <template #amount="{ data: { row } }">
      {{ formatNumber(row.amount, -1) }}
    </template>
    <template #assetName="{ data: { row } }">
      <div class="flex items-center">
        <v-icon :currency="row.assetName" class="mr-0.04 h-0.24 w-0.24" />
        {{ row.assetName }}
      </div>
    </template>
  </v-table>
  <div v-if="!isMobile" class="flex justify-center">
    <n-pagination
      class="mt-0.12"
      :default-page-size="PAGE_SIZE"
      :item-count="transferListFiltered.length"
      :page="page" @update-page="onChangePage"
    >
      <template #prev>
        <svg-back class="h-0.24 w-0.24" />
      </template>
      <template #next>
        <svg-back class="h-0.24 w-0.24 rotate-180" />
      </template>
    </n-pagination>
  </div>
  <!-- <div v-else>
    <div v-for="(row, index) in transferList" :key="index">
      <div class="mb-0.14 flex justify-between text-grey1">
        <div>Time</div>
        <div class="text-white2">
          {{ dayjs(row.timestamp).format('MM/DD/YYYY HH:mm:ss') }}
        </div>
      </div>
      <div class="mb-0.14 flex justify-between text-grey1">
        <div>Asset Name</div>
        <div class="text-white2">
          {{ row.assetName }}
        </div>
      </div>
      <div class="mb-0.14 flex justify-between text-grey1">
        <div>Amount</div>
        <div class="text-white2">
          {{ row.amount }}
        </div>
      </div>
      <div v-if="current !== 'claim'" class="mb-0.14 flex justify-between text-grey1">
        <div>Status</div>
        <div class="text-white2">
          {{ statusDict[row.status as 0 | 1 | 2] }}
        </div>
      </div>
      <div v-if="current !== 'claim'" class="mb-0.14 flex justify-between text-grey1">
        <div>Transaction Fee</div>
        <div class="text-white2">
          {{ row.transactionFee }}
        </div>
      </div>
      <div v-if="index !== currentList.length - 1" class="mb-0.16 w-full bg-black3 pt-0.01" />
    </div>
  </div> -->
</template>

<style scoped>

</style>
