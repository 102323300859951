<script setup lang="ts">
const historyAdvertise = useLocalStorage('historyAdvertise', '')
const { run: getCopywriting } = useHttp(vesselApi.stats.getCopywriting)
const cw = ref<{
  banner_text: string
  url_name: string
  url: string
}>({
  banner_text: '',
  url_name: '',
  url: '',
})

function closeAd() {
  historyAdvertise.value = cw.value.banner_text
}
onMounted(() => {
  getCopywriting({ key: 'vessel trade page banner' }).then((res) => {
    // console.log(res)
    cw.value = JSON.parse(res.data.content).reduce((res: any, i: any) => ({ ...res, [i.key]: i.value }), {})
  })
})
const isShowAdvertise = computed(() => cw.value?.banner_text && cw.value.banner_text !== historyAdvertise.value)
</script>

<template>
  <div v-if="isShowAdvertise" class="relative bg-#1B3241 py-0.05 text-center text-caption2 text-primary">
    {{ cw.banner_text }}
    <span class="cursor-pointer underline" @click="openUrl(cw.url)">{{ cw.url_name }}</span>
    <div class="absolute right-0 top-1/2 mr-0.08 cursor-pointer -translate-y-1/2" @click="closeAd">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.981411 0.981411C1.15715 0.805675 1.44207 0.805675 1.61781 0.981411L3.99961 3.36321L6.38141 0.981411C6.55715 0.805675 6.84207 0.805675 7.01781 0.981411C7.19354 1.15715 7.19354 1.44207 7.01781 1.61781L4.63601 3.99961L7.01781 6.38141C7.19354 6.55715 7.19354 6.84207 7.01781 7.01781C6.84207 7.19354 6.55715 7.19354 6.38141 7.01781L3.99961 4.63601L1.61781 7.01781C1.44207 7.19354 1.15715 7.19354 0.981411 7.01781C0.805675 6.84207 0.805675 6.55715 0.981411 6.38141L3.36321 3.99961L0.981411 1.61781C0.805675 1.44207 0.805675 1.15715 0.981411 0.981411Z" fill="#37AAEC" />
      </svg>
    </div>
  </div>
</template>

<style scoped>

</style>
