<script setup lang="ts">
const { vesselKey, apiKey, isExportUserKey, getVesselKey } = useKey()
const { openModal } = useModal()
const { isConnected } = useUser()
const { isSigned } = useKey()
const message = useMessage()

const { copy } = useClipboard({ source: '' })

function handleCopy(key: string, keyType: string) {
  copy(key).then(() => {
    message.success(`${keyType} Copied. Please keep it confidential.`)
  })
}

function handleExportKey() {
  getVesselKey()
  isExportUserKey.value = true
}
</script>

<template>
  <div class="flex flex-1 flex-col items-center justify-center">
    <div class="pl-0.16 text-0.48 font-700 tracking--0.0096 font-dm">
      API Management
    </div>
    <div class="my-0.2 pl-0.16 text-0.16 text-grey1">
      Please keep your Vessel Key or API Key confidential to protect your assets.
    </div>
    <div class="cursor-pointer py-0.12 pl-0.16 underline" @click="openUrl('https://apidoc.vessel.finance/')">
      API Document
    </div>
    <common-connect-wallet-button v-if="!isConnected" class="mt-0.4" />
    <common-sign-for-trading-button v-else-if="!isSigned" class="mt-0.4" />
    <div v-else class="mt-0.4 w-full flex flex-col items-center px-0.16">
      <div class="w-full rd-0.24 bg-black2 px-0.12 py-0.2 sm:w-8.64 sm:px-0.8 sm:py-0.32">
        <div class="text-0.24">
          Vessel Public Key
        </div>
        <div class="mt-0.16 flex items-center gap-0.04 text-grey2">
          <div>
            {{ vesselKey?.publicKey?.slice(0, 32) }}...
          </div>
          <svg-copy class="cursor-pointer" @click="handleCopy(vesselKey?.publicKey, 'Vessel Key')" />
        </div>
        <v-button class="mt-0.24 w-2.38" @click="handleExportKey">
          Export Private Key
        </v-button>
      </div>
      <div class="my-0.14 w-full rd-0.24 bg-black2 px-0.12 py-0.2 sm:w-8.64 sm:px-0.8 sm:py-0.32">
        <div class="text-0.24">
          API Key
        </div>
        <div class="mt-0.16 flex flex-wrap items-center gap-0.04 text-grey2">
          <div>
            {{ apiKey?.key }}
          </div>
          <svg-copy class="cursor-pointer" @click="handleCopy(apiKey?.key, 'Api Key')" />
        </div>
        <div class="mt-0.24 text-0.24">
          Passphrase
        </div>
        <div class="mt-0.16 flex flex-wrap items-center gap-0.04 text-grey2">
          <div>
            {{ apiKey?.passphrase }}
          </div>
          <svg-copy class="cursor-pointer" @click="handleCopy(apiKey?.passphrase, 'Api Passphrase')" />
        </div>
        <div class="mt-0.24 text-0.24">
          Secret Key
        </div>
        <div class="mt-0.16 flex flex-wrap items-center gap-0.04 text-grey2">
          <div>
            {{ apiKey?.secret?.slice(0, 32) }}...
          </div>
          <svg-copy class="cursor-pointer" @click="handleCopy(apiKey?.secret, 'Api Passphrase')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
