<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.8156 14C1.69338 14 1.58227 13.9693 1.48227 13.908C1.38227 13.8467 1.30449 13.7662 1.24894 13.6667C1.19338 13.5667 1.16271 13.4582 1.15694 13.3413C1.15116 13.2244 1.18183 13.1107 1.24894 13L7.4156 2.33333C7.48227 2.22222 7.56849 2.13889 7.67427 2.08333C7.78005 2.02778 7.88827 2 7.99894 2C8.11005 2 8.21849 2.02778 8.32427 2.08333C8.43005 2.13889 8.51605 2.22222 8.58227 2.33333L14.7489 13C14.8156 13.1111 14.8463 13.2251 14.8409 13.342C14.8356 13.4589 14.8049 13.5671 14.7489 13.6667C14.6934 13.7667 14.6156 13.8473 14.5156 13.9087C14.4156 13.97 14.3045 14.0004 14.1823 14H1.8156ZM7.99894 12C8.18783 12 8.34627 11.936 8.47427 11.808C8.60227 11.68 8.66605 11.5218 8.6656 11.3333C8.6656 11.1444 8.6016 10.986 8.4736 10.858C8.3456 10.73 8.18738 10.6662 7.99894 10.6667C7.81005 10.6667 7.6516 10.7307 7.5236 10.8587C7.3956 10.9867 7.33183 11.1449 7.33227 11.3333C7.33227 11.5222 7.39627 11.6807 7.52427 11.8087C7.65227 11.9367 7.81049 12.0004 7.99894 12ZM7.99894 10C8.18783 10 8.34627 9.936 8.47427 9.808C8.60227 9.68 8.66605 9.52178 8.6656 9.33333V7.33333C8.6656 7.14444 8.6016 6.986 8.4736 6.858C8.3456 6.73 8.18738 6.66622 7.99894 6.66667C7.81005 6.66667 7.6516 6.73067 7.5236 6.85867C7.3956 6.98667 7.33183 7.14489 7.33227 7.33333V9.33333C7.33227 9.52222 7.39627 9.68067 7.52427 9.80867C7.65227 9.93667 7.81049 10.0004 7.99894 10Z" fill="#FFD87C" />
  </svg>
</template>

<style scoped>

</style>
