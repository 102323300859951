import type { OrderItem, TradeItem } from '~/service/modules/order'

export const useOrder = createGlobalState(() => {
  const currentOpenOrderList = ref<OrderItem[]>([])
  const orderHistoryList = ref<OrderItem[]>([])
  const tradeHistoryList = ref<TradeItem[]>([])
  const currentTab = ref('open order')
  const { currentSymbol } = useSymbol()
  const { isSigned } = useKey()

  const { run: getApiOpenOrder, isLoading: isLoadingOpenOrder } = useHttp(vesselApi.order.getOpenOrder)
  const { run: getApiOrderHistory, isLoading: isLoadingOrderHistory } = useHttp(vesselApi.order.getOrderHistory)
  const { run: getApiTradeHistory, isLoading: isLoadingTradeHistory } = useHttp(vesselApi.order.getTradeHistory)
  function getCurrentTabOrder() {
    if (!isSigned.value) {
      return
    }
    const operator: Record<string, () => void> = {
      'open order': getOpenOrder,
      'order history': getOrderHistory,
      'trade history': getTradeHistory,
    }
    operator[currentTab.value]()
  }

  function getOrderHistory() {
    getApiOrderHistory().then((res) => {
      orderHistoryList.value = res.data.orders.filter(i => !['NEW', 'PARTIALLY_FILLED'].includes(i.status))
    })
  }

  function getTradeHistory() {
    getApiTradeHistory().then((res) => {
      tradeHistoryList.value = res.data.trades
    })
  }

  function getOpenOrder() {
    getApiOpenOrder().then((res) => {
      currentOpenOrderList.value = res.data.orders
    })
  }

  watch(currentSymbol, () => {
    tradeHistoryList.value = []
    orderHistoryList.value = []
    getCurrentTabOrder()
  })

  return {
    currentTab,
    getCurrentTabOrder,
    getOpenOrder,
    getOrderHistory,
    getTradeHistory,
    currentOpenOrderList,
    orderHistoryList,
    tradeHistoryList,
    isLoading: computed(() => isLoadingOpenOrder.value || isLoadingOrderHistory.value || isLoadingTradeHistory.value),
  }
})
