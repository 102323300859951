<script setup lang="ts">
import headerImg from '~/assets/image/voyage/header.png'
import headerIcon1 from '~/assets/image/voyage/headerIcon1.png'
import headerIcon2 from '~/assets/image/voyage/headerIcon2.png'
import headerIcon3 from '~/assets/image/voyage/headerIcon3.png'
import headerIcon4 from '~/assets/image/voyage/headerIcon4.png'

const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()
const { voyageSummary, currentTab, contentRef } = useVoyage()
const { poolDetailList, updatePool, selectPool, selectPoolRow, userPoolList, getUserPosition, isLoadingUserPosition } = useAmm()
const { symbolList, currencyList } = useSymbol()

function getSymbolItemByPoolId(poolId: string) {
  const symbol = poolDetailList.value.find(i => i.poolId === poolId)?.symbol
  return symbolList.value.find(i => i.symbol === symbol)
}

function getPrice(poolId: string, baseAmount: string, quoteAmount: string) {
  const symbolItem = getSymbolItemByPoolId(poolId)
  const basePrice = +currencyList.value?.find(i => i.assetName === symbolItem?.base)?.price
  const quotePrice = +currencyList.value?.find(i => i.assetName === symbolItem?.quote)?.price
  return +baseAmount * basePrice + +quoteAmount * quotePrice
}

const totalValue = computed(() => {
  return userPoolList.value.reduce((res, row) => res + getPrice(row.poolId, row.baseAmount, row.quoteAmount), 0)
})

function changeTab(tab: string) {
  currentTab.value = tab
  contentRef.value.scrollTo({ top: 500, behavior: 'smooth' })
}

function addLiquidity() {
  if (!isConnected.value) {
    openModal('chooseProvider')
  }
  else if (!isSigned.value) {
    checkAndBindVesselKey()
  }
  else {
    router.push({
      path: '/pools/add',
    })
  }
}

onMounted(() => {
  getUserPosition()
  updatePool()
})
whenever(isSigned, getUserPosition)
onUnmounted(() => {
  // selectPool.value = null
})
</script>

<template>
  <div>
    <div class="title mt-1 text-center text-0.96 font-700 font-dm">
      Voyage
    </div>
    <div class="text-center">
      Embark On The Vessel Maiden Voyage
    </div>
    <div class="header grid grid-cols-4 mx-auto mt-1.1 h-2.18 w-12.8" :style="{ backgroundImage: `url(${headerImg})` }">
      <div class="w-full flex flex-col items-center justify-center">
        <img :src="headerIcon1" class="mb-0.12 w-0.28" alt="">
        <div v-if="isSigned" style="backgroundImage: linear-gradient(270deg, #FFF 50%, #37AAEC 80%);" class="clip text-headline3">
          {{ formatNumberWithUnit(totalValue) }}
        </div>
        <div v-else class="text-headline3">
          -
        </div>
        <div class="flex items-center text-caption2m text-grey1">
          LP Value
          <v-question>
            Approximate $USD value of user’s LP position
          </v-question>
        </div>
        <v-button size="small" class="mt-0.12" @click="addLiquidity">
          <template #prefix>
            <SvgPlus class="mr-0.08" />
          </template>
          Add Liquidity
        </v-button>
      </div>
      <div class="w-full flex flex-col items-center justify-center">
        <img :src="headerIcon2" class="mb-0.12 w-0.28" alt="">
        <div style="backgroundImage: linear-gradient(270deg, #E7F7FF 100%, #E7F7FF 100%);" class="clip text-headline3">
          <span v-if="isSigned && voyageSummary">{{ voyageSummary.rankBoost }}x</span>
          <span v-else>-</span>
        </div>
        <div class="flex items-center text-caption2m text-grey1">
          Navigator Boost
          <v-question>
            Earning higher ranks on the navigator leaderboard will enable user the mileage multiplier.
          </v-question>
        </div>
        <div class="mt-0.24 flex cursor-pointer items-center text-button2 text-primary" @click="changeTab('leaderboard')">
          Navigator Leaderboard >
        </div>
      </div>
      <div class="w-full flex flex-col items-center justify-center">
        <img :src="headerIcon3" class="mb-0.12 w-0.28" alt="">
        <div style="backgroundImage: linear-gradient(270deg, #E7F7FF 100%, #E7F7FF 100%);" class="clip text-headline3">
          <span v-if="isSigned && voyageSummary">{{ formatNumber(voyageSummary.lpPointTotal, 0) }}</span>
          <span v-else>-</span>
        </div>
        <div class="flex items-center text-caption2m text-grey1">
          Vessel Mileage
          <v-question>
            Earn Vessel Mileage by providing liquidity close to the trading pair’s market price. And get an extra 16% from the voyagers you invited.
          </v-question>
        </div>
        <div class="mt-0.24 flex cursor-pointer items-center text-button2 text-primary" @click="openUrl('https://vesselfinance.gitbook.io/vessel/vessel-voyage/vessel-mileage-for-liquidity-providing')">
          How to Earn >
        </div>
      </div>
      <div class="w-full flex flex-col items-center justify-center">
        <img :src="headerIcon4" class="mb-0.12 w-0.28" alt="">
        <div style="backgroundImage: linear-gradient(270deg, #37AAEC 20%, #FFF 50%);" class="clip text-headline3">
          <span v-if="isSigned && voyageSummary">{{ formatNumber(voyageSummary.referralPointTotal, 0) }}</span>
          <span v-else>-</span>
        </div>
        <div class="flex items-center text-caption2m text-grey1">
          Referral Coins
          <v-question>
            Earn referral coins for each new user who registers through your referral link and completes their deposit.
          </v-question>
        </div>
        <div class="mt-0.24 flex cursor-pointer items-center text-button2 text-primary" @click="changeTab('referral')">
          Refer Voyagers >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  background: linear-gradient(90deg, #7CCEFD 0.1%, #E7F7FF 39.7%, #E7F7FF 59.49%, #7CCEFD 99.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  background-size: 100% 100%;
}

.clip {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
