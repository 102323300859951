export function useResponsive() {
  const { width } = useWindowSize()
  const fontSize = ref(400)
  const fontRatio = computed(() => fontSize.value / 400)

  watch(width, () => {
    const w = width.value
    fontSize.value = w > 500 ? 400 : (w / 375) * 400
    window.document.documentElement.style.fontSize = `${fontSize.value}px`
  }, {
    immediate: true,
  })

  const isMobile = computed(() => width.value < 500)
  const isPad = computed(() => width.value <= 1024 && width.value >= 500)
  return {
    isMobile,
    fontRatio,
    isPad,
  }
}
