<script setup lang="ts">
import VTickSpacing from '~/components/v/TickSpacing.vue'
import type { PoolOverviewItem } from '~/service/modules/amm'
import type { Column } from '~/components/v/Table.vue'

const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()
const { voyageSummary } = useVoyage()

const { poolOverviewList, isLoadingOverviewList, getPoolOverviewList } = useAmm()

const tableColumns: Column[] = [
  {
    key: 'pool',
    title: 'Pool',
    minWidth: 240,
  },
  {
    key: 'tvl',
    title: 'TVL',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'volume',
    title: '24h Volume',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'fee',
    title: '24h Fees',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'apr',
    title: 'Est.APR',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'point',
    title: 'Mileage Earned',
    minWidth: 100,
    align: 'right',
  },
  {
    key: 'action',
    title: 'Action',
    minWidth: 100,
    align: 'right',
  },
]

function addSymbol(symbol: string) {
  if (!isConnected.value) {
    openModal('chooseProvider')
  }
  else if (!isSigned.value) {
    checkAndBindVesselKey()
  }
  else {
    router.push({
      path: '/pools/add',
      query: {
        symbol,
      },
    })
  }
}

function getPoints(row: PoolOverviewItem) {
  const item = voyageSummary.value?.poolsPoint.find(i => +i.poolId === +row.poolId)
  return item?.point || 0
}

const sortedPoolOverviewList = computed(() => poolOverviewList.value.sort((i, j) => {
  if ((+j.boost?.boost || 0) - (+i.boost?.boost || 0) === 0) {
    return +j.quoteTvlValue + +j.baseTvlValue - +i.quoteTvlValue - +i.baseTvlValue
  }
  return (+j.boost?.boost || 0) - (+i.boost?.boost || 0)
}))

onMounted(() => {
  getPoolOverviewList()
})
</script>

<template>
  <div class="bg mb-0.4 rd-0.12 px-0.16 py-0.32">
    <v-table
      class="mt-0.12 min-h-3 overflow-auto"
      :data="sortedPoolOverviewList"
      :columns="tableColumns"
      :column-gap="35"
      :loading="isLoadingOverviewList"
      row-key="time"
      content-class="gap-y-0.08  min-h-2.8"
      row-class="gap-x-0.16 py-0.04 h-0.72 text-caption1"
      title-row-class="gap-x-0.16 text-caption1"
    >
      <template #title-point>
        <div class="flex items-center justify-end">
          <v-question>Updated every 5 five minutes</v-question>
          <div class="ml-0.02">
            Mileage Earned
          </div>
        </div>
      </template>
      <template #pool="{ data: { row } }">
        <div class="flex flex-grow-1 flex-basis-1.3 items-center">
          <v-icon-pair class="mr-0.08" :base="row.baseTokenName" :quote="row.quoteTokenName" :size="24" />
          <div>
            <div class="text-caption1">
              {{ row.baseTokenName }}/{{ row.quoteTokenName }}
            </div>
            <div class="flex items-center gap-x-0.04">
              <VTickSpacing :tick-spacing="row.tickSpacing" :token="row.quoteTokenName" />
              <div v-if="row.boost" class="h-0.2 flex items-center gap-x-0.02 rd-full bg-#37aaec/20 px-0.06 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M4.2994 1.60002C4.21512 1.53689 4.11306 1.50197 4.00777 1.50025C3.90248 1.49853 3.79933 1.5301 3.71304 1.59046C3.62675 1.65081 3.56171 1.73687 3.52721 1.83636C3.49271 1.93586 3.4905 2.0437 3.5209 2.14452C3.6083 2.43612 3.61812 2.74547 3.5494 3.04202C3.48091 3.33682 3.33606 3.60841 3.1294 3.82952C3.11563 3.8444 3.10277 3.86009 3.0909 3.87652C2.7929 4.28502 1.1109 6.67652 2.6204 9.25752L2.6354 9.28202C3.00208 9.83348 3.5043 10.2815 4.0939 10.583C4.67639 10.8813 5.32603 11.0241 5.9799 10.9975C6.67517 11.0097 7.36175 10.8417 7.9729 10.51C8.59468 10.1719 9.11833 9.67855 9.4929 9.07802C10.1434 7.97802 10.0849 6.80002 9.7869 5.85752C9.4954 4.93352 8.9469 4.15052 8.4834 3.80652C8.41058 3.7526 8.32447 3.71948 8.23428 3.7107C8.14409 3.70193 8.05322 3.71783 7.97136 3.7567C7.88951 3.79557 7.81976 3.85596 7.76957 3.9314C7.71937 4.00684 7.69063 4.09451 7.6864 4.18502C7.6529 4.90052 7.5049 5.46052 7.2894 5.90052C7.1784 4.69702 6.7259 3.80252 6.1774 3.13852C5.6039 2.44352 4.8954 1.98852 4.5159 1.74452C4.44254 1.69878 4.37051 1.65092 4.2999 1.60102L4.2994 1.60002Z" fill="url(#paint0_linear_32159_35455)" />
                  <defs>
                    <linearGradient id="paint0_linear_32159_35455" x1="6.00059" y1="1.50018" x2="6.00059" y2="15.1783" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#37AAEC" />
                      <stop offset="1" stop-color="#19F2FF" />
                    </linearGradient>
                  </defs>
                </svg>
                <div class="text-hairline2">
                  {{ row.boost.boost }}x
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #tvl="{ data: { row } }">
        <div class="text-caption1">
          ${{ formatNumberWithUnit(+row.quoteTvlValue + +row.baseTvlValue) }}
        </div>
      </template>
      <template #volume="{ data: { row } }">
        <div class="text-caption1">
          ${{ formatNumberWithUnit(row.tradeVolume24h) }}
        </div>
      </template>
      <template #fee="{ data: { row } }">
        <div class="text-caption1">
          ${{ formatNumberWithUnit(row.feeValue24h) }}
        </div>
      </template>
      <template #apr="{ data: { row } }">
        <div class="text-caption1">
          {{ getRatio(row.apr) }}
        </div>
      </template>
      <template #point="{ data: { row } }">
        <div class="text-caption1">
          {{ formatNumber(getPoints(row), 0) }}
        </div>
      </template>
      <template #action="{ data: { row } }">
        <div
          class="flex cursor-pointer items-center justify-end text-right text-button2 text-primary"
          @click.stop="addSymbol(`${row.baseTokenName}${row.quoteTokenName}`)"
        >
          + Add
        </div>
      </template>
    </v-table>
  </div>
</template>

<style scoped>
.bg {
  border: 0.4px solid var(--vessel-color-black-3, #353945);
  background: rgba(20, 20, 22, 0.50);
  backdrop-filter: blur(10px);
}
</style>
