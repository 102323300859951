import {
  getAccount,
  readContract,
  writeContract,
} from '@wagmi/core'
import {
  createPublicClient,
  http,
  parseUnits,
} from 'viem'

import VAULT_ABI from '~/abi/vault.json'
import ERC20_ABI from '~/abi/erc20.json'
import { getWagmiConfig } from '~/lib/wagmi'

export async function getPublicClient() {
  const config = getWagmiConfig()
  return createPublicClient({
    chain: config.chains[0],
    transport: http(),
  })
}

export async function writeVault(functionName: string, args: any[], value?: bigint) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()

  const publicClient = await getPublicClient()
  const gas = await publicClient.estimateContractGas({
    abi: VAULT_ABI,
    functionName,
    address: currentVaultAddress.value as `0x${string}`,
    account: account.address,
    args,
    value,
    chain: account.chain,
  })

  return writeContract(config, {
    abi: VAULT_ABI,
    functionName,
    address: currentVaultAddress.value as `0x${string}`,
    account: account.address,
    args,
    value,
    chain: account.chain,
    gas,
  })
}

export async function approveAmount(address: `0x${string}`, decimal: number, amount: string) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  const publicClient = await getPublicClient()
  const gas = await publicClient.estimateContractGas({
    abi: ERC20_ABI,
    functionName: 'approve',
    address,
    account: account.address,
    args: [
      currentVaultAddress.value,
      parseUnits(amount, decimal),
    ],
    chain: account.chain,
  })
  return writeContract(config, {
    abi: ERC20_ABI,
    functionName: 'approve',
    address,
    account: account.address,
    args: [
      currentVaultAddress.value,
      parseUnits(amount, decimal),
    ],
    chain: account.chain,
    gas,
  })
}

export async function faucetMint(address: `0x${string}`, amount: bigint) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const publicClient = await getPublicClient()
  const gas = await publicClient.estimateContractGas({
    abi: ERC20_ABI,
    functionName: 'mint',
    address,
    account: account.address,
    args: [
      amount,
    ],
    chain: account.chain,
  })

  return writeContract(config, {
    abi: ERC20_ABI,
    functionName: 'mint',
    address,
    account: account.address,
    args: [
      amount,
    ],
    chain: account.chain,
    gas,
  })
}

export async function getBalanceFromChain(address: `0x${string}`) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  return readContract(config, {
    abi: ERC20_ABI,
    address,
    functionName: 'balanceOf',
    account: account.address,
    args: [
      account.address,
    ],
    chainId: account.chainId,
  })
}

export async function getAllowanceFromChain(address: `0x${string}`) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  return readContract(config, {
    abi: ERC20_ABI,
    address,
    functionName: 'allowance',
    account: account.address,
    args: [
      account.address,
      currentVaultAddress.value,
    ],
    chainId: account.chainId,
  })
}

export async function getClaimableFromChain(assetId: number) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  return readContract(config, {
    abi: VAULT_ABI,
    account: account.address,
    address: currentVaultAddress.value as `0x${string}`,
    functionName: 'getPendingWithdraw',
    args: [
      account.address,
      assetId,
    ],
  }) as Promise<bigint>
}
