<script setup lang="ts">
import type { Column } from '~/components/v/Table.vue'
import type { TradeItem } from '~/service/modules/order'

const props = defineProps<{
  historyCurrentSymbol?: string
}>()
const { symbolList } = useSymbol()
const { isNeedRefresh } = useWs()
const { isSigned } = useKey()
const isOnlyCurrent = useStorage('isOnlyCurrentOpen', false)

const { run: getApiTradeHistory, isLoading } = useHttp(vesselApi.order.getTradeHistory)

const filter = ref({
  type: null,
  side: null,
  range: [dayjs().subtract(6, 'days').startOf('day').valueOf(), dayjs().valueOf()],
  symbol: null,
})

const noMore = ref(false)
const sideList = ['Buy', 'Sell']
const symbolFilterList = computed(() => (symbolList.value.map(i => addSymbolSlash(i.symbol))))
const tradeHistoryList = ref<TradeItem[]>([])

const tableColumns: Column[] = [
  {
    key: 'time',
    title: 'Filled Time',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'symbol',
    title: 'Symbol',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'side',
    title: 'Side',
    minWidth: 40,
  },
  {
    key: 'price',
    title: 'Filled Price',
    minWidth: 100,
    align: 'right',
  },
  {
    key: 'amount',
    title: 'Filled Amount',
    minWidth: 100,
    align: 'right',
  },
  {
    key: 'quoteQty',
    title: 'Total',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'fee',
    title: 'Fee',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'maker',
    title: 'Maker/Taker',
    minWidth: 80,
    align: 'right',
  },
]

function getTradeHistory(isReset = false) {
  if (isReset) {
    tradeHistoryList.value = []
  }
  getApiTradeHistory({
    fromId: tradeHistoryList.value.length ? tradeHistoryList.value.at(-1)?.id : undefined,
    limit: ORDER_PAGE_SIZE,
    startTime: filter.value.range[0],
    endTime: dayjs(filter.value.range[1]).endOf('day').valueOf(),
    side: filter.value.side?.toUpperCase(),
    type: filter.value.type?.toUpperCase(),
    symbol: filter.value.symbol?.replace('/', ''),
  }).then((res) => {
    if (isReset) {
      tradeHistoryList.value = []
    }
    noMore.value = res.data.trades.length < ORDER_PAGE_SIZE
    tradeHistoryList.value.push(...res.data.trades)
  })
}

function reset() {
  filter.value = {
    type: null,
    side: null,
    range: null,
    symbol: null,
  }
}

function handleLoad() {
  if (isLoading.value || noMore.value)
    return
  getTradeHistory()
}

watch(isNeedRefresh, () => {
  getTradeHistory(true)
})

watch([isOnlyCurrent, props], () => {
  if (props.historyCurrentSymbol) {
    filter.value.symbol = isOnlyCurrent.value ? props.historyCurrentSymbol : null
  }
}, {
  immediate: true,
})

watch(filter, () => {
  getTradeHistory(true)
}, {
  deep: true,
})

whenever(isSigned, () => {
  getTradeHistory(true)
}, {
  immediate: true,
})
</script>

<template>
  <div class="relative w-full flex-1 overflow-hidden">
    <div class="flex justify-between" :class="{ 'px-0.28': !historyCurrentSymbol }">
      <div class="ml-0.04 flex items-center gap-x-0.12">
        <n-date-picker
          v-model:value="filter.range"
          start-placeholder="MM/DD/YYYY"
          end-placeholder="MM/DD/YYYY"
          type="daterange"
          size="small"
          format="MM/dd/yyyy"
        >
          <template #date-icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M13.332 2.00033H12.6654V0.666992H11.332V2.00033H4.66536V0.666992H3.33203V2.00033H2.66536C1.93203 2.00033 1.33203 2.60033 1.33203 3.33366V14.0003C1.33203 14.7337 1.93203 15.3337 2.66536 15.3337H13.332C14.0654 15.3337 14.6654 14.7337 14.6654 14.0003V3.33366C14.6654 2.60033 14.0654 2.00033 13.332 2.00033ZM13.332 14.0003H2.66536V6.66699H13.332V14.0003ZM13.332 5.33366H2.66536V3.33366H13.332V5.33366Z" fill="#777E91" />
            </svg>
          </template>
        </n-date-picker>
        <n-select
          v-if="!historyCurrentSymbol" v-model:value="filter.symbol" class="w-1.34" clearable
          :options="symbolFilterList.map(i => ({ value: i, label: i }))" placeholder="Symbol"
        />
        <n-select
          v-model:value="filter.side" class="w-1.34" clearable
          :options="sideList.map(i => ({ value: i, label: i }))" placeholder="Side"
        />
        <!--        <div class="cursor-pointer text-0.14 font-500 font-dm text-white2" @click="reset"> -->
        <!--          Reset -->
        <!--        </div> -->
      </div>
      <n-checkbox v-if="historyCurrentSymbol" v-model:checked="isOnlyCurrent">
        Current Pair Only
      </n-checkbox>
    </div>
    <n-infinite-scroll style="height: calc(100% - 55px)" :distance="10" class="mt-0.12" @load="handleLoad">
      <v-table
        class="min-h-3 overflow-auto"
        :data="tradeHistoryList"
        :columns="tableColumns"
        :column-gap="35"
        :loading="isLoading"
        row-key="id"
        content-class="gap-y-0.08 min-h-2.8"
        :row-class="`gap-x-0.16  ${historyCurrentSymbol ? 'py-0.04 h-0.4' : 'py-0.14 h-0.68 border-t-1 border-t-black2 px-0.32'}`"
        :title-row-class="`gap-x-0.16 ${historyCurrentSymbol ? '' : 'py-0.04 px-0.32'}`"
      >
        <template #symbol="{ data: { row } }">
          {{ addSymbolSlash(row.symbol) }}
        </template>
        <template #time="{ data: { row } }">
          <div>
            <div>{{ dayjs(+row.time).format('MM/DD/YYYY') }}</div>
            <div>{{ dayjs(+row.time).format('HH:mm:ss') }}</div>
          </div>
        </template>
        <template #side="{ data: { row } }">
          <div :class="{ 'text-green': row.side === 'BUY', 'text-red': row.side === 'SELL' }">
            {{ row.side }}
          </div>
        </template>
        <template #price="{ data: { row } }">
          <div>
            {{ formatNumber(+row.price, getPrecisionFromSymbol(row.symbol).price) }} {{ getCurrencyFromSymbol(row.symbol)?.quote }}
          </div>
        </template>
        <template #amount="{ data: { row } }">
          <div>
            {{ formatNumber(+row.qty, getPrecisionFromSymbol(row.symbol).amount) }} {{ getCurrencyFromSymbol(row.symbol)?.base }}
          </div>
        </template>
        <template #quoteQty="{ data: { row } }">
          <div>
            {{ formatNumber(+row.quoteQty, getPrecisionFromSymbol(row.symbol).total) }} {{ getCurrencyFromSymbol(row.symbol)?.quote }}
          </div>
        </template>
        <template #fee="{ data: { row } }">
          <div>
            {{ formatNumber(+row.fee, 8) }} {{ row.feeCurrency }}
          </div>
        </template>
        <template #maker="{ data: { row } }">
          <div>
            {{ row.isMaker ? 'Maker' : 'Taker' }}
          </div>
        </template>
      </v-table>
    </n-infinite-scroll>
  </div>
</template>

<style scoped>

</style>
