<script setup lang="ts">
const { getUserAsset } = useUser()
const { isSigned } = useKey()

onMounted(() => {
  if (isSigned.value) {
    getUserAsset()
  }
})
</script>

<template>
  <n-scrollbar class="h-full">
    <div class="h-full flex flex-col overflow-auto">
      <div class="mb-0.02 h-3.28 flex flex-none gap-0.02">
        <AssetModule class="flex-1">
          <AssetSpotTotal />
        </AssetModule>
        <AssetModule class="flex-1">
          <AssetSpotHistory />
        </AssetModule>
      </div>
      <AssetSpotAsset class="flex-1" />
    </div>
  </n-scrollbar>
</template>

<style scoped>

</style>
