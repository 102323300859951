<script setup lang="ts">
const { currentTab, getCurrentTabOrder } = useOrder()
const { isConnected } = useUser()
const { isSigned } = useKey()
const tabOptions = [
  { value: 'open order', label: 'Open orders' },
  { value: 'order history', label: 'Order History' },
  { value: 'trade history', label: 'Trade History' },
  // { value: 'favorite', label: 'Favorite' },
]

function onChangeTab() {
  getCurrentTabOrder()
}
</script>

<template>
  <div class="overflow-hidden p-0.16">
    <div class="mb-0.12 py-0.04">
      <v-tabs v-model="currentTab" :options="tabOptions" @change="onChangeTab" />
    </div>
    <div v-if="!isSigned" class="h-2 flex-center flex-1">
      <common-connect-wallet-button v-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" />
    </div>
    <div v-else>
      <trade-mobile-history-open-order v-if="currentTab === 'open order'" />
      <trade-mobile-history-order-history v-if="currentTab === 'order history'" />
      <trade-mobile-history-trade-history v-if="currentTab === 'trade history'" />
    </div>
  </div>
</template>

<style scoped>

</style>
