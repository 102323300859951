import type { Chain } from 'viem'
import type { ChainConfig } from '~/service/modules/public'

let chainConfig: ChainConfig[] = []

export async function getChainConfig() {
  try {
    const result = await vesselApi.public.getChainConfig()
    chainConfig = result.data.data.map(res => ({
      id: Number(res.id) || 534352,
      name: res.name || 'Scroll',
      nativeCurrencyDecimals: Number(res.nativeCurrencyDecimals) || 18,
      nativeCurrencyName: res.nativeCurrencyName || 'Ether',
      nativeCurrencySymbol: res.nativeCurrencySymbol || 'ETH',
      publicRpcUrl: res.publicRpcUrl || 'https://rpc.scroll.io',
      vaultAddress: res.vaultAddress || '0x6126E927627b8d9eb9aDb9faadC47B76F94B6bA2',
      explorerName: res.explorerName || 'ScrollScan',
      explorerUrl: res.explorerUrl || 'https://scrollscan.com/',
    }))
    return chainConfig
  }
  catch {
    chainConfig = [{
      id: 534352,
      name: 'Scroll',
      nativeCurrencyDecimals: 18,
      nativeCurrencyName: 'Ether',
      nativeCurrencySymbol: 'ETH',
      publicRpcUrl: 'https://rpc.scroll.io',
      vaultAddress: '0x6126E927627b8d9eb9aDb9faadC47B76F94B6bA2',
      explorerName: 'ScrollScan',
      explorerUrl: 'https://scrollscan.com/',
    }]
    return chainConfig
  }
}

export function getChainConfigSync() {
  return chainConfig
}

export function getConfigChains() {
  const chainConfig = getChainConfigSync()
  return chainConfig.map(c => ({
    id: Number(c.id),
    name: c.name,
    nativeCurrency: {
      name: c.nativeCurrencyName,
      symbol: c.nativeCurrencySymbol,
      decimals: Number(c.nativeCurrencyDecimals),
    },
    rpcUrls: {
      default: {
        http: [c.publicRpcUrl],
      },
    },
    blockExplorers: {
      default: {
        name: c.explorerName,
        url: c.explorerUrl,
      },
    },
  })) as unknown as readonly [Chain, ...Chain[]]
}
