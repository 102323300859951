<template>
  <svg width="99" height="22" viewBox="0 0 99 22" fill="#37AAEC" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.5575 21.2777C40.2451 21.2777 38.3781 20.8039 36.9566 19.8562C35.554 18.8895 34.8527 17.4869 34.8527 15.6484C34.8527 15.4968 34.8717 15.2314 34.9096 14.8523H40.7947C40.7379 15.4968 40.8706 15.999 41.1928 16.3592C41.515 16.7193 42.0078 16.8994 42.6712 16.8994C43.2208 16.8994 43.6568 16.7762 43.979 16.5298C44.3202 16.2834 44.4908 15.9327 44.4908 15.4778C44.4908 14.9471 44.2444 14.5112 43.7516 14.17C43.2777 13.8288 42.5006 13.4308 41.4202 12.9759C40.3399 12.521 39.449 12.0945 38.7477 11.6965C38.0464 11.2795 37.4399 10.7204 36.9282 10.0191C36.4164 9.29883 36.1605 8.40799 36.1605 7.34657C36.1605 5.96293 36.5017 4.77831 37.184 3.79271C37.8664 2.78815 38.8141 2.02999 40.0271 1.51823C41.2591 0.987523 42.6617 0.722168 44.2349 0.722168C45.6564 0.722168 46.9169 0.949615 48.0162 1.40451C49.1155 1.84045 49.9684 2.47541 50.575 3.30938C51.2004 4.14335 51.5132 5.12896 51.5132 6.2662C51.5132 6.56946 51.5037 6.79691 51.4847 6.94854H45.4858C45.5048 6.85377 45.5143 6.72109 45.5143 6.5505C45.5143 6.09561 45.3721 5.74496 45.0878 5.49856C44.8035 5.2332 44.4149 5.10053 43.9221 5.10053C43.4104 5.10053 42.9934 5.2332 42.6712 5.49856C42.3679 5.74496 42.2163 6.09561 42.2163 6.5505C42.2163 7.04331 42.4532 7.46977 42.9271 7.8299C43.4199 8.17107 44.178 8.57858 45.2015 9.05242C46.3009 9.56418 47.2012 10.0475 47.9025 10.5024C48.6227 10.9383 49.2387 11.5259 49.7505 12.2651C50.2622 13.0043 50.5181 13.9141 50.5181 14.9945C50.5181 16.1886 50.1959 17.269 49.5515 18.2356C48.907 19.1833 47.9878 19.932 46.7937 20.4817C45.5996 21.0124 44.1875 21.2777 42.5575 21.2777Z" fill="inherit" />
    <path d="M26.4698 8.76811L27.0384 5.4417H34.2883L35.0559 1.03491H22.2336L18.6798 21.0787H31.5305L32.2981 16.6435H25.0483L25.6775 13.1417H28.2671C30.9215 13.1417 33.0733 11.1794 33.0733 8.75864H29.4686C28.2404 8.75864 27.1197 9.17881 26.2701 9.87015L26.4698 8.76811Z" fill="inherit" />
    <path d="M60.5211 21.2777C58.2087 21.2777 56.3418 20.8039 54.9202 19.8562C53.5176 18.8895 52.8163 17.4869 52.8163 15.6484C52.8163 15.4968 52.8353 15.2314 52.8732 14.8523H58.7584C58.7015 15.4968 58.8342 15.999 59.1564 16.3592C59.4786 16.7193 59.9714 16.8994 60.6348 16.8994C61.1845 16.8994 61.6204 16.7762 61.9426 16.5298C62.2838 16.2834 62.4544 15.9327 62.4544 15.4778C62.4544 14.9471 62.208 14.5112 61.7152 14.17C61.2413 13.8288 60.4642 13.4308 59.3839 12.9759C58.3035 12.521 57.4127 12.0945 56.7114 11.6965C56.0101 11.2795 55.4035 10.7204 54.8918 10.0191C54.38 9.29883 54.1242 8.40799 54.1242 7.34657C54.1242 5.96293 54.4653 4.77831 55.1477 3.79271C55.83 2.78815 56.7777 2.02999 57.9907 1.51823C59.2228 0.987523 60.6253 0.722168 62.1985 0.722168C63.6201 0.722168 64.8805 0.949615 65.9798 1.40451C67.0791 1.84045 67.9321 2.47541 68.5386 3.30938C69.1641 4.14335 69.4768 5.12896 69.4768 6.2662C69.4768 6.56946 69.4673 6.79691 69.4484 6.94854H63.4495C63.4684 6.85377 63.4779 6.72109 63.4779 6.5505C63.4779 6.09561 63.3358 5.74496 63.0514 5.49856C62.7671 5.2332 62.3786 5.10053 61.8858 5.10053C61.374 5.10053 60.957 5.2332 60.6348 5.49856C60.3316 5.74496 60.1799 6.09561 60.1799 6.5505C60.1799 7.04331 60.4169 7.46977 60.8907 7.8299C61.3835 8.17107 62.1417 8.57858 63.1652 9.05242C64.2645 9.56418 65.1648 10.0475 65.8661 10.5024C66.5863 10.9383 67.2023 11.5259 67.7141 12.2651C68.2259 13.0043 68.4817 13.9141 68.4817 14.9945C68.4817 16.1886 68.1595 17.269 67.5151 18.2356C66.8707 19.1833 65.9514 19.932 64.7573 20.4817C63.5632 21.0124 62.1511 21.2777 60.5211 21.2777Z" fill="inherit" />
    <path d="M78.5416 5.4417L77.973 8.76811L77.7684 9.89693C78.6219 9.18968 79.7555 8.75864 80.9995 8.75864H84.6042C84.6042 11.1794 82.4524 13.1417 79.798 13.1417H77.1806L76.5514 16.6435H83.8013L83.0337 21.0787H70.1829L73.7368 1.03491H86.5591L85.7915 5.4417H78.5416Z" fill="inherit" />
    <path d="M92.1273 16.8425H98.8107L98.0431 21.0787H85.7588L89.3127 1.03491H94.8851L92.1273 16.8425Z" fill="inherit" />
    <path d="M5.94205 1.03491L7.93221 15.4778L15.0115 1.03491H20.9535L10.491 21.0787H3.38327L0 1.03491H5.94205Z" fill="inherit" />
  </svg>
</template>
