<script setup lang="ts">
const emit = defineEmits(['change'])
const current = defineModel<number>()
const isDown = ref(false)
const progressRef = ref<HTMLDivElement>()
const { elementX, elementWidth } = useMouseInElement(progressRef)

useEventListener(document, 'mouseup', (evt) => {
  isDown.value = false
})
useEventListener(document, 'mousemove', (evt) => {
  changeValue()
})
useEventListener(document, 'touchmove', (evt) => {
  changeValue()
})

function onMouseDown() {
  isDown.value = true
  changeValue()
}
function changeValue() {
  if (isDown.value) {
    current.value = Math.max(0, Math.min(Math.floor(elementX.value / elementWidth.value * 100), 100))
    emit('change', current.value)
  }
}
function onMouseUp() {
  changeValue()
}

const safeCurrent = computed(() => Math.max(0, Math.min(current.value || 0, 100)))
</script>

<template>
  <div
    ref="progressRef"
    class="relative mx-0.04 h-0.24 cursor-pointer select-none"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @touchstart="onMouseDown"
    @touchend="onMouseUp"
  >
    <div class="progress-bar mt-0.11 h-0.02 w-full pt-0.02" :style="{ '--current': `${safeCurrent}%` }" />
    <div
      v-for="i in 5" :key="i"
      class="absolute top-0.09 h-0.06 rd-full pl-0.02"
      :style="{
        left: `${i * 25 - 25}%`,
        background: `${i * 25 - 25 > (current || 0) ? 'var(--vessel-color-grey-1)' : 'var(--vessel-color-white-2)'}`,
      }"
    />
    <div
      class="absolute flex-center cursor-pointer border-grey1 rd-full bg-white1 -translate-x-1/2 hover:border-4"
      :class="{ 'border-4 top-0.01 h-0.2 w-0.2': isDown, 'top-0.04 h-0.16 w-0.16': !isDown }"
      :style="{ left: `${safeCurrent}%` }"
    />
    <div
      v-if="isDown"
      class="absolute top--0.4 w-0.42 flex-center cursor-pointer rd-0.08 bg-grey1 px-0.12 py-0.06 text-0.12 font-500 text-white2 -translate-x-1/2"
      :style="{ left: `${safeCurrent}%` }"
    >
      {{ current }}%
      <div class="error-clip" />
    </div>
  </div>
</template>

<style scoped>
.progress-bar {
  background-image: linear-gradient(90deg, rgba(252, 252, 253, 1) 0 var(--current), rgba(53, 57, 69, 1) 0 100%, rgba(53, 57, 69, 1));
}
.error-clip {
  position: absolute;
  left: 50%;
  bottom: calc(-11rem / 400);
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 6px solid var(--vessel-color-grey-1);
}
</style>
