<script setup lang="tsx">
import { vOnClickOutside } from '@vueuse/components'
import type { SymbolItem } from '~/service/modules/public'
import type { Column } from '~/components/v/Table.vue'

const { currentSymbol, currentSymbolItem, symbolList, changeCurrentSymbol } = useSymbol()
const { symbolPrice } = useWs()

const currentSymbolBalanceItem = computed(() => symbolPrice.value?.[currentSymbol.value])

const showSymbolList = ref(false)
const toggleSymbol = useToggle(showSymbolList)
const search = ref('')

function clearSearch() {
  search.value = ''
}

const percent24 = computed(() => {
  const lastPrice = +currentSymbolBalanceItem.value?.closePrice || 0
  const openPrice = +currentSymbolBalanceItem.value?.openPrice || 0
  if (openPrice !== 0) {
    return +(((lastPrice - openPrice) / openPrice) * 100).toFixed(2)
  }
  return 0
})

const dataList = computed(() => [
  // {
  //   icon: (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  //       <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 13.3334C10.9462 13.3334 13.334 10.9456 13.334 8.00004C13.334 5.05452 10.9462 2.66671 8.00065 2.66671C5.05513 2.66671 2.66732 5.05452 2.66732 8.00004C2.66732 10.9456 5.05513 13.3334 8.00065 13.3334ZM8.00065 14.6667C11.6825 14.6667 14.6673 11.6819 14.6673 8.00004C14.6673 4.31814 11.6825 1.33337 8.00065 1.33337C4.31875 1.33337 1.33398 4.31814 1.33398 8.00004C1.33398 11.6819 4.31875 14.6667 8.00065 14.6667Z" fill="#777E91" />
  //       <path d="M7.33398 4.66667C7.33398 4.29848 7.63246 4 8.00065 4C8.36884 4 8.66732 4.29848 8.66732 4.66667C9.77189 4.66667 10.6673 5.5621 10.6673 6.66667C10.6673 7.03486 10.3688 7.33333 10.0007 7.33333C9.63246 7.33333 9.33398 7.03486 9.33398 6.66667C9.33398 6.29848 9.03551 6 8.66732 6H7.16293C6.88921 6 6.66732 6.22189 6.66732 6.49561C6.66732 6.70894 6.80382 6.89833 7.0062 6.96579L9.41674 7.7693C10.1636 8.01825 10.6673 8.71716 10.6673 9.50439C10.6673 10.5145 9.84847 11.3333 8.83837 11.3333H8.66732C8.66732 11.7015 8.36884 12 8.00065 12C7.63246 12 7.33398 11.7015 7.33398 11.3333C6.22941 11.3333 5.33398 10.4379 5.33398 9.33333C5.33398 8.96514 5.63246 8.66667 6.00065 8.66667C6.36884 8.66667 6.66732 8.96514 6.66732 9.33333C6.66732 9.70152 6.96579 10 7.33398 10H8.83837C9.11209 10 9.33398 9.77811 9.33398 9.50439C9.33398 9.29106 9.19748 9.10167 8.9951 9.03421L6.58457 8.2307C5.83773 7.98176 5.33398 7.28284 5.33398 6.49561C5.33398 5.48551 6.15283 4.66667 7.16293 4.66667H7.33398Z" fill="#777E91" />
  //     </svg>),
  //   title: 'USD',
  //   value: '?',
  //   valueClass: 'text-green',
  // },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6572 6.60606C11.9069 6.33551 11.89 5.91374 11.6195 5.664L9.2862 3.51015C9.03082 3.27442 8.6372 3.27442 8.38182 3.51015L6.04846 5.664C5.77792 5.91373 5.76104 6.3355 6.01078 6.60605C6.26051 6.8766 6.68228 6.89347 6.95283 6.64374L8.16734 5.52267L8.16734 12C8.16734 12.3682 8.46582 12.6667 8.83401 12.6667C9.2022 12.6667 9.50067 12.3682 9.50067 12L9.50067 5.52269L10.7151 6.64373C10.9857 6.89347 11.4074 6.8766 11.6572 6.60606Z" fill="#777E91" />
      </svg>),
    title: '24h High',
    value: `${formatNumber(currentSymbolBalanceItem.value?.highPrice, 2)}`,
    valueClass: '',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9892 9.39394C11.2389 9.66449 11.2221 10.0863 10.9515 10.336L8.61823 12.4898C8.36286 12.7256 7.96923 12.7256 7.71385 12.4899L5.38049 10.336C5.10995 10.0863 5.09307 9.6645 5.34281 9.39395C5.59254 9.1234 6.01431 9.10653 6.28486 9.35626L7.49937 10.4773L7.49937 3.99998C7.49937 3.63179 7.79785 3.33331 8.16604 3.33331C8.53423 3.33331 8.8327 3.63179 8.8327 3.99998L8.8327 10.4773L10.0472 9.35627C10.3177 9.10653 10.7395 9.1234 10.9892 9.39394Z" fill="#777E91" />
      </svg>),
    title: '24h Low',
    value: `${formatNumber(currentSymbolBalanceItem.value?.lowPrice, 2)}`,
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83268 2C2.20087 2 2.49935 2.29848 2.49935 2.66667V12C2.49935 12.3682 2.79783 12.6667 3.16602 12.6667H15.166C15.5342 12.6667 15.8327 12.9651 15.8327 13.3333C15.8327 13.7015 15.5342 14 15.166 14H3.16602C2.06145 14 1.16602 13.1046 1.16602 12V2.66667C1.16602 2.29848 1.46449 2 1.83268 2Z" fill="#777E91" />
        <path d="M12.5007 3.33331C12.1325 3.33331 11.834 3.63179 11.834 3.99998V10.6666C11.834 11.0348 12.1325 11.3333 12.5007 11.3333C12.8688 11.3333 13.1673 11.0348 13.1673 10.6666V3.99998C13.1673 3.63179 12.8688 3.33331 12.5007 3.33331Z" fill="#777E91" />
        <path d="M7.16732 4.66665C6.79913 4.66665 6.50065 4.96512 6.50065 5.33331V10.6666C6.50065 11.0348 6.79913 11.3333 7.16732 11.3333C7.53551 11.3333 7.83398 11.0348 7.83398 10.6666V5.33331C7.83398 4.96512 7.53551 4.66665 7.16732 4.66665Z" fill="#777E91" />
        <path d="M4.50065 8.66665C4.13246 8.66665 3.83398 8.96512 3.83398 9.33331V10.6666C3.83398 11.0348 4.13246 11.3333 4.50065 11.3333C4.86884 11.3333 5.16732 11.0348 5.16732 10.6666V9.33331C5.16732 8.96512 4.86884 8.66665 4.50065 8.66665Z" fill="#777E91" />
        <path d="M9.16732 6.66665C9.16732 6.29846 9.46579 5.99998 9.83398 5.99998C10.2022 5.99998 10.5007 6.29846 10.5007 6.66665V10.6666C10.5007 11.0348 10.2022 11.3333 9.83398 11.3333C9.46579 11.3333 9.16732 11.0348 9.16732 10.6666V6.66665Z" fill="#777E91" />
      </svg>
    ),
    title: `24h Volume(${currentSymbolItem.value?.base})`,
    value: `${formatNumber(currentSymbolBalanceItem.value?.volume, 2)}`,
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83268 2C2.20087 2 2.49935 2.29848 2.49935 2.66667V12C2.49935 12.3682 2.79783 12.6667 3.16602 12.6667H15.166C15.5342 12.6667 15.8327 12.9651 15.8327 13.3333C15.8327 13.7015 15.5342 14 15.166 14H3.16602C2.06145 14 1.16602 13.1046 1.16602 12V2.66667C1.16602 2.29848 1.46449 2 1.83268 2Z" fill="#777E91" />
        <path d="M12.5007 3.33331C12.1325 3.33331 11.834 3.63179 11.834 3.99998V10.6666C11.834 11.0348 12.1325 11.3333 12.5007 11.3333C12.8688 11.3333 13.1673 11.0348 13.1673 10.6666V3.99998C13.1673 3.63179 12.8688 3.33331 12.5007 3.33331Z" fill="#777E91" />
        <path d="M7.16732 4.66665C6.79913 4.66665 6.50065 4.96512 6.50065 5.33331V10.6666C6.50065 11.0348 6.79913 11.3333 7.16732 11.3333C7.53551 11.3333 7.83398 11.0348 7.83398 10.6666V5.33331C7.83398 4.96512 7.53551 4.66665 7.16732 4.66665Z" fill="#777E91" />
        <path d="M4.50065 8.66665C4.13246 8.66665 3.83398 8.96512 3.83398 9.33331V10.6666C3.83398 11.0348 4.13246 11.3333 4.50065 11.3333C4.86884 11.3333 5.16732 11.0348 5.16732 10.6666V9.33331C5.16732 8.96512 4.86884 8.66665 4.50065 8.66665Z" fill="#777E91" />
        <path d="M9.16732 6.66665C9.16732 6.29846 9.46579 5.99998 9.83398 5.99998C10.2022 5.99998 10.5007 6.29846 10.5007 6.66665V10.6666C10.5007 11.0348 10.2022 11.3333 9.83398 11.3333C9.46579 11.3333 9.16732 11.0348 9.16732 10.6666V6.66665Z" fill="#777E91" />
      </svg>
    ),
    title: `24h Turnover(${currentSymbolItem.value?.quote})`,
    value: `${formatNumber(currentSymbolBalanceItem.value?.quoteVolume, 2)}`,
  },
])

const tableColumns: Column[] = [
  {
    key: 'name',
    title: 'Name',
    minWidth: 80,
  },
  {
    key: 'price',
    title: 'Price',
    minWidth: 80,
    align: 'right',
  },
  {
    key: 'percent',
    title: '24h %',
    minWidth: 60,
    align: 'right',
  },
]

function onClickSymbol(row: SymbolItem) {
  changeCurrentSymbol(row.symbol)
  showSymbolList.value = false
}

const symbolListFiltered = computed(() => symbolList.value.filter(i => i.symbol.toLowerCase().includes(search.value.toLowerCase())))
</script>

<template>
  <div
    class="relative bg-black1 px-0.16 pt-0.1"
  >
    <div class="flex flex-col overflow-y-hidden">
      <!-- asset name -->
      <div class="h-0.5 flex flex-none cursor-pointer items-center" @click="toggleSymbol()">
        <!-- <img :src="btcImg" class="mr-0.04 h-0.24 w-0.24"> -->
        <div class="text-0.24 font-600">
          {{ currentSymbolItem?.base || '-' }}/{{ currentSymbolItem?.quote || '-' }}
        </div>
        <div class="mx-0.12 h-0.32 w-0.32 flex-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6381 1.57919C13.1027 1.77261 14.2266 2.89655 14.42 4.36108C14.553 5.36791 14.666 6.62737 14.666 7.99986C14.666 9.37234 14.553 10.6318 14.42 11.6386C14.2266 13.1032 13.1027 14.2271 11.6381 14.4205C10.6313 14.5535 9.37183 14.6665 7.99935 14.6665C6.62686 14.6665 5.3674 14.5535 4.36057 14.4205C2.89604 14.2271 1.7721 13.1032 1.57868 11.6386C1.44571 10.6318 1.33268 9.37234 1.33268 7.99986C1.33268 6.62737 1.44571 5.36791 1.57868 4.36108C1.7721 2.89655 2.89604 1.77261 4.36057 1.57919C5.3674 1.44621 6.62687 1.33319 7.99935 1.33319C9.37183 1.33319 10.6313 1.44621 11.6381 1.57919ZM9.86128 6.52845C10.1216 6.2681 10.5437 6.2681 10.8041 6.52845C11.0644 6.7888 11.0644 7.21091 10.8041 7.47126L8.47075 9.8046C8.2104 10.0649 7.78829 10.0649 7.52794 9.8046L5.19461 7.47126C4.93426 7.21091 4.93426 6.7888 5.19461 6.52845C5.45496 6.2681 5.87707 6.2681 6.13742 6.52845L7.99935 8.39038L9.86128 6.52845Z" fill="#777E91" />
          </svg>
        </div>
      </div>

      <!-- price -->
      <div class="min-w-1.5">
        <div class="text-0.24 font-600" :class="percent24 > 0 ? 'text-green' : 'text-red'">
          {{ formatNumber(currentSymbolBalanceItem?.closePrice, 2) }}
        </div>
        <div class="text-0.12 font-600" :class="percent24 > 0 ? 'text-green' : 'text-red'">
          {{ percent24 }}%
        </div>
      </div>

      <!-- favorite -->
      <!-- <div class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52275 8.93361C9.42172 9.17558 9.19391 9.3408 8.93252 9.36167L4.18325 9.74094C3.56183 9.79057 3.31039 10.5666 3.78461 10.9712L7.39904 14.0554C7.59925 14.2262 7.68672 14.4949 7.62542 14.7509L6.52045 19.3652C6.37552 19.9704 7.03337 20.4497 7.56505 20.1262L11.6365 17.649C11.86 17.5131 12.1406 17.5131 12.3642 17.649L16.4356 20.1262C16.9672 20.4497 17.6251 19.9704 17.4802 19.3652L16.3752 14.7509C16.3139 14.4949 16.4014 14.2262 16.6016 14.0554L20.216 10.9712C20.6902 10.5666 20.4388 9.79057 19.8174 9.74094L15.0681 9.36167C14.8067 9.3408 14.5789 9.17558 14.4779 8.93361L12.6463 4.54699C12.4067 3.97327 11.5939 3.97327 11.3544 4.54699L9.52275 8.93361ZM16.0179 7.43115L14.4918 3.77638C13.5679 1.56346 10.4328 1.56348 9.50877 3.77638L7.98275 7.43115L4.02404 7.74729C1.62712 7.9387 0.65727 10.9318 2.48641 12.4926L5.49492 15.0597L4.57544 18.8994C4.0164 21.234 6.55383 23.0826 8.60461 21.8348L12.0003 19.7688L15.396 21.8348C17.4468 23.0826 19.9842 21.2339 19.4252 18.8994L18.5057 15.0597L21.5142 12.4926C23.3434 10.9318 22.3735 7.9387 19.9766 7.74729L16.0179 7.43115Z" fill="#777E91" />
        </svg>
      </div> -->

      <div class="grid grid-cols-2 mt-0.24 gap-0.08">
        <div v-for="(item) in dataList" :key="item.title" class="data-item">
          <div>
            <div class="flex items-center">
              <Render :value="item.icon" />
              <div class="description">
                <div class="title text-0.12 font-400">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div class="value text-0.14 font-500" :class="item.valueClass">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-modal
      v-model:show="showSymbolList"
      modal-class="text-grey1 w-full"
      :z-index="201"
      :title="`${currentSymbolItem?.base || '-'}/${currentSymbolItem?.quote || '-'}`"
    >
      <div
        class="max-h-2.8 w-full flex flex-col overflow-hidden"
      >
        <!-- <v-input v-model="search" align="left" class="flex-none mb-0.08 ">
          <template #suffix>
            <div class="h-0.18 w-0.18 flex-center cursor-pointer border-1 border-black3 rd-full" @click="clearSearch">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L6 5.29289L8.64645 2.64645C8.84171 2.45118 9.15829 2.45118 9.35355 2.64645C9.54882 2.84171 9.54882 3.15829 9.35355 3.35355L6.70711 6L9.35355 8.64645C9.54882 8.84171 9.54882 9.15829 9.35355 9.35355C9.15829 9.54882 8.84171 9.54882 8.64645 9.35355L6 6.70711L3.35355 9.35355C3.15829 9.54882 2.84171 9.54882 2.64645 9.35355C2.45118 9.15829 2.45118 8.84171 2.64645 8.64645L5.29289 6L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z" fill="#FCFCFD" />
              </svg>
            </div>
          </template>
        </v-input> -->
        <v-table
          :data="symbolListFiltered"
          :columns="tableColumns"
          :column-gap="35"
          class="w-full flex-1"
          title-row-class="py-0.08 h-0.4 leading-0.4 px-0.08"
          content-class="mt-0.08"
          row-class="h-0.4 leading-0.4 border-t-1 border-black3 gap-y-0 hover:bg-black2 px-0.08 cursor-pointer"
          @click-row="onClickSymbol"
        >
          <template #percent="{ data: { row } }">
            <div v-if="+row.openPrice" :class="+symbolPrice[row.symbol]?.closePrice < +symbolPrice[row.symbol]?.openPrice ? 'text-red' : 'text-green'">
              {{ formatNumber((+symbolPrice[row.symbol]?.closePrice - (+symbolPrice[row.symbol]?.openPrice)) / +symbolPrice[row.symbol]?.openPrice * 100) }} %
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #price="{ data: { row } }">
            <div v-if="!+symbolPrice[row.symbol]?.closePrice">
              -
            </div>
            <div v-else class="text-0.14 font-500 text-white2" :class="+symbolPrice[row.symbol]?.closePrice < +symbolPrice[row.symbol]?.openPrice ? 'text-red' : 'text-green'">
              {{ formatNumber(symbolPrice[row.symbol]?.closePrice, 2) }}
            </div>
          </template>
          <template #name="{ data: { row } }">
            <div class="text-0.12 font-500 text-white2">
              <span>{{ row.base }}</span> <span class="text-grey1">/{{ row.quote }}</span>
            </div>
          </template>
        </v-table>
      </div>
    </v-modal>
  </div>
</template>

<style scoped lang="scss">
.data-item {
  display: flex;
  .description {
    display: flex;
    align-items: center;
    color: var(--vessel-color-grey-1);
    .title {
      margin-left: calc(4rem / 400);
      font-size: calc(12rem / 400);
      line-height: calc(20rem / 400);
    }
  }
  .value {
    margin-top: calc(4rem / 400);
    min-width: calc(118rem / 400);
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.2s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
