<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.9987 1.33333C7.63051 1.33333 7.33203 1.63181 7.33203 1.99999V3.99999C7.33203 4.36818 7.63051 4.66666 7.9987 4.66666C8.36689 4.66666 8.66536 4.36818 8.66536 3.99999V1.99999C8.66536 1.63181 8.36689 1.33333 7.9987 1.33333Z" fill="#FCFCFD" />
    <path d="M7.9987 11.3333C7.63051 11.3333 7.33203 11.6318 7.33203 12V14C7.33203 14.3682 7.63051 14.6667 7.9987 14.6667C8.36689 14.6667 8.66536 14.3682 8.66536 14V12C8.66536 11.6318 8.36689 11.3333 7.9987 11.3333Z" fill="#FCFCFD" />
    <path d="M13.9987 7.33333C14.3669 7.33333 14.6654 7.6318 14.6654 8C14.6654 8.36819 14.3669 8.66666 13.9987 8.66666H11.9987C11.6305 8.66666 11.332 8.36818 11.332 8C11.332 7.6318 11.6305 7.33333 11.9987 7.33333H13.9987Z" fill="#FCFCFD" />
    <path d="M4.66536 8C4.66536 7.6318 4.36689 7.33333 3.9987 7.33333H1.9987C1.63051 7.33333 1.33203 7.6318 1.33203 8C1.33203 8.36818 1.63051 8.66666 1.9987 8.66666H3.9987C4.36689 8.66666 4.66536 8.36819 4.66536 8Z" fill="#FCFCFD" />
    <path d="M11.77 3.28602C12.0303 3.02567 12.4524 3.02567 12.7128 3.28602C12.9731 3.54636 12.9731 3.96847 12.7128 4.22882L11.2986 5.64304C11.0382 5.90339 10.6161 5.90339 10.3558 5.64304C10.0954 5.38269 10.0954 4.96058 10.3558 4.70023L11.77 3.28602Z" fill="#FCFCFD" />
    <path d="M5.64165 10.357C5.3813 10.0966 4.95919 10.0966 4.69884 10.357L3.28463 11.7712C3.02428 12.0315 3.02428 12.4537 3.28463 12.714C3.54498 12.9744 3.96709 12.9744 4.22744 12.714L5.64165 11.2998C5.902 11.0394 5.902 10.6173 5.64165 10.357Z" fill="#FCFCFD" />
    <path d="M12.7128 11.7713C12.9731 12.0316 12.9731 12.4537 12.7128 12.7141C12.4524 12.9744 12.0303 12.9744 11.7699 12.7141L10.3557 11.2999C10.0954 11.0395 10.0954 10.6174 10.3557 10.3571C10.6161 10.0967 11.0382 10.0967 11.2985 10.3571L12.7128 11.7713Z" fill="#FCFCFD" />
    <path d="M5.64171 5.64295C5.90206 5.3826 5.90206 4.96049 5.64171 4.70014L4.2275 3.28593C3.96715 3.02558 3.54504 3.02558 3.28469 3.28593C3.02434 3.54628 3.02434 3.96839 3.28469 4.22873L4.6989 5.64295C4.95925 5.9033 5.38136 5.9033 5.64171 5.64295Z" fill="#FCFCFD" />
  </svg>
</template>

<style scoped>

</style>
