<script setup lang="ts">
import { messageLight } from 'naive-ui/es/message/styles'

const { run: getCopywriting } = useHttp(vesselApi.stats.getCopywriting)
const { address } = useUser()
const c = ref<Record<string, string>>({})
const popoverRef = ref()
const selection = ref('')
const contact = ref('')
const isNotChoose = ref(false)
const message = useMessage()
const lastQuestionnaireId = useStorage('questionnaire', '')
const { isMobile } = useResponsive()

const { run: submitQuestionnaire, isLoading } = useHttp(vesselApi.stats.submitQuestionnaire)

const isNew = computed(() => lastQuestionnaireId.value !== c.value.question_id)

const options = computed(() => {
  const r = []
  for (const key of Object.keys(c.value)) {
    if (key.startsWith('option')) {
      r.push(c.value[key] as string)
    }
  }
  return r
})

function closePopover() {
  popoverRef.value.setShow(false)
  lastQuestionnaireId.value = c.value.question_id
}

function submit() {
  if (!selection.value) {
    isNotChoose.value = true
    return
  }
  const data = {
    questionId: c.value.question_id,
    questionTitle: c.value.question_title,
    contact: contact.value,
    choices: [
      selection.value,
    ],
  }
  submitQuestionnaire(data).then((res) => {
    message.success(c.value.success_msg_title)
    lastQuestionnaireId.value = c.value.question_id
    closePopover()
  }).catch(() => {
    message.error(c.value.fail_msg_title)
  })
}

function initPopover() {
  selection.value = ''
}

onMounted(() => {
  getCopywriting({ key: 'survey in vessel product' }).then((res) => {
    try {
      c.value = (JSON.parse(res.data.content) as { key: string; value: string }[]).reduce((res, i) => ({ ...res, [i.key]: i.value }), {})
    }
    catch (e) {

    }
    if (lastQuestionnaireId.value !== c.value.question_id && !IS_MAINNET) {
      popoverRef.value.setShow(true)
    }
  })
})
</script>

<template>
  <n-popover
    v-if="!isMobile"
    ref="popoverRef"
    placement="top-start" trigger="click" style="padding: 0; background-color: transparent;" :show-arrow="false"
    :on-update:show="initPopover"
  >
    <template #trigger>
      <div class="fixed bottom-0.4 left-0.4 z-202 h-0.56 w-0.56 flex-center cursor-pointer rd-full bg-black3">
        <n-badge :dot="isNew">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2.00098 22.0001V4.00006C2.00098 3.45006 2.19698 2.97939 2.58898 2.58806C2.98098 2.19673 3.45164 2.00073 4.00098 2.00006H20.001C20.551 2.00006 21.022 2.19606 21.414 2.58806C21.806 2.98006 22.0016 3.45073 22.001 4.00006V16.0001C22.001 16.5501 21.8053 17.0211 21.414 17.4131C21.0226 17.8051 20.5516 18.0007 20.001 18.0001H6.00098L2.00098 22.0001ZM12.001 15.0001C12.2843 15.0001 12.522 14.9041 12.714 14.7121C12.906 14.5201 13.0016 14.2827 13.001 14.0001C13.001 13.7167 12.905 13.4794 12.713 13.2881C12.521 13.0967 12.2836 13.0007 12.001 13.0001C11.7176 13.0001 11.4803 13.0961 11.289 13.2881C11.0976 13.4801 11.0016 13.7174 11.001 14.0001C11.001 14.2834 11.097 14.5211 11.289 14.7131C11.481 14.9051 11.7183 15.0007 12.001 15.0001ZM11.001 11.0001H13.001V5.00006H11.001V11.0001Z" fill="#37AAEC" />
          </svg>
        </n-badge>
      </div>
    </template>
    <div class="w-4.48 rd-0.2 bg-black2 p-0.32 font-poppins">
      <div class="flex items-start justify-between">
        <div class="text-0.32 font-700 tracking--0.0032 font-dm text-white2">
          {{ c.modal_title }}
        </div>
        <div class="ml-0.1 h-0.4 w-0.4 flex-center flex-none cursor-pointer border-2 border-black3 rd-full transition-all hover:rotate-90" @click="closePopover">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#FCFCFD" />
          </svg>
        </div>
      </div>
      <div class="mt-0.1 text-0.16 text-grey1">
        {{ c.modal_hint }}
      </div>
      <div class="mt-0.32">
        <div class="font-0.16 text-grey2">
          {{ c.question_title }} <span class="ml-0.05 text-primary">*</span>
        </div>
        <v-radio-group v-model="selection" class="my-0.12 grid-cols-1!" :options="options" @select="isNotChoose = false" />
        <div v-if="isNotChoose" class="text-0.12 text-red">
          Please choose your answer.
        </div>
      </div>
      <div class="mt-0.32">
        <div class="font-0.16 text-grey2">
          {{ c.contact_title }}
        </div>
        <v-input v-model="contact" class="mt-0.1" align="left" />
      </div>
      <v-button type="primary" class="mt-0.32 w-full" :loading="isLoading" @click="submit">
        {{ c.submit_btn }}
      </v-button>
    </div>
  </n-popover>
</template>

<style scoped>

</style>
