<script setup lang="ts">

</script>

<template>
  <AssetModule class="flex flex-col overflow-hidden p-0.24">
    <AssetHistoryOpen />
  </AssetModule>
</template>

<style scoped>

</style>
