<script setup lang="ts">
import LastTrade from './LastTrade.vue'
import Orderbook from './Orderbook.vue'

const tabOptions = [
  { value: 'orderbook', label: 'Order Book' },
  { value: 'last-trade', label: 'Last Trades' },
]
const currentTab = ref('orderbook')

const dynamicComponent = computed(() => {
  return {
    'orderbook': Orderbook,
    'last-trade': LastTrade,
  }[currentTab.value]
})
</script>

<template>
  <div class="h-full flex flex-col py-0.1">
    <v-tabs v-model="currentTab" class="flex-none px-0.1" :options="tabOptions" />
    <keep-alive>
      <component :is="dynamicComponent" class="relative flex-1 overflow-hidden" />
    </keep-alive>
  </div>
</template>

<style scoped>

</style>
