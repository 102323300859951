type ModalType =
  'chooseProvider' |
  'changeChainID' |
  'connecting' |
  'signVesselKey' |
  'invitationCodeBound' |
  'invitationCodeExpired' |
  'deposit' |
  'remindFaucet' |
  'disconnect' |
  'withdraw' |
  'claim' |
  'exportPrivateKey' |
  'feedback' |
  'duplicatedCode' |
  'walletNotBound' |
  'voyage' |
  'transfer' |
  'registerOnChain'

export const useModal = createGlobalState(() => {
  const modal = ref<Record<ModalType, boolean>>({
    chooseProvider: false,
    changeChainID: false,
    connecting: false,
    signVesselKey: false,
    invitationCodeBound: false,
    invitationCodeExpired: false,
    deposit: false,
    remindFaucet: false,
    disconnect: false,
    withdraw: false,
    claim: false,
    exportPrivateKey: false,
    feedback: false,
    duplicatedCode: false,
    walletNotBound: false,
    voyage: false,
    transfer: false,
    registerOnChain: false,
  })
  const feedbackType = ref<'feedback' | 'bug'>('feedback')

  function openModal(modalType: ModalType) {
    modal.value[modalType] = true
  }
  function closeModal(modalType: ModalType) {
    modal.value[modalType] = false
  }
  function closeAllModal() {
    for (const modalKey of Object.keys(modal.value)) {
      modal.value[modalKey as keyof typeof modal.value] = false
    }
  }
  return {
    modal,
    openModal,
    closeModal,
    feedbackType,
    closeAllModal,
  }
})
