import {
  useConfig,
  useConnect,
  useDisconnect,
  useReconnect,
} from '@wagmi/vue'
import { watchAccount } from '@wagmi/core'

export type WalletConnectType = 'metaMaskSDK' | 'walletConnect' | 'io.rabby' | 'com.okex.wallet'
  | 'particleWallet' | 'particle_coinbase' | 'particle_trust' | 'particle_okx' | 'particle_phantom' | 'particle_metamask'

export const useConnector = createGlobalState(() => {
  const { openModal } = useModal()
  const config = useConfig()
  const currentChainId = ref(config.chains[0].id)
  const { reconnect } = useReconnect()
  const actionAfterConnected = ref<() => void>()
  const { connectors, isSuccess: isConnected, connectAsync } = useConnect()
  const { disconnectAsync: disconnectProvider } = useDisconnect()
  const lastConnectWalletType = useStorage<WalletConnectType | ''>('walletType', '')

  const currentConnector = computed(() => connectors.find(i => i.id === lastConnectWalletType.value))
  const currentVaultAddress = computed<`0x${string}`>(() => {
    const chainConfig = getChainConfigSync()
    return chainConfig.find(i => i.id === currentChainId.value)?.vaultAddress as `0x${string}` || chainConfig[0]?.vaultAddress as `0x${string}`
  })

  const connectWalletFn = useDebounceFn(async (data) => {
    initNewWallet(data.address)
  }, 50)

  const unwatch = watchAccount(config, {
    async onChange(data, prevAccount) {
      console.log('account', data, prevAccount)
      if (data.address !== prevAccount?.address) {
        if (!prevAccount?.address) {
          connectWalletFn(data)
        }
        else if (!data.address) {
          await clearAddressInfo()
        }
        else {
          location.reload()
        }
      }
    },
  })

  async function checkChain(fn?: () => void, fn2?: () => void) {
    actionAfterConnected.value = fn
    console.log(currentConnector.value)

    if (currentConnector.value && await currentConnector.value.getChainId() !== currentChainId.value) {
      openModal('changeChainID')
      if (fn2) {
        fn2()
      }
      return false
      // throw new Error('Chain ID is not correct.')
    }
    return true
  }

  async function disconnect() {
    lastConnectWalletType.value = ''
    // disconnectProvider({ connector: currentConnector.value }).then(() => {
    // config.setState(x => ({
    //   ...x,
    //   connections: new Map([...config.state.connections].filter(([key, value]) => key !== config.state.current)),
    //   current: null,
    // }))
    // })
  }

  function initWallet() {
    if (currentConnector.value) {
      reconnect({ connectors: [currentConnector.value] })
    }
  }

  return {
    lastConnectWalletType,
    disconnect,
    checkChain,
    isConnected,
    currentConnector,
    currentChainId,
    initWallet,
    currentVaultAddress,
    actionAfterConnected,
  }
})
