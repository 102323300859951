<script setup lang="ts">
const value = ref('')
</script>

<template>
  <div class="p-1">
    <v-input
      v-model="value"
      class="mt-0.12 w-4"
      prefix="Amount"
      suffix="BTC"
      error-message="12"
    />
    <v-input
      v-model="value"
      class="mt-0.12 w-4"
      align="left"
    >
      <template #suffix>
        <div class="h-0.32 cursor-pointer select-none border border-2 border-black3 rd-full px-0.16 text-0.14 font-700 leading-0.28">
          Max amount
        </div>
      </template>
    </v-input>
  </div>
</template>

<style scoped>

</style>
