<script setup lang="ts" generic="T extends any, O extends any">

</script>

<template>
  <div class="h-full w-full flex flex-col overflow-x-hidden">
    <Topbar />
    <RouterView />
  </div>
</template>
