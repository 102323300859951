<script setup lang="ts">
const props = defineProps<{
  modelValue: string
  options: string[]
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
  'select': []
}>()

function onSelect(item: string) {
  emit('update:modelValue', item)
  emit('select')
}
</script>

<template>
  <div class="grid grid-cols-2 gap-0.16">
    <div v-for="item in options" :key="item" class="flex cursor-pointer items-center gap-x-0.08" @click="onSelect(item)">
      <svg v-if="modelValue !== item" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.99967 1.33325C4.31967 1.33325 1.33301 4.31992 1.33301 7.99992C1.33301 11.6799 4.31967 14.6666 7.99967 14.6666C11.6797 14.6666 14.6663 11.6799 14.6663 7.99992C14.6663 4.31992 11.6797 1.33325 7.99967 1.33325ZM7.99967 13.3333C5.05301 13.3333 2.66634 10.9466 2.66634 7.99992C2.66634 5.05325 5.05301 2.66659 7.99967 2.66659C10.9463 2.66659 13.333 5.05325 13.333 7.99992C13.333 10.9466 10.9463 13.3333 7.99967 13.3333Z" fill="#777E91" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.99967 1.33325C4.31967 1.33325 1.33301 4.31992 1.33301 7.99992C1.33301 11.6799 4.31967 14.6666 7.99967 14.6666C11.6797 14.6666 14.6663 11.6799 14.6663 7.99992C14.6663 4.31992 11.6797 1.33325 7.99967 1.33325ZM7.99967 13.3333C5.05301 13.3333 2.66634 10.9466 2.66634 7.99992C2.66634 5.05325 5.05301 2.66659 7.99967 2.66659C10.9463 2.66659 13.333 5.05325 13.333 7.99992C13.333 10.9466 10.9463 13.3333 7.99967 13.3333Z" fill="#37AAEC" />
        <path d="M8.00033 11.3334C9.84127 11.3334 11.3337 9.84103 11.3337 8.00008C11.3337 6.15913 9.84127 4.66675 8.00033 4.66675C6.15938 4.66675 4.66699 6.15913 4.66699 8.00008C4.66699 9.84103 6.15938 11.3334 8.00033 11.3334Z" fill="#37AAEC" />
      </svg>
      <div
        class="text-0.12 font-600 leading-0.16 font-poppins transition-colors hover:text-primary"
        :class="modelValue === item ? 'text-primary' : 'text-secondary'"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
