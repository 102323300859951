<script setup lang="tsx">
import type { Option } from './progress.vue'
import badgeImg1 from '@/assets/image/airdrop/badge1.png'
import badgeImg2 from '@/assets/image/airdrop/badge2.png'
import badgeImg3 from '@/assets/image/airdrop/badge3.png'
import badgeImg4 from '@/assets/image/airdrop/badge4.png'
import badgeImg5 from '@/assets/image/airdrop/badge5.png'
import badgeImg6 from '@/assets/image/airdrop/badge6.png'
import galxeBg from '@/assets/image/airdrop/galxe-bg.png'
import galxeImg from '@/assets/image/airdrop/galxe-img.png'
import loyaltyImg from '@/assets/image/airdrop/loyalty.png'

const { loyaltyList, airdropInfo } = useAirdrop()

const { openModal, feedbackType } = useModal()
const { isSigned } = useKey()
const { isConnected } = useUser()

function getLoyalty(current: number): JSX.Element {
  const item = loyaltyList.value.filter(i => i.levelAfter === current).at(-1)

  if (!item) {
    return undefined
  }
  return (
    <div class="w-2.8 flex flex-col items-center gap-y-0.04 px-0.12 py-0.06 text-0.14 font-500 sm:w-3.04">
      <div class="text-white2">{item.title}</div>
      <div class="text-grey2">{item.description}</div>
      <div class="text-0.12 font-400">{dayjs(item.changeAt).format('MM DD, YYYY HH:mm')}</div>
    </div>
  )
}

const levelOption = computed<Option[]>(() => [
  {
    value: 0,
    name: 'Lv.1',
    tooltipRender: getLoyalty(1),
  },
  {
    value: 1,
    name: 'Lv.2',
    tooltipRender: getLoyalty(2),
  },
  {
    value: 2,
    name: 'Lv.3',
    tooltipRender: getLoyalty(3),
  },
  {
    value: 3,
    name: 'Lv.4',
    tooltipRender: getLoyalty(4),
  },
  {
    value: 4,
    name: 'Lv.5',
    tooltipRender: getLoyalty(5),
  },
])

function share(shareType: 'feedback' | 'bug') {
  openModal('feedback')
  feedbackType.value = shareType
}
</script>

<template>
  <div>
    <div class="text-0.18 font-600 text-grey3">
      Loyalty Level
    </div>
    <div class="mb-0.14 text-0.14 font-500 text-grey1">
      Discover exclusive perks and rewards by achieving higher loyalty levels.
    </div>
    <AirdropCard v-if="isSigned">
      <div class="w-full">
        <AirdropProgress :step="(airdropInfo?.userLoyaltyLevel || 1) - 1" :options="levelOption" :total="4" />
      </div>
    </AirdropCard>
    <AirdropCard v-else class="mb-0.4 w-3.36 flex-center py-0.6 sm:w-auto px-0.1!">
      <common-connect-wallet-button v-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" />
    </AirdropCard>
    <div class="flex items-center justify-between">
      <div>
        <div class="mt-0.24 text-0.18 font-600 text-grey3">
          Build Your Loyalty
        </div>
        <div class="mb-0.14 text-0.14 font-500 text-grey1">
          Level up your loyalty status by actively engaging with the Vessel community.
        </div>
      </div>
      <img class="h-0.48 w-0.48" :src="loyaltyImg" alt="">
    </div>
    <div class="flex flex-col gap-x-0.2 gap-y-0.14 sm:flex-row">
      <div
        class="flex flex-1 flex-col items-center rd-0.24 px-0.32 py-0.4"
        :style="{ background: `url(${galxeBg})`, backgroundSize: '100% 100%' }"
      >
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="galxeImg" alt="">
        </div>
        <div class="text-0.14 font-500">
          Galxe and Beyond
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points from Galxe related campaigns.
        </div>
        <v-button type="outline" class="mt-0.36" @click="openUrl('https://galxe.com/DsQcmWEWMhuuF2k5JWoQes/campaign/GCZ4wtTYqe')">
          Join Campaigns
        </v-button>
      </div>

      <AirdropCard class="flex flex-1 flex-col items-center">
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="badgeImg6" alt="">
        </div>
        <div class="text-0.14 font-500">
          Ambassador Program
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points as a Vessel ambassador! Be our voices!
        </div>
        <v-button type="outline" class="mt-0.12" @click="openUrl(GITBOOK_URL)">
          More Information
        </v-button>
      </AirdropCard>

      <AirdropCard class="flex flex-1 flex-col items-center">
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="badgeImg1" alt="">
        </div>
        <div class="text-0.14 font-500">
          Feedback Reporter
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points by giving us suggestions and expectations!
        </div>
        <v-button type="outline" class="mt-0.12" @click="share('feedback')">
          Share Feedback
        </v-button>
      </AirdropCard>
    </div>
    <div class="mb-0.66 mt-0.2 flex flex-col gap-x-0.2 gap-y-0.14 sm:flex-row">
      <!-- <AirdropCard class="flex flex-1 flex-col items-center">
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="badgeImg4" alt="">
        </div>
        <div class="text-0.14 font-500">
          Media Promotion
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points from being our voice in social media propagation and content creation.
        </div>
        <v-button type="outline" class="mt-0.12" @click="openUrl('https://x.com/vesselfinance?s=21&t=EglxJCuyhz1aGUWyhMhHTg')">
          Follow Twitter
        </v-button>
      </AirdropCard> -->
      <AirdropCard class="flex flex-1 flex-col items-center">
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="badgeImg3" alt="">
        </div>
        <div class="text-0.14 font-500">
          Bug Buster
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points for bug catching! Help us enhance and improve!
        </div>
        <v-button type="outline" class="mt-0.12" @click="share('bug')">
          Report Bug
        </v-button>
      </AirdropCard>
      <AirdropCard class="flex flex-1 flex-col items-center">
        <div>
          <img class="mb--0.15 ml-0.1 mt--0.3 w-1.5" :src="badgeImg5" alt="">
        </div>
        <div class="text-0.14 font-500">
          API Integration
        </div>
        <div class="mt-0.08 text-center text-0.14 font-500 leading-0.24 text-grey1">
          Earn loyalty points from contributing to Vessel API with valuable insights.
        </div>
        <v-button type="outline" class="mt-0.12" @click="openUrl('https://apidoc.vessel.finance/')">
          Vessel API
        </v-button>
      </AirdropCard>
      <AirdropCard class="invisible flex flex-1 flex-col items-center" />
    </div>
  </div>
</template>

<style scoped>

</style>
