<script setup lang="ts">
import bgImg from '@/assets/image/airdrop/bg.png'
import titleBgImg from '@/assets/image/airdrop/title-bg.png'
import cardBgImg from '@/assets/image/airdrop/card-bg.png'
import titleImg from '@/assets/image/airdrop/title.png'
import collectButtonImg from '@/assets/image/airdrop/collect-button-disabled.png'

const showShare = ref(false)
const { isSigned } = useKey()
const { isConnected } = useUser()
const { initAirdrop } = useAirdrop()

const tabOptions = [
  {
    value: 'interaction',
    label: 'Interaction',
  },
  {
    value: 'contribution',
    label: 'Contribution',
  },
  {
    value: 'referral',
    label: 'Referral',
  },
  {
    value: 'leaderboard',
    label: 'Leaderboard',
    disabled: true,
  },
]
const currentTab = ref('interaction')

const { airdropInfo, resume, pause } = useAirdrop()
const { isMobile } = useResponsive()

watch(isSigned, () => {
  if (isSigned.value) {
    initAirdrop()
  }
}, {
  immediate: true,
})
onMounted(() => {
  resume()
})
onUnmounted(pause)
</script>

<template>
  <div
    class="absolute inset-0 top-0.8 flex flex-col items-center overflow-auto bg-cover bg-repeat"
    :style="{ backgroundImage: `url(${bgImg})` }"
  >
    <img :src="titleBgImg" class="pointer-events-none absolute left-1/2 top--0.5 z-2 sm:top-0 sm:h-7.2 sm:w-3 -translate-x-1/2" alt="">
    <img :src="titleImg" class="pointer-events-none absolute left-1/2 top-2.5 z-1 w-3 sm:top-2.5 sm:w-6 -translate-x-1/2" alt="">
    <div class="absolute left-1/2 top-3.36 z-3 w-3.01 text-center text-0.16 text-grey2 sm:top-3.6 sm:w-5.3 -translate-x-1/2">
      Discover trading with Vessel! Earn points by exploring checkpoints tasks and becoming part of the community.
    </div>
    <div
      style="background: linear-gradient(180deg, rgba(35, 38, 47, 0.35) -57.98%, rgba(0, 0, 0, 0.70) 101.73%)"
      class="absolute left-1/2 top-6.24 z-3 h-2.36 w-3.33 flex flex-col items-center backdrop-blur-[10px] sm:top-5.92 sm:h-2.36 sm:w-8.64 -translate-1/2"
    >
      <img class="pointer-events-none absolute inset-0 z--1 w-full" :src="cardBgImg" alt="">
      <div
        class="mt-0.32 h-0.24 w-0.24 flex-center rd-full"
        style="background:linear-gradient(180deg, #37AAEC 0%, #19F2FF 100%);box-shadow: 1.5px 3px 9px 0px rgba(204, 252, 255, 0.20) inset;"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.64945C6 3.59445 7.19 4.00945 7.78 3.27445C7.915 3.10945 8.15 3.08445 8.315 3.20945C9.52 4.07445 10.23 5.62445 9.925 7.25945C9.715 8.37945 9.015 9.33445 8.07 9.90945C8.42 9.39945 8.58 8.75945 8.455 8.07945C8.365 7.56445 8.085 7.10445 7.715 6.73945L6.35 5.39445C6.155 5.20445 5.845 5.20445 5.65 5.39445L4.295 6.72945C3.915 7.09945 3.635 7.56945 3.54 8.08945C3.48162 8.40766 3.48737 8.73433 3.55692 9.05029C3.62646 9.36626 3.7584 9.66515 3.945 9.92945C2.78 9.23445 2 7.95945 2 6.49945C2 3.98445 4.06 2.24445 5.225 1.46445C5.555 1.24445 6 1.48445 6 1.88445V2.64945ZM4.98486 7.44922L5.99986 6.44922L7.01486 7.45422C7.24986 7.67922 7.42486 7.96922 7.47986 8.28922C7.62986 9.20422 6.90986 9.99922 5.99986 9.99922C5.08986 9.99922 4.36986 9.20422 4.51986 8.28422C4.57486 7.96422 4.75486 7.67922 4.98486 7.44922Z" fill="#141416" />
        </svg>
      </div>
      <div class="mt-0.04 text-0.14 font-500 leading-0.24 font-poppins text-primary">
        Total Points
      </div>
      <div class="mb-0.24 text-0.48 font-0.48 font-700 tracking--0.0096 font-dm text-white2 sm:leading-0.56">
        {{ airdropInfo?.totalScore ?? '-' }}
      </div>
      <div class="text-caption1 text-grey2">
        Ended on July 05, 2024, at 09:00 (UTC).
      </div>
      <v-button disabled class="mt0.1 w-1.37">
        Closed
      </v-button>
      <!-- <img v-if="isSigned" class="w-1.07 cursor-not-allowed" :src="collectButtonImg" alt="">
      <common-connect-wallet-button v-else-if="!isConnected" />
      <common-sign-for-trading-button v-else-if="!isSigned" /> -->
      <!-- <div class="absolute right-0.2 top-0.2 h-0.4 w-0.4 flex-center cursor-pointer rd-full bg-black3 pr-0.02" @click="showShare = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 12" fill="none">
          <path d="M8.27361 11.4545C7.81907 11.4545 7.43271 11.2955 7.11452 10.9773C6.79634 10.6591 6.63725 10.2727 6.63725 9.81818C6.63725 9.75455 6.6418 9.68855 6.65089 9.62018C6.65998 9.55182 6.67362 9.49055 6.6918 9.43637L2.84634 7.2C2.6918 7.33637 2.51907 7.44327 2.32816 7.52073C2.13725 7.59818 1.93725 7.63673 1.72816 7.63637C1.27362 7.63637 0.887251 7.47727 0.56907 7.15909C0.250888 6.84091 0.0917969 6.45455 0.0917969 6C0.0917969 5.54546 0.250888 5.15909 0.56907 4.84091C0.887251 4.52273 1.27362 4.36364 1.72816 4.36364C1.93725 4.36364 2.13725 4.40237 2.32816 4.47982C2.51907 4.55727 2.6918 4.664 2.84634 4.8L6.6918 2.56364C6.67362 2.50909 6.65998 2.44782 6.65089 2.37982C6.6418 2.31182 6.63725 2.24582 6.63725 2.18182C6.63725 1.72727 6.79634 1.34091 7.11452 1.02273C7.43271 0.704547 7.81907 0.545456 8.27361 0.545456C8.72816 0.545456 9.11452 0.704547 9.43271 1.02273C9.75089 1.34091 9.90998 1.72727 9.90998 2.18182C9.90998 2.63637 9.75089 3.02273 9.43271 3.34091C9.11452 3.65909 8.72816 3.81818 8.27361 3.81818C8.06452 3.81818 7.86452 3.77964 7.67361 3.70255C7.48271 3.62546 7.30998 3.51855 7.15543 3.38182L3.30998 5.61818C3.32816 5.67273 3.3418 5.73418 3.35089 5.80255C3.35998 5.87091 3.36452 5.93673 3.36452 6C3.36452 6.06364 3.35998 6.12964 3.35089 6.198C3.3418 6.26637 3.32816 6.32764 3.30998 6.38182L7.15543 8.61818C7.30998 8.48182 7.48271 8.37509 7.67361 8.298C7.86452 8.22091 8.06452 8.18218 8.27361 8.18182C8.72816 8.18182 9.11452 8.34091 9.43271 8.65909C9.75089 8.97727 9.90998 9.36364 9.90998 9.81818C9.90998 10.2727 9.75089 10.6591 9.43271 10.9773C9.11452 11.2955 8.72816 11.4545 8.27361 11.4545Z" fill="#37AAEC" />
        </svg
      </div> -->
    </div>
    <div class="absolute top-7.5 sm:top-7.2 sm:w-8.6">
      <AirdropTabs v-model="currentTab" :options="!isMobile ? tabOptions : tabOptions.slice(0, 3)" />
    </div>
    <div class="mt-8 sm:mt-7.8 sm:w-8.6">
      <AirdropInteraction v-if="currentTab === 'interaction'" class="w-full" />
      <AirdropContribution v-if="currentTab === 'contribution'" class="w-full" />
      <AirdropReferral v-if="currentTab === 'referral'" class="w-full" />
    </div>
  </div>
  <AirdropShare v-model:show="showShare" />
</template>

<style scoped>
</style>
