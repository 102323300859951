<script setup lang="ts">
import type { UserPositionItem } from '@/service/modules/amm'
import type { Column } from '~/components/v/Table.vue'

const { isMobile } = useResponsive()
const { isSigned } = useKey()
const { poolDetailList, updatePool, selectPool, selectPoolRow, userPoolList, getUserPosition, isLoadingUserPosition } = useAmm()
const { symbolList, currencyList } = useSymbol()
const { symbolPrice } = useWs()

const { run: collectFeeReq, isLoading: isLoadingCollect } = useHttp(vesselApi.amm.collectFee)

const page = ref(1)
const PAGE_SIZE = 6
const showCollect = ref(false)
const isCollecting = ref(false)

const notification = useNotification()

const tableColumns = computed<Column[]>(() => [
  {
    key: 'pool',
    title: 'Pool',
    minWidth: 300,
    align: 'left',
  },
  {
    key: 'range',
    title: 'Price Range',
    minWidth: 240,
    width: 80,
    align: 'right',
  },
  {
    key: 'position',
    title: 'Position',
    minWidth: 120,
    width: 80,
    align: 'right',
  },
  {
    key: 'fee',
    title: 'Uncollected Fees',
    minWidth: 120,
    width: 80,
    align: 'right',
  },
  // {
  //   key: 'pnl',
  //   title: 'Total PNL',
  //   minWidth: 80,
  //   width: 80,
  //   align: 'right',
  // },

])

const currencyDirection = ref('')
const isQuote = computed(() => currencyDirection.value === getSymbolItemByPoolId(selectPool.value?.poolId)?.quote)

const currentPairSwitch = computed(() => {
  const symbolItem = getSymbolItemByPoolId(selectPool.value?.poolId)
  if (symbolItem.base) {
    return [symbolItem.base, symbolItem.quote]
  }
  else {
    return ['base', 'quote']
  }
})

function onChangePage(current: number) {
  page.value = current
}

const poolListByPage = computed(() => userPoolList.value.slice(PAGE_SIZE * (page.value - 1), PAGE_SIZE * page.value))

function getPoolItemByPoolId(poolId: string) {
  return poolDetailList.value.find(i => i.poolId === poolId)
}

function getSymbolItemByPoolId(poolId: string) {
  const symbol = poolDetailList.value.find(i => i.poolId === poolId)?.symbol
  return symbolList.value.find(i => i.symbol === symbol)
}

function getPrice(poolId: string, baseAmount: number, quoteAmount: number) {
  const symbolItem = getSymbolItemByPoolId(poolId)
  const basePrice = +currencyList.value?.find(i => i.assetName === symbolItem?.base)?.price
  const quotePrice = +currencyList.value?.find(i => i.assetName === symbolItem?.quote)?.price
  return +baseAmount * basePrice + +quoteAmount * quotePrice
}

const isLoadSymbolPrice = computed(() => Object.keys(symbolPrice.value).length > 0)

function isInRange(row: UserPositionItem) {
  const currentPrice = +symbolPrice.value?.[getSymbolItemByPoolId(row.poolId)?.symbol]?.closePrice
  return +row.tickL < currentPrice && currentPrice < +row.tickR
}

function onClickRow(row: UserPositionItem) {
  selectPoolRow.value = row
  currencyDirection.value = getSymbolItemByPoolId(row.poolId).base
}

function getSymbolIndex(value: number) {
  if (!value) {
    return 0
  }
  const currentSymbolAmmItem = poolDetailList.value.find(i => i.poolId === selectPool.value.poolId)
  if (+value < +currentSymbolAmmItem?.minTick) {
    return 0
  }
  if (+value > +currentSymbolAmmItem?.maxTick) {
    return Math.round((+currentSymbolAmmItem.maxTick - +currentSymbolAmmItem.minTick) / +currentSymbolAmmItem.tickSpacing)
  }
  return Math.round((+value - +currentSymbolAmmItem?.minTick) / +currentSymbolAmmItem?.tickSpacing)
}

async function collectFee() {
  if ((+selectPool.value.quoteFeeAmount === 0 && +selectPool.value.baseFeeAmount === 0) || isCollecting.value) {
    return
  }
  isCollecting.value = true
  const signMessage = {
    poolId: +selectPool.value.poolId,
    addType: 0,
    tickIndexL: getSymbolIndex(+selectPool.value.tickL),
    tickIndexR: getSymbolIndex(+selectPool.value.tickR),
    baseAmount: 0,
    quoteAmount: 0,
    timestamp: new Date().getTime(),
    nonce: new Date().getTime(),
  }
  const symbolItem = getSymbolItemByPoolId(selectPool.value.poolId)

  const signature = await signPool(signMessage, symbolItem?.symbol)

  collectFeeReq({
    ...signMessage,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': signMessage.timestamp,
    },
  }).then((res) => {
    if (res.data.error) {
      return
    }
    notification.success({
      title: 'Collect Fee Confirmation',
      content: `Your fees of ${res.data.baseAssetAmount} ${symbolItem.base} and ${res.data.quoteAssetAmount} ${symbolItem.quote} have been successfully collected from the ${symbolItem.base}/${symbolItem.quote} pool.`,
      duration: 2000,
    })
    showCollect.value = false
    selectPool.value.quoteFeeAmount = '0'
    selectPool.value.baseFeeAmount = '0'
    getUserPosition()
  }).finally(() => {
    isCollecting.value = false
  })
}

const totalValue = computed(() => {
  return userPoolList.value.reduce((res, row) => res + getPrice(row.poolId, +row.baseAmount, +row.quoteAmount), 0)
})
const totalFee = computed(() => {
  return userPoolList.value.reduce((res, row) => res + getPrice(row.poolId, +row.baseFeeAmount, +row.quoteFeeAmount), 0)
})

onMounted(() => {
  getUserPosition()
  updatePool()
})
whenever(isSigned, getUserPosition)
onUnmounted(() => {
  // selectPool.value = null
})
</script>

<template>
  <AssetModule v-if="!selectPoolRow" class="overflow-auto p-0.16">
    <v-button class="" @click="$router.push('/pools/add')">
      <template #prefix>
        <SvgPlus class="mr-0.08" />
      </template>
      New Position
    </v-button>
    <div v-if="poolListByPage.length !== 0" class="my-0.32 flex px-0.16">
      <div class="mr-0.12">
        <div class="text-caption1b">
          Estimate Pool Value
        </div>
        <div class="text-body1b">
          $ {{ formatNumber(totalValue, 2) }}
        </div>
      </div>
      <div class="mx-0.24 h-0.5 bg-black2 pl-0.02" />
      <div class="mr-0.12 min-w-1.8">
        <div class="text-caption1b">
          Uncollected Fees
        </div>
        <div class="text-body1b">
          $ {{ formatNumber(totalFee, 2) }}
        </div>
      </div>
    </div>
    <v-table
      v-if="!isMobile && (isLoadingUserPosition || poolListByPage.length !== 0)"
      class="flex-1"
      :data="poolListByPage"
      :columns="tableColumns"
      :loading="isLoadingUserPosition"
      content-class="min-h-2"
      :column-gap="35"
      row-class="h-0.68 py-0.14 px-0.32 gap-x-0.16  hover:bg-black2 cursor-pointer text-caption1 rd-0.04"
      title-row-class="py-0.14 px-0.32 gap-x-0.16  mb-0!"
      row-key="time"
      @click-row="onClickRow"
    >
      <template #pool="{ data: { row } }">
        <div class="flex items-center gap-x-0.04">
          <v-icon-pair :base="getSymbolItemByPoolId(row.poolId)?.base" :quote="getSymbolItemByPoolId(row.poolId)?.quote" :size="24" />
          <div class="">
            <span class="text-caption1">{{ getSymbolItemByPoolId(row.poolId)?.base }}/{{ getSymbolItemByPoolId(row.poolId)?.quote }}</span>
            <div class="flex items-center">
              <VTickSpacing :tick-spacing="getPoolItemByPoolId(row.poolId)?.tickSpacing" :token="getSymbolItemByPoolId(row.poolId)?.quote" />
              <v-in-range v-if="isLoadSymbolPrice" :is-in="isInRange(row)" />
            </div>
          </div>
        </div>
      </template>
      <template #range="{ data: { row } }">
        <div class="">
          {{ formatNumber(row.tickL, -1) }} {{ getSymbolItemByPoolId(row.poolId)?.quote }} per {{ getSymbolItemByPoolId(row.poolId)?.base }} - <br> {{ formatNumber(row.tickR, -1) }} {{ getSymbolItemByPoolId(row.poolId)?.quote }} per {{ getSymbolItemByPoolId(row.poolId)?.base }}
        </div>
      </template>
      <template #position="{ data: { row } }">
        <div class="">
          ${{ formatNumber(getPrice(row.poolId, +row.baseAmount, +row.quoteAmount), 2) }}
        </div>
      </template>
      <template #fee="{ data: { row } }">
        <div class="">
          ${{ formatNumber(getPrice(row.poolId, +row.baseFeeAmount, +row.quoteFeeAmount), 2) }}
        </div>
      </template>
    </v-table>
    <div v-else-if="poolListByPage.length === 0" class="mb-0.32 mt-0.32 p-0.18 text-center text-0.12 text-grey1 font-600">
      You haven’t created any positions on Vessel yet.
    </div>
    <div v-if="!isMobile && userPoolList?.length > PAGE_SIZE" class="flex justify-center">
      <n-pagination
        class="mt-0.12"
        :default-page-size="PAGE_SIZE"
        :item-count="userPoolList?.length"
        :page="page" @update-page="onChangePage"
      >
        <template #prev>
          <svg-back class="h-0.24 w-0.24" />
        </template>
        <template #next>
          <svg-back class="h-0.24 w-0.24 rotate-180" />
        </template>
      </n-pagination>
    </div>
    <div class="mt-0.2 flex justify-between gap-x-0.48 px-1">
      <div
        class="flex flex-1 cursor-pointer items-center justify-between rd-br-0.4 rd-tl-0.4 bg-black2 px-0.32 py-0.16"
        @click="$router.push('/pools')"
      >
        <div>
          <div class="mb-0.08 text-0.16 text-white2 font-500">
            Explore All AMM Pools
          </div>
          <div class="text-0.12 text-grey1 font-500 leading-0.4">
            Discover a variety of AMM Pools on Vessel.
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class="flex-none">
          <path d="M11.4531 22.12L17.5598 16L11.4531 9.88L13.3331 8L21.3331 16L13.3331 24L11.4531 22.12Z" fill="#777E91" />
        </svg>
      </div>
      <div
        class="flex flex-1 cursor-pointer items-center justify-between rd-br-0.4 rd-tl-0.4 bg-black2 px-0.32 py-0.16"
        @click="openUrl('https://vesselfinance.gitbook.io/vessel/vessel-mainnet-user-guide/provide-liquidity-to-vessels-vaelob')"
      >
        <div>
          <div class="mb-0.08 text-0.16 text-white2 font-500">
            Learn about Vessel’s VAELOB
          </div>
          <div class="text-0.12 text-grey1 font-500 leading-0.2">
            Go over our guide insights and perfect your strategy in Vessel’s VAELOB.
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class="flex-none">
          <path d="M11.4531 22.12L17.5598 16L11.4531 9.88L13.3331 8L21.3331 16L13.3331 24L11.4531 22.12Z" fill="#777E91" />
        </svg>
      </div>
    </div>
    <!-- <div v-else>
      <div v-for="(row, index) in currentList" :key="index">
        <div class="mb-0.14 flex justify-between text-grey1">
          <div>Time</div>
          <div class="text-white2">
            {{ dayjs(row.timestamp).format('MM/DD/YYYY HH:mm:ss') }}
          </div>
        </div>
        <div class="mb-0.14 flex justify-between text-grey1">
          <div>Asset Name</div>
          <div class="text-white2">
            {{ row.assetName }}
          </div>
        </div>
        <div class="mb-0.14 flex justify-between text-grey1">
          <div>Amount</div>
          <div class="text-white2">
            {{ row.amount }}
          </div>
        </div>
        <div v-if="current !== 'claim'" class="mb-0.14 flex justify-between text-grey1">
          <div>Status</div>
          <div class="text-white2">
            {{ statusDict[row.status as 0 | 1 | 2] }}
          </div>
        </div>
        <div v-if="current !== 'claim'" class="mb-0.14 flex justify-between text-grey1">
          <div>Transaction Fee</div>
          <div class="text-white2">
            {{ row.transactionFee }}
          </div>
        </div>
        <div v-if="index !== currentList.length - 1" class="mb-0.16 w-full bg-black3 pt-0.01" />
      </div>
    </div> -->
  </AssetModule>
  <AssetModule v-else class="overflow-auto p-0.16 px-0.32">
    <v-back name="All positions" @click="selectPoolRow = null" />
    <div class="mt-0.16 flex justify-between">
      <div class="flex items-center gap-x-0.04 text-0.32 font-700 font-dm">
        <v-icon-pair :base="getSymbolItemByPoolId(selectPool.poolId)?.base" :quote="getSymbolItemByPoolId(selectPool.poolId)?.quote" :size="32" />
        <div class="">
          <div class="mb-0.04 text-body1b">
            {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}/{{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
          </div>
          <div class="flex items-center">
            <VTickSpacing :tick-spacing="getPoolItemByPoolId(selectPool.poolId)?.tickSpacing" :token="getSymbolItemByPoolId(selectPool.poolId)?.quote" />
            <v-in-range v-if="isLoadSymbolPrice" :is-in="isInRange(selectPool)" />
          </div>
        </div>
      </div>
      <div class="flex gap-x-0.12">
        <v-button @click="$router.push('/pools/increase')">
          Increase Liquidity
        </v-button>
        <v-button type="outline" @click="$router.push('/pools/remove')">
          Remove Liquidity
        </v-button>
      </div>
    </div>
    <div class="mt-0.16 flex gap-0.16">
      <div class="flex-1 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
        <div>
          <div class="text-0.14 text-grey1 font-500">
            Liquidity
          </div>
          <div class="text-0.24 text-white2 font-600">
            ${{ formatNumber(getPrice(selectPool.poolId, +selectPool.baseAmount, +selectPool.quoteAmount), 2) }}
          </div>
        </div>
        <div class="mt-0.24 flex">
          <div class="flex-1">
            <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
            </div>
            <div class="mt-0.04 text-0.24 text-white2 font-600">
              {{ formatNumber(selectPool.baseAmount, -1) }}
            </div>
            <div class="text-0.12 text-grey1 font-500">
              ${{ formatNumber(getPrice(selectPool.poolId, +selectPool.baseAmount, 0)) }}({{
                getRatio(+getPrice(selectPool.poolId, +selectPool.baseAmount, 0)
                  / +getPrice(selectPool.poolId, +selectPool.baseAmount, +selectPool.quoteAmount)) }})
            </div>
          </div>
          <div class="flex-1">
            <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
            </div>
            <div class="mt-0.04 text-0.24 text-white2 font-600">
              {{ formatNumber(selectPool.quoteAmount, -1) }}
            </div>
            <div class="text-0.12 text-grey1 font-500">
              ${{ formatNumber(getPrice(selectPool.poolId, 0, +selectPool.quoteAmount), 2) }}({{
                getRatio(+getPrice(selectPool.poolId, 0, +selectPool.quoteAmount)
                  / +getPrice(selectPool.poolId, +selectPool.baseAmount, +selectPool.quoteAmount)) }})
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
        <div class="flex justify-between">
          <div>
            <div class="text-0.14 text-grey1 font-500">
              Uncollected Fees
            </div>
            <div class="text-0.24 text-white2 font-600">
              ${{ formatNumber(getPrice(selectPool.poolId, +selectPool.baseFeeAmount, +selectPool.quoteFeeAmount), 2) }}
            </div>
          </div>
          <div>
            <v-button type="outline" @click="showCollect = true">
              Collect Fees
            </v-button>
          </div>
        </div>
        <div class="mt-0.24 flex">
          <div class="flex-1">
            <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
            </div>
            <div class="mt-0.04 text-0.24 text-white2 font-600">
              {{ formatNumber(selectPool.baseFeeAmount, -1) }}
            </div>
            <div class="text-0.12 text-grey1 font-500">
              ${{ formatNumber(getPrice(selectPool.poolId, +selectPool.baseFeeAmount, 0), 2) }}({{
                getRatio(+getPrice(selectPool.poolId, +selectPool.baseFeeAmount, 0)
                  / +getPrice(selectPool.poolId, +selectPool.baseFeeAmount, +selectPool.quoteFeeAmount)) }})
            </div>
          </div>
          <div class="flex-1">
            <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
            </div>
            <div class="mt-0.04 text-0.24 text-white2 font-600">
              {{ formatNumber(selectPool.quoteFeeAmount, -1) }}
            </div>
            <div class="text-0.12 text-grey1 font-500">
              ${{ formatNumber(getPrice(selectPool.poolId, 0, +selectPool.quoteFeeAmount), 2) }}({{
                getRatio(+getPrice(selectPool.poolId, 0, +selectPool.quoteFeeAmount)
                  / +getPrice(selectPool.poolId, +selectPool.baseFeeAmount, +selectPool.quoteFeeAmount)) }})
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-0.16 flex-1 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
      <div class="flex items-center justify-between">
        <div class="text-0.14 text-grey1 font-500">
          Price Range
        </div>
        <v-switch v-model="currencyDirection" :options="currentPairSwitch" />
      </div>
      <div class="my-0.14 flex">
        <div class="flex-1">
          <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
            Min Price
          </div>
          <div class="mt-0.04 text-0.16 text-white2 font-500">
            {{ !isQuote ? formatNumber(selectPool.tickL, -1) : formatNumber(1 / +selectPool.tickR, +currencyList.find(i => i.assetName === getSymbolItemByPoolId(selectPool?.poolId)?.base)?.depositPrecision) }}
            {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'base' : 'quote'] }} per {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'quote' : 'base'] }}
          </div>
          <div class="text-0.12 text-grey1 font-500">
            Your position will be 100% {{ getSymbolItemByPoolId(selectPool.poolId)?.[!isQuote ? 'base' : 'quote'] }} at this price.
          </div>
        </div>
        <div class="ml-0.82 flex-1">
          <div class="flex items-center gap-x-0.04 text-0.14 text-grey1 font-500">
            Max Price
          </div>
          <div class="mt-0.04 text-0.16 text-white2 font-500">
            {{ !isQuote ? formatNumber(selectPool.tickR, -1) : formatNumber(1 / +selectPool.tickL, +currencyList.find(i => i.assetName === getSymbolItemByPoolId(selectPool?.poolId)?.base)?.depositPrecision) }}
            {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'base' : 'quote'] }} per {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'quote' : 'base'] }}
          </div>
          <div class="text-0.12 text-grey1 font-500">
            Your position will be 100% {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'base' : 'quote'] }} at this price.
          </div>
        </div>
      </div>
      <div class="text-0.14 text-grey1 font-500">
        Current Price
      </div>
      <div class="text-0.16 font-500">
        1 {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'quote' : 'base'] }} =
        {{ !isQuote ? formatNumber(+symbolPrice?.[getSymbolItemByPoolId(selectPool.poolId)?.symbol]?.closePrice, -1)
          : formatNumber(1 / +symbolPrice?.[getSymbolItemByPoolId(selectPool.poolId)?.symbol]?.closePrice, +currencyList.find(i => i.assetName === getSymbolItemByPoolId(selectPool?.poolId)?.base)?.depositPrecision) }}
        {{ getSymbolItemByPoolId(selectPool.poolId)?.[isQuote ? 'base' : 'quote'] }}
      </div>
    </div>
    <div class="mt-0.16">
      <div class="flex justify-end">
        <div class="flex cursor-pointer items-center text-grey1" @click="$router.push('/portfolio/history')">
          View All
          <SvgBack class="rotate-180" />
        </div>
      </div>
      <AssetHistoryPool :pool-id="+selectPoolRow.poolId" :left="+selectPool.tickL" :right="+selectPool.tickR" hide-filter />
    </div>
    <v-modal
      v-model:show="showCollect"
      modal-class="w-5.5 text-grey1"
      :z-index="201"
      title="Collect Fees"
    >
      <div class="mt-0.2 font-500">
        <div class="text-0.16 text-grey2 font-400">
          Uncollected Fees
        </div>
        <div class="mt-0.12 text-0.24 text-white2 font-600">
          $ {{ formatNumber(getPrice(selectPool.poolId, +selectPool.baseFeeAmount, +selectPool.quoteFeeAmount), 2) }}
        </div>
        <div class="mt-0.32 text-0.16 text-grey2 font-400">
          You'll receive collected fees
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
            </div>
            <div class="text-0.16 font-500">
              {{ formatNumber(selectPool.baseFeeAmount, -1) }}
            </div>
          </div>
          <div class="mt-0.24 flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
            </div>
            <div class="text-0.16 font-500">
              {{ formatNumber(selectPool.quoteFeeAmount, -1) }}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" type="outline" @click="showCollect = false">
            Cancel
          </v-button>
          <v-button
            class="flex-1" :loading="isLoadingCollect"
            :disabled="+selectPool.quoteFeeAmount === 0 && +selectPool.baseFeeAmount === 0" @click="collectFee"
          >
            Collect
          </v-button>
        </div>
      </template>
    </v-modal>
  </AssetModule>
</template>

<style scoped>

</style>
