<script setup lang="ts">
const { isSigned } = useKey()
const route = useRoute()
const router = useRouter()
const tabOptions = [
  {
    value: 'pool',
    label: 'Liquidity Pools',
  },
  {
    value: 'referral',
    label: 'Referrals',
  },
  {
    value: 'leaderboard',
    label: 'Leaderboard',
  },
]

const { getVoyageSummary, currentTab, contentRef } = useVoyage()
const { run: postTwitter } = useHttp(vesselApi.stats.postTwitterCode)

const boundName = ref('')
const showBoundName = ref(false)

whenever(isSigned, () => {
  getVoyageSummary()
  if (route.query.code) {
    postTwitter({
      code: route.query.code,
      state: route.query.state,
    }).then((res) => {
      if (res.data.otherUserBound) {
        boundName.value = res.data.otherUserName
        showBoundName.value = true
      }
    }).finally(() => {
      router.replace({
        ...route,
        query: {},
      })
    })
  }
}, {
  immediate: true,
})

// onMounted(() => {
//   if (route.query.code) {
//     console.log(route.query)
//     postTwitter({
//       code: route.query.code,
//       state: route.query.state,
//     }).then((res) => {
//       console.log(res)
//     })
//   }
// })
</script>

<template>
  <VoyageBg />
  <div ref="contentRef" class="absolute inset-0 top-0.8 z-1 overflow-auto">
    <VoyageMain />
    <AirdropTabs v-model="currentTab" class="mx-auto mb-0.14 mt-0.4 w-12.8" :options="tabOptions" />
    <div class="mx-auto w-12.8">
      <VoyagePool v-if="currentTab === 'pool'" class="w-full" />
      <VoyageReferral v-if="currentTab === 'referral'" class="w-full" />
      <VoyageLeaderboard v-if="currentTab === 'leaderboard'" class="w-full" />
    </div>
    <v-modal
      v-model:show="showBoundName"
      modal-class="w-5.88"
      title="X Account Already Linked"
    >
      <div class="text-0.16 text-grey1">
        This X account is already linked to {{ boundName }}.
        Please ensure you are using the correct account or contact support for assistance.
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" @click="showBoundName = false">
            OK
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
