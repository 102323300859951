<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none" class="flex-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" x="10" y="8">
      <g clip-path="url(#clip0_24550_43729)">
        <path d="M3.51982 19.8055C3.01056 19.8055 2.57445 19.624 2.21149 19.2611C1.84853 18.8981 1.66735 18.4623 1.66797 17.9537V8.69442C1.66797 8.18516 1.84945 7.74905 2.21241 7.38609C2.57538 7.02312 3.01118 6.84195 3.51982 6.84257H6.2976C6.55994 6.84257 6.78001 6.93146 6.95778 7.10923C7.13556 7.28701 7.22414 7.50676 7.22352 7.76849C7.22352 8.03084 7.13464 8.2509 6.95686 8.42868C6.77908 8.60646 6.55933 8.69504 6.2976 8.69442H3.51982V17.9537H16.4828V8.69442H13.705C13.4427 8.69442 13.2226 8.60553 13.0448 8.42775C12.867 8.24997 12.7785 8.03022 12.7791 7.76849C12.7791 7.50615 12.868 7.28609 13.0457 7.10831C13.2235 6.93053 13.4433 6.84195 13.705 6.84257H16.4828C16.992 6.84257 17.4282 7.02405 17.7911 7.38701C18.1541 7.74997 18.3352 8.18578 18.3346 8.69442V17.9537C18.3346 18.4629 18.1532 18.899 17.7902 19.262C17.4272 19.625 16.9914 19.8061 16.4828 19.8055H3.51982ZM10.9272 11.6342L11.7606 10.8009C11.9303 10.6311 12.1427 10.5463 12.3976 10.5463C12.6525 10.5463 12.8723 10.6389 13.0569 10.824C13.2266 10.9938 13.3115 11.2099 13.3115 11.4722C13.3115 11.7345 13.2266 11.9506 13.0569 12.1203L10.6494 14.5278C10.4643 14.7129 10.2482 14.8055 10.0013 14.8055C9.75439 14.8055 9.53834 14.7129 9.35315 14.5278L6.94575 12.1203C6.77599 11.9506 6.68741 11.7386 6.68001 11.4842C6.6726 11.2299 6.76118 11.0099 6.94575 10.824C7.1155 10.6543 7.33155 10.5694 7.59389 10.5694C7.85624 10.5694 8.07229 10.6543 8.24204 10.824L9.07538 11.6342V1.28701C9.07538 1.02467 9.16426 0.804606 9.34204 0.626828C9.51982 0.44905 9.73957 0.36047 10.0013 0.361087C10.2636 0.361087 10.4837 0.449976 10.6615 0.627754C10.8393 0.805532 10.9278 1.02528 10.9272 1.28701V11.6342Z" fill="#37AAEC" />
      </g>
      <defs>
        <clipPath id="clip0_24550_43729">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
    <g filter="url(#filter0_b_24550_43728)">
      <circle cx="20" cy="20.5" r="20" fill="url(#paint0_linear_24550_43728)" fill-opacity="0.3" />
      <circle cx="20" cy="20.5" r="19.8" stroke="url(#paint1_linear_24550_43728)" stroke-width="0.4" />
    </g>
    <defs>
      <filter id="filter0_b_24550_43728" x="-12" y="-11.5" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24550_43728" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24550_43728" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_24550_43728" x1="20" y1="0.5" x2="20" y2="43.2966" gradientUnits="userSpaceOnUse">
        <stop offset="0.0595561" stop-color="#696F7D" stop-opacity="0.5" />
        <stop offset="0.840053" stop-color="#7C808D" />
      </linearGradient>
      <linearGradient id="paint1_linear_24550_43728" x1="20" y1="0.5" x2="20" y2="40.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#7ACEFD" />
        <stop offset="0.66" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>

</style>
