<script setup lang="ts">
const showForm = ref(false)
const current = ref('limit')
const { currentSymbolItem } = useSymbol()
const type = ref<'buy' | 'sell'>('buy')
const { isSigned } = useKey()
const { isConnected } = useUser()
const tabOption = [
  { value: 'limit', label: 'Limit' },
  { value: 'market', label: 'Market' },
  // { value: 'stopLimit', label: 'Stop limit' },
]

function openForm(formType: 'buy' | 'sell') {
  type.value = formType
  showForm.value = true
}
</script>

<template>
  <div v-if="!isSigned" class="fixed bottom-0 left-0 right-0 z-190 h-0.88 w-full flex flex-center gap-x-0.12 bg-black1 p-0.1 px-0.16">
    <common-connect-wallet-button v-if="!isConnected" class="w-full" />
    <common-sign-for-trading-button v-else-if="!isSigned" />
  </div>
  <div v-else class="fixed bottom-0 left-0 right-0 z-190 h-0.88 w-full flex gap-x-0.12 bg-black1 p-0.1 px-0.16">
    <v-button class="flex-1" bg="green" @click="openForm('buy')">
      Buy
    </v-button>
    <v-button class="flex-1" bg="red" @click="openForm('sell')">
      Sell
    </v-button>
  </div>
  <v-modal
    v-model:show="showForm"
    modal-class="text-grey1 w-full"
    :z-index="201"
    :title="`${type.slice(0, 1).toUpperCase()}${type.slice(1)} ${currentSymbolItem?.base}`"
  >
    <v-tabs v-model="current" :options="tabOption" size="small" class="mb-0.16" />
    <trade-operation-limit v-if="current === 'limit'" :type="type" @submit="showForm = false" />
    <trade-operation-market v-if="current === 'market'" :type="type" @submit="showForm = false" />
  </v-modal>
</template>

<style scoped>
.type-button {
  &.show-buy {
    &::before {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &.show-sell {
    &::after {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &::before {
    transition: all 0.1s ease-out;
    content: '';
    position: absolute;
    background: var(--vessel-color-green);
    width: calc(260rem / 400);
    padding-top: calc(64rem / 400);
    border-radius: calc(32rem / 400);
    top: 0;
    left: calc(-125rem / 400);
    /* transform: translate(calc(-130rem / 400), calc(32rem / 400)); */
    opacity: 0;
  }

  &::after {
    transition: all 0.1s ease-out;
    content: '';
    position: absolute;
    background: var(--vessel-color-red);
    width: calc(260rem / 400);
    padding-top: calc(64rem / 400);
    border-radius: calc(32rem / 400);
    top: calc(-32rem / 400);
    right: calc(-125rem / 400);;
    opacity: 0;
    /* transform: translate(calc(130rem / 400), calc(-32rem / 400)); */
  }
}
</style>
