<script setup lang="ts">
export interface Option {
  value: number
  name: string
  tooltipRender?: JSX.Element
}
const props = defineProps<{
  options: Option[]
  total: number
  step: number
}>()

const { isMobile } = useResponsive()

const currentSelectIndex = ref(0)

const currentRender = computed(() => {
  return props.options[currentSelectIndex.value]?.tooltipRender
})
</script>

<template>
  <div
    v-if="isMobile && !!currentRender"
    class="mx--0.2 mb-0.3 rd-0.08 bg-grey1 px-0.12 py-0.06 text-0.12 font-poppins"
  >
    <Render :value="currentRender" />
  </div>
  <div class="relative mb-0.2 h-0.14 w-full rd-0.2 bg-grey1">
    <div
      class="absolute bottom-0 left-0 top-0 h-full bg-primary"
      :style="{ width: `calc(${options[step]?.value} / ${total} * 100%)` }"
    />

    <div
      v-for="(item, index) in options" :key="item.name"
      class="group point absolute top-1/2 h-0.24 w-0.24 flex-center rd-full"
      :class="{
        'bg-primary': index <= step,
        'bg-grey1': index > step,
      }"
      :style="{ '--left': `calc(${item.value} / ${total} * 100%)` }"
      @click="currentSelectIndex = index"
    >
      <div
        v-if="item.tooltipRender && !isMobile"
        class="invisible absolute top--0.08 w-auto rd-0.08 bg-grey1 px-0.12 py-0.06 text-0.12 opacity-0 transition-all group-hover:visible -translate-y-full group-hover:opacity-100"
      >
        <Render :value="item.tooltipRender" />
        <div class="absolute bottom--0.06 left-1/2 border-l-7 border-r-7 border-t-6 border-transparent border-t-grey1 -translate-x-1/2" />
      </div>
      <svg v-if="index <= step" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.06732 9.19992L5.61732 7.74992C5.4951 7.6277 5.3451 7.56659 5.16732 7.56659C4.98954 7.56659 4.83398 7.63325 4.70065 7.76659C4.57843 7.88881 4.51732 8.04436 4.51732 8.23325C4.51732 8.42214 4.57843 8.5777 4.70065 8.69992L6.60065 10.5999C6.72287 10.7221 6.87843 10.7833 7.06732 10.7833C7.25621 10.7833 7.41176 10.7221 7.53398 10.5999L11.3173 6.81659C11.4395 6.69436 11.5007 6.54436 11.5007 6.36659C11.5007 6.18881 11.434 6.03325 11.3007 5.89992C11.1784 5.7777 11.0229 5.71659 10.834 5.71659C10.6451 5.71659 10.4895 5.7777 10.3673 5.89992L7.06732 9.19992ZM8.00065 14.6666C7.07843 14.6666 6.21176 14.4915 5.40065 14.1413C4.58954 13.791 3.88398 13.3161 3.28398 12.7166C2.68398 12.1166 2.2091 11.411 1.85932 10.5999C1.50954 9.78881 1.33443 8.92214 1.33398 7.99992C1.33398 7.0777 1.5091 6.21103 1.85932 5.39992C2.20954 4.58881 2.68443 3.88325 3.28398 3.28325C3.88398 2.68325 4.58954 2.20836 5.40065 1.85859C6.21176 1.50881 7.07843 1.3337 8.00065 1.33325C8.92287 1.33325 9.78954 1.50836 10.6007 1.85859C11.4118 2.20881 12.1173 2.6837 12.7173 3.28325C13.3173 3.88325 13.7924 4.58881 14.1427 5.39992C14.4929 6.21103 14.6678 7.0777 14.6673 7.99992C14.6673 8.92214 14.4922 9.78881 14.142 10.5999C13.7918 11.411 13.3169 12.1166 12.7173 12.7166C12.1173 13.3166 11.4118 13.7917 10.6007 14.1419C9.78954 14.4921 8.92287 14.667 8.00065 14.6666Z" fill="#FCFCFD" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M3.99935 14.6667C3.63268 14.6667 3.3189 14.5363 3.05802 14.2754C2.79713 14.0145 2.66646 13.7005 2.66602 13.3334V6.66675C2.66602 6.30008 2.79668 5.9863 3.05802 5.72542C3.31935 5.46453 3.63313 5.33386 3.99935 5.33342H4.66602V4.00008C4.66602 3.07786 4.99113 2.29186 5.64135 1.64208C6.29157 0.992304 7.07757 0.667192 7.99935 0.666748C8.92157 0.666748 9.70779 0.991859 10.358 1.64208C11.0082 2.2923 11.3331 3.0783 11.3327 4.00008V5.33342H11.9993C12.366 5.33342 12.68 5.46408 12.9413 5.72542C13.2027 5.98675 13.3331 6.30053 13.3327 6.66675V13.3334C13.3327 13.7001 13.2022 14.0141 12.9413 14.2754C12.6805 14.5367 12.3665 14.6672 11.9993 14.6667H3.99935ZM7.99935 11.3334C8.36602 11.3334 8.68002 11.203 8.94135 10.9421C9.20268 10.6812 9.33313 10.3672 9.33268 10.0001C9.33268 9.63342 9.20224 9.31964 8.94135 9.05875C8.68046 8.79786 8.36646 8.66719 7.99935 8.66675C7.63268 8.66675 7.3189 8.79742 7.05802 9.05875C6.79713 9.32008 6.66646 9.63386 6.66602 10.0001C6.66602 10.3667 6.79668 10.6807 7.05802 10.9421C7.31935 11.2034 7.63313 11.3339 7.99935 11.3334ZM5.99935 5.33342H9.99935V4.00008C9.99935 3.44453 9.8049 2.9723 9.41602 2.58341C9.02713 2.19453 8.5549 2.00008 7.99935 2.00008C7.44379 2.00008 6.97157 2.19453 6.58268 2.58341C6.19379 2.9723 5.99935 3.44453 5.99935 4.00008V5.33342Z" fill="#B1B5C4" />
      </svg>
      <div class="absolute top-0.3 w-auto whitespace-nowrap text-center text-0.12 font-500 text-grey1">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.point {
  left: var(--left);
  transform: translate(-50%, -50%);
}
</style>
