<script setup lang="ts">

</script>

<template>
  <div class="rd-0.04 bg-black1">
    <slot />
  </div>
</template>

<style scoped>

</style>
