<script setup lang="ts">

</script>

<template>
  <div class="circle absolute left-1/2 top-0 mt--0.3 pr-0.03 -translate-x-1/2">
    <div class="absolute inset-0 flex flex-center pl-0.02 pt-0.05 text-caption2b text-primary">
      <slot />
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52" fill="none">
      <g filter="url(#filter0_ddd_24482_42208)">
        <circle cx="28.5391" cy="28" r="14" fill="url(#paint0_radial_24482_42208)" />
      </g>
      <defs>
        <filter id="filter0_ddd_24482_42208" x="0.539062" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.666667 0 0 0 0 0.92549 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24482_42208" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.94902 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_24482_42208" result="effect2_dropShadow_24482_42208" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="2" dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_24482_42208" result="effect3_dropShadow_24482_42208" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_24482_42208" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_24482_42208" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.5391 28) rotate(90) scale(21.4667)">
          <stop offset="0.47" stop-color="#23262F" />
          <stop offset="0.628016" stop-color="#173142" />
          <stop offset="1" stop-color="#37AAEC" />
        </radialGradient>
      </defs>
    </svg>
  </div>
</template>

<style scoped>
.circle {
  transform-origin: 28px 150px;
}
</style>
