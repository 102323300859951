<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g filter="url(#filter0_i_19541_36462)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.81185 3.53276C8.81185 4.79276 10.3985 5.34609 11.1852 4.36609C11.3652 4.14609 11.6785 4.11276 11.8985 4.27942C13.5052 5.43276 14.4518 7.49942 14.0452 9.67943C13.9275 10.3072 13.6943 10.8961 13.3699 11.4249C13.0605 11.9293 12.1922 11.3548 12.0852 10.7728C11.9652 10.0861 11.5918 9.47276 11.0985 8.98609L9.27852 7.19276C9.01852 6.93942 8.60518 6.93942 8.34518 7.19276L6.53852 8.97276C6.03185 9.46609 5.65852 10.0928 5.53185 10.7861C5.45401 11.2104 5.46168 11.6459 5.55441 12.0672C5.61795 12.3559 5.26487 12.6592 5.05481 12.4512C4.08144 11.4875 3.47852 10.1483 3.47852 8.66609C3.47852 5.31276 6.22518 2.99276 7.77852 1.95276C8.21852 1.65942 8.81185 1.97942 8.81185 2.51276V3.53276ZM7.45897 9.93245C8.2091 9.19342 9.41736 9.19822 10.1656 9.93912C10.479 10.2391 10.7123 10.6258 10.7856 11.0525C10.9856 12.2725 10.0256 13.3325 8.81231 13.3325C7.59897 13.3325 6.63897 12.2725 6.83897 11.0458C6.91231 10.6191 7.15231 10.2391 7.45897 9.93245Z" fill="url(#paint0_linear_19541_36462)" />
    </g>
    <defs>
      <filter id="filter0_i_19541_36462" x="3.47852" y="1.84131" width="11.6602" height="13.4911" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="1" dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.8007 0 0 0 0 0.988735 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_19541_36462" />
      </filter>
      <linearGradient id="paint0_linear_19541_36462" x1="8.80892" y1="1.84131" x2="8.80892" y2="13.3325" gradientUnits="userSpaceOnUse">
        <stop stop-color="#37AAEC" />
        <stop offset="1" stop-color="#19F2FF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>

</style>
