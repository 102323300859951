<script setup lang="ts">
const { orderHistoryList, isLoading } = useOrder()

const page = ref(1)

const orderList = computed(() => orderHistoryList.value.sort((i, j) => +j.orderTime - +i.orderTime) || [])
// const orderListCurrentPage = computed(() => orderList.value.slice(ORDER_PAGE_SIZE * (page.value - 1), ORDER_PAGE_SIZE * page.value))
const orderListCurrentPage = computed(() => orderList.value)

function onChangePage(current: number) {
  page.value = current
}
</script>

<template>
  <div class="relative w-full font-poppins">
    <div v-for="(item, index) in orderListCurrentPage" :key="item.clientOrderId">
      <div v-if="index !== 0" class="mb-0.2 mt-0.16 w-full bg-black3 pt-0.01" />
      <div class="flex items-center justify-between">
        <div class="flex items-center font-700">
          <div class="text-0.17 text-white2">
            {{ item.symbol }}
          </div>
          <div
            class="ml-.1 border-2 rd-0.04 p-0.06 text-0.12 leading-0.12"
            :class="item.side === 'BUY' ? 'text-green border-green' : 'text-red border-red'"
          >
            {{ item.side }} / {{ item.type }}
          </div>
        </div>
      </div>
      <div class="mt-0.16 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Order Time
        </div>
        <div class="text-0.16 text-white2">
          {{ dayjs(+item.orderTime).format('MM/DD/YYYY HH:mm:ss') }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Order Price
        </div>
        <div class="text-0.16 text-white2">
          {{ +item.executedQty !== 0 ? formatNumber(+item.cumulativeQuoteQty / +item.executedQty, 2) : '-' }} / {{ item.type === 'MARKET' ? 'MARKET' : formatNumber(item.price, 2) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Order Amount
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(item.executedQty, 4) }} / {{ formatNumber(item.origQty, 4) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Filled / Total
        </div>
        <div class="text-0.16 text-white2">
          {{ formatNumber(item.cumulativeQuoteQty, 2) }} / {{ formatNumber(+item.price * +item.origQty) }}
        </div>
      </div>
      <div class="mt-0.14 flex items-center justify-between font-500">
        <div class="text-0.14 text-grey1">
          Status
        </div>
        <div class="text-0.16 text-white2">
          {{ item.status.replace(/\_/g, ' ') }}
        </div>
      </div>
    </div>

    <div v-if="!orderListCurrentPage.length" class="h-1 flex-center text-0.12">
      No Data
    </div>

    <!-- <div class="flex justify-end">
      <n-pagination
        class="mt-0.12"

        :default-page-size="ORDER_PAGE_SIZE"
        :item-count="orderList.length" :page="page" @update-page="onChangePage"
      />
    </div> -->
  </div>
</template>

<style scoped>

</style>
