<script setup lang="ts">
interface SelectOption {
  value: string
  label: string
  disabled?: boolean
}
const props = defineProps<{
  options: SelectOption[] | string[]
  plain?: boolean
  popoverClass?: string
  small?: boolean
  align?: string
}>()

const emit = defineEmits(['change'])

const current = defineModel<any>()
const showSelect = ref(false)
const selectRef = ref()

onClickOutside(selectRef, (event) => {
  showSelect.value = false
})

const selectOption = computed<SelectOption[]>(() => {
  if (props.options.length && typeof props.options[0] === 'string') {
    return props.options.map(item => ({ value: item, label: item })) as SelectOption[]
  }
  return props.options as SelectOption[]
})

const currentValue = computed(() => {
  if (typeof current.value === 'string') {
    return current.value
  }
  else {
    return (current.value as SelectOption).value
  }
})

const currentName = computed(() => {
  if (props.options.length && typeof props.options[0] === 'string') {
    return currentValue.value
  }
  else {
    return (props.options as SelectOption[]).find(i => i.value === currentValue.value)?.label || currentValue.value
  }
})

function onSelect(item: SelectOption) {
  if (typeof current.value === 'string') {
    current.value = item.value as string
  }
  else {
    current.value = item
  }
  emit('change', current.value)
  showSelect.value = false
}
</script>

<template>
  <div ref="selectRef" class="relative h-0.28">
    <div
      class="h-full flex cursor-pointer items-center rd-full py-0.02 pl-0.12 pr-0.04"
      :class="{
        'bg-black2 text-white2': !plain,
        'bg-transparent text-white2': plain,
        'justify-end': align === 'right',
        'justify-between': align === 'left' || !align,
      }"
      @click="showSelect = !showSelect"
    >
      <div
        :class="{
          'text-button2': !small,
          'text-caption2m mr-0.04': small,

        }"
      >
        {{ currentName }}
      </div>
      <div class="transition-all" :class="showSelect ? 'rotate-180' : 'rotate-0'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z" fill="#777E91" />
        </svg>
      </div>
    </div>
    <Transition name="slide">
      <div v-if="showSelect" :class="popoverClass" class="absolute left-0 z-300 mt-0.08 max-h-2 min-w-full flex flex-col overflow-auto rd-0.04 bg-black2">
        <div
          v-for="item in selectOption" :key="item.value"
          class="cursor-pointer px-0.16 py-0.08 transition-all hover-rd-0.04 hover:bg-black3"
          :class="{
            'text-white2 hover:text-white2': currentValue === item.value,
            'text-white2 hover:text-white2 s': currentValue !== item.value,
            'text-button2': !small,
            'text-caption2m': small,
            'text-right pr-0.3': align === 'right',
            'text-center': align === 'center',
            'text-left': align === 'left' || !align,
          }"
          @click="onSelect(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
  transform: translate(0, 0);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translate(0, -10px);
}
</style>
