import type { App } from 'vue'
import { WagmiPlugin, createConfig, http } from '@wagmi/vue'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { metaMask, walletConnect } from '@wagmi/vue/connectors'
import type { Chain } from 'viem'
import type { Config } from '@wagmi/core'
import { particleCoinbaseWallet, particleMetamaskWallet, particleOkxWallet, particleTrustWallet } from './particleWagmiWallet'

let wagmiConfig: Config

export function getWagmiConfig(): Config {
  const chainConfig = getChainConfigSync()
  if (wagmiConfig) {
    return wagmiConfig
  }

  const connectors = [
    metaMask({ enableAnalytics: false }),
    walletConnect({ projectId: WALLET_CONNECT_PROJECT_ID, showQrModal: false }),
    particleMetamaskWallet,
    particleOkxWallet,
    particleTrustWallet,
    particleCoinbaseWallet,
  ]

  return wagmiConfig = createConfig({
    pollingInterval: 2000,
    chains: getConfigChains(),
    transports: Object.fromEntries(chainConfig.map(c => [c.id, http()])),
    connectors,
  })
}

export function installWagmi(app: App) {
  const config = getWagmiConfig()
  const queryClient = new QueryClient()
  app.use(WagmiPlugin, { config })
  app.use(VueQueryPlugin, { queryClient })
}

// declare module '@wagmi/vue' {
//   interface Register {
//     config: typeof config
//   }
// }
